import React from "react";

export default function Footer({ margin_top }) {
  return (
    <p
      className="text-center"
      style={{
        marginTop: margin_top,
      }}
    >
      © 2025{" "}
      <a href="https://kiemthecaofree.com/" target="_self" className="ml-25">
        Đức Ngọc Kiếm Tiền
      </a>
      . All rights Reserved{" "}
    </p>
  );
}
