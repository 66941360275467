import React, { Fragment, useEffect, useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import {
  ApiLink,
  notify,
  regexBankNumber,
  regexPhone,
  Title,
} from "../../../utils/Title";
import UserLogo from "./UserLogo";
export default function PayInfo({ username }) {
  let [loading, isLoading] = useState(true);
  let [bankList, setBankList] = useState([]);

  let [errInfo, setErrInfo] = useState({
    momo_phone: "",
    zalopay_phone: "",
    bank_number: "",
    bank_fullname: "",
    bank_id: "",
  });

  let [payInfo, setPayInfo] = useState({
    momo_phone: "",
    zalopay_phone: "",
    bank_id: "",
    bank_number: "",
    bank_fullname: "",
  });

  let navigate = useNavigate();

  useEffect(() => {
    document.title = Title.user_pay_info + Title.origin;
  }, []);

  useEffect(() => {
    async function getInfo() {
      const response = await axios.get(`${ApiLink.domain + "/account/info"}`, {
        withCredentials: true,
      });
      if (response.data.status) {
        const { bank_id, bank_number, bank_fullname, momo_phone, zalopay_phone } =
          response.data.data;
        setPayInfo({
          bank_id,
          bank_number,
          bank_fullname,
          momo_phone,
          zalopay_phone,
        });
      } else {
        notify(false, response.data.message);
        if (response.data.must === "login") {
          return navigate("/account/login", { replace: true });
        } else {
          return navigate("/", { replace: true });
        }
      }
    }

    async function getBankList() {
      const response = await axios.get(`${ApiLink.domain + "/pay-bank"}`, {
        withCredentials: true,
      });
      if (response.data.status) {
        setBankList(response.data.data);
      } else {
        notify(false, response.data.message);
        if (response.data.must === "login") {
          return navigate("/account/login", { replace: true });
        } else {
          return navigate("/", { replace: true });
        }
      }
    }
    const getAll = async () => {
      await Promise.all([Promise.resolve(getInfo())]);
      await Promise.all([Promise.resolve(getBankList())]);
      isLoading(false);
    };
    getAll();
  }, [navigate]);

  const handleChangepayInfo = (e) => {
    const { name, value } = e.target;
    if (name === "momo_phone") {
      if (regexPhone.test(value) || value === "") {
        setErrInfo({ ...errInfo, [name]: "" });
      } else {
        setErrInfo({ ...errInfo, [name]: "SĐT ví Momo không hợp lệ" });
      }
    } else if (name === "zalopay_phone") {
      if (regexPhone.test(value) || value === "") {
        setErrInfo({ ...errInfo, [name]: "" });
      } else {
        setErrInfo({ ...errInfo, [name]: "SĐT ví ZaloPay không hợp lệ" });
      }
    } else if (name === "bank_number") {
      if (
        (regexBankNumber.test(value) && value.trim().length >= 5) ||
        value === ""
      ) {
        setErrInfo({ ...errInfo, [name]: "" });
      } else {
        setErrInfo({ ...errInfo, [name]: "STK ngân hàng không hợp lệ" });
      }
    } else if (name === "bank_fullname") {
      if (value.trim().length >= 5 || value === "") {
        setErrInfo({ ...errInfo, [name]: "" });
      } else {
        setErrInfo({ ...errInfo, [name]: "Tên chủ tài khoản không hợp lệ" });
      }
    }
    setPayInfo({ ...payInfo, [name]: value });
  };

  const handleChangeInfoSubmit = async () => {
    if (payInfo.momo_phone.length !== 0) {
      const result = regexPhone.test(payInfo.momo_phone);
      if (!result) {
        setErrInfo({ ...errInfo, momo_phone: "SĐT ví Momo không hợp lệ" });
        return;
      }
    }

    if (payInfo.zalopay_phone.length !== 0) {
      const result = regexPhone.test(payInfo.zalopay_phone);
      if (!result) {
        setErrInfo({
          ...errInfo,
          zalopay_phone: "SĐT ví ZaloPay không hợp lệ",
        });
        return;
      }
    }
    if (Number(payInfo.bank_id) > 0) {
      const result =
        regexBankNumber.test(payInfo.bank_number) &&
        payInfo.bank_number.trim().length >= 5;
      if (!result) {
        setErrInfo({ ...errInfo, bank_number: "STK ngân hàng không hợp lệ" });
        return;
      }

      if (payInfo.bank_fullname.trim().length < 5) {
        setErrInfo({
          ...errInfo,
          bank_fullname: "Tên chủ tài khoản không hợp lệ",
        });
        return;
      }
    }
    const new_pay = {
      ...payInfo,
      bank_id: Number(payInfo.bank_id) > 0 ? Number(payInfo.bank_id) : null,
    };
    const response = await axios.post(
      `${ApiLink.domain + "/account/info/update_pay_info"}`,
      new_pay,
      {
        withCredentials: true,
      }
    );
    notify(response.data.status, response.data.message);
    if (response.data.status) {
      setPayInfo(response.data.data);
      setErrInfo({
        ...errInfo,
        momo_phone: "",
        zalopay_phone: "",
        bank_number: "",
        bank_fullname: "",
        bank_id: "",
      });
    }
  };

  return loading ? (
    <Fragment></Fragment>
  ) : (
    <div id="main">
      <UserLogo />
      <div className="page-content">
        <section
          className="row"
          style={{
            marginLeft: "-5px",
            marginRight: "-5px",
          }}
        >
          <div className="col-12 col-lg-12">
            <div
              className="row"
              style={{
                marginLeft: "-5px",
                marginRight: "-5px",
              }}
            >
              <div className="col-12">
                <div className="col-lg-12 stretch-card" style={{ padding: 0 }}>
                  <div className="card">
                    <div className="card-body user_account">
                      <h4
                        className="card-title"
                        style={{ marginBottom: 20, textAlign: "center" }}
                      >
                        Chỉnh sửa thông tin thanh toán
                      </h4>
                      <div
                        className="col-12 mg_15"
                        style={{ display: "flex", justifyContent: "center" }}
                      >
                        <div className="col-6">
                          <div id="form_edit_info_submit">
                            <div style={{ marginBottom: 20 }}>
                              <label className="form-label">
                                Số điện thoại ví Momo
                              </label>
                              <input
                                className="form-control showordisable"
                                name="momo_phone"
                                defaultValue={payInfo.momo_phone}
                                type="text"
                                placeholder="Nhập SĐT ví Momo"
                                onChange={(e) => handleChangepayInfo(e)}
                              />
                              {errInfo.momo_phone === "" ? (
                                ""
                              ) : (
                                <p
                                  className="text-danger"
                                  style={{
                                    fontSize: 14,
                                    fontWeight: "bold",
                                  }}
                                >
                                  {errInfo.momo_phone}
                                </p>
                              )}
                            </div>
                            <div style={{ marginBottom: 20 }}>
                              <label className="form-label">
                                Số điện thoại ví ZaloPay
                              </label>
                              <input
                                className="form-control showordisable"
                                name="zalopay_phone"
                                defaultValue={payInfo.zalopay_phone}
                                type="text"
                                placeholder="Nhập SĐT ví ZaloPay"
                                onChange={(e) => handleChangepayInfo(e)}
                              />
                              {errInfo.zalopay_phone === "" ? (
                                ""
                              ) : (
                                <p
                                  className="text-danger"
                                  style={{
                                    fontSize: 14,
                                    fontWeight: "bold",
                                  }}
                                >
                                  {errInfo.zalopay_phone}
                                </p>
                              )}
                            </div>
                            <div style={{ marginBottom: 20 }}>
                              <label className="form-label">
                                Chọn tên ngân hàng
                              </label>
                              <select
                                className="form-select"
                                name="bank_id"
                                defaultValue={payInfo.bank_id}
                                aria-label="Default select example"
                                onChange={(e) => handleChangepayInfo(e)}
                              >
                                <option value={null}>Chọn ngân hàng</option>
                                {bankList.map((bank, index) => {
                                  return (
                                    <option key={index} value={bank.bank_id}>
                                      {bank.bank_code + " - " + bank.bank_name}
                                    </option>
                                  );
                                })}
                              </select>
                            </div>
                            <div style={{ marginBottom: 20 }}>
                              <label className="form-label">
                                Số tài khoản ngân hàng
                              </label>
                              <input
                                className="form-control showordisable"
                                id="info_phone_input"
                                name="bank_number"
                                defaultValue={payInfo.bank_number}
                                type="number"
                                placeholder="Nhập STK ngân hàng"
                                onChange={(e) => handleChangepayInfo(e)}
                              />
                              {errInfo.bank_number === "" ? (
                                ""
                              ) : (
                                <p
                                  className="text-danger"
                                  style={{
                                    fontSize: 14,
                                    fontWeight: "bold",
                                  }}
                                >
                                  {errInfo.bank_number}
                                </p>
                              )}
                            </div>
                            <div style={{ marginBottom: 20 }}>
                              <label className="form-label">
                                Tên chủ tài khoản
                              </label>
                              <input
                                className="form-control showordisable"
                                name="bank_fullname"
                                defaultValue={payInfo.bank_fullname}
                                type="text"
                                placeholder="Nhập tên chủ tài khoản"
                                onChange={(e) => handleChangepayInfo(e)}
                              />
                              {errInfo.bank_fullname === "" ? (
                                ""
                              ) : (
                                <p
                                  className="text-danger"
                                  style={{
                                    fontSize: 14,
                                    fontWeight: "bold",
                                  }}
                                >
                                  {errInfo.bank_fullname}
                                </p>
                              )}
                            </div>
                            <div
                              style={{
                                marginBottom: 20,
                                textAlign: "center",
                              }}
                            >
                              <button
                                type="button"
                                id="btn_edit_info"
                                className="btn btn-success"
                                onClick={() => handleChangeInfoSubmit()}
                              >
                                Cập nhật thông tin
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  );
}
