import axios from "axios";
import { toast } from "react-toastify";
const Title = {
  origin: " - Đức Ngọc Kiếm Tiền",
  mobile_app: "Nhiệm vụ",
  order_check: "Kiểm tra trạng thái",
  task_link: "Nhập code",
  task_everyday: "Nhiệm vụ hàng ngày",
  rank: "Bảng xếp hạng",
  sigup: "Đăng ký tài khoản",
  login: "Đăng nhập tài khoản",
  forgotPassword: "Quên mật khẩu",
  verifyEmail: "Xác thực tài khoản",
  resendEmail: "Lấy lại link xác thực",
  search: "Kết quả tìm kiếm cho ",
  notFound: "Trang tìm kiếm không tồn tại",
  infoUser: "Thông tin tài khoản",
  infoWithdraw: "Rút tiền",
  orderUser: "Lịch sử mua hàng",
  cartUser: "Giỏ hàng",
  user_index: "Chỉ số cơ thể",
  user_nutrition: "Quản lý dinh dưỡng",
  user_history_xu: "Lịch sử nhận thưởng",
  user_history_fish: "Lịch sử câu cá",
  user_reference: "Giới thiệu bạn bè",
  user_exchange: "Quy đổi tiền nông trại",
  user_history_pay: "Lịch sử rút tiền",
  user_pay_info: "Thông tin thanh toán",
  user_monan: "Quản lý món ăn",
  user_khuyennghi: "Khuyến nghị dinh dưỡng",
  user_thongke: "Thống kê dinh dưỡng",
};

const ADMIN_CODE = "ADMIN";
const CUSTOMER_CODE = "CUSTOMER";

const FRONTEND_BASE = "https://mobile.kiemthecaofree.com";
const BACKEND_BASE = "https://mobis.kiemthecaofree.com";

// const FRONTEND_BASE = "http://localhost:3000";
// const BACKEND_BASE = "http://localhost:7000";

const ApiLink = {
  domain: "https://mobis.kiemthecaofree.com/api",
  // domain: "http://localhost:7000/api/v1",
};

// OKE
const CLIENT_ID_FRONTEND =
  "628053571760-k99hhdbh40lno2at3ngm430i9cpql18a.apps.googleusercontent.com";

// TEST;
// const CLIENT_ID_FRONTEND =
//   "508621019009-461kn6gbp9jtv7gkm4mphsv3dcqdio3s.apps.googleusercontent.com";

const MIN_EXCHANGE_TEXT = "20.000 đồng / lần";
const RATIO_EXCHANGE_TEXT = "1 : 40";
const RATIO_EXCHANGE_MEAN =
  "40K Nông trại = 1K Tiền mặt. Tỉ lệ có thể giảm nếu có doanh thu quảng cáo trên Web nhé. Rất cảm ơn các bạn đã tham gia!";
const MIN_EXCHANGE_NUMBER = 20000;

const RATIO_XOSO_TEXT = "1 x 70";
const RATIO_XOSO_MEAN = "Bạn đặt 1.000đ trúng thưởng 70.000đ";
const MIN_XOSO_NUMBER = 20;
const MIN_XOSO_TEXT = "20 đồng / lần";

const regexEmail = /^[a-z0-9]+@gmail.com+$/;
const regexUsername = /^[a-zA-Z0-9]+$/;
const regexBankNumber = /^\d+$/;
const regexPhone = /(0[3|5|7|8|9])+([0-9]{8})\b/;
const regexSlug = /^[a-z0-9]+(?:-[a-z0-9]+)*$/;

const toast_config = {
  position: "top-right",
  autoClose: 5000,
  hideProgressBar: false,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
  progress: undefined,
  theme: "light",
};

const isAuth = () => {
  const user = JSON.parse(localStorage.getItem("userLogin"));
  if (user) {
    return true;
  }
  return false;
};

const notify = (success, message) => {
  if (success) {
    toast.success(message, toast_config);
  } else {
    toast.error(message, toast_config);
  }
};

const convertToDateOnly = (time) => {
  const date = new Date(Number(Date.parse(time)));
  const time_toi_uu =
    String(date.getDate()).padStart(2, "0") +
    "/" +
    String(date.getMonth() + 1).padStart(2, "0") +
    "/" +
    date.getFullYear();
  return time_toi_uu;
  // return date.toLocaleDateString();
};

const convertToDate = (time) => {
  const date = new Date(Number(Date.parse(time)));
  const time_toi_uu =
    String(date.getDate()).padStart(2, "0") +
    "/" +
    String(date.getMonth() + 1).padStart(2, "0") +
    "/" +
    date.getFullYear() +
    " " +
    String(date.getHours()).padStart(2, "0") +
    ":" +
    String(date.getMinutes()).padStart(2, "0") +
    ":" +
    String(date.getSeconds()).padStart(2, "0");
  return time_toi_uu;
  // return date.toLocaleDateString() + " " + date.toLocaleTimeString();
};

const convertToTime = (time) => {
  const date = new Date(Number(Date.parse(time)));
  return date.getHours() + " giờ " + date.getMinutes() + " phút";
};

const convertToTimeFarm = (so_phut) => {
  let con_lai = so_phut;
  if (so_phut / (60 * 24) > 1) {
    let ngay = so_phut / (60 * 24);
    if (Number.isInteger(ngay)) {
      return ngay + " ngày";
    } else {
      con_lai = so_phut - Math.floor(ngay) * 60 * 24;
      let gio = con_lai / 60;
      if (Number.isInteger(gio)) {
        return Math.floor(ngay) + " ngày " + gio + " giờ";
      } else {
        con_lai = so_phut - Math.floor(ngay) * 60 * 24 - Math.floor(gio) * 60;
        return (
          Math.floor(ngay) +
          " ngày " +
          Math.floor(gio) +
          " giờ " +
          con_lai +
          " phút"
        );
      }
    }
  } else if (so_phut / 60 > 1) {
    let gio = con_lai / 60;
    if (Number.isInteger(gio)) {
      return gio + " giờ";
    } else {
      con_lai = so_phut - Math.floor(gio) * 60;
      return Math.floor(gio) + " giờ " + con_lai + " phút";
    }
  }
  return so_phut + " phút";
};

const convertToTimeConLai = (date_time) => {
  const date_time_ole = new Date(date_time);
  const currentTime = new Date();
  const time_con_lai = Math.abs(date_time_ole - currentTime);
  console.log("time_con_lai", time_con_lai);

  const days = Math.floor(time_con_lai / (1000 * 60 * 60 * 24));
  const hours = Math.floor(
    (time_con_lai % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
  );
  const minutes = Math.floor((time_con_lai % (1000 * 60 * 60)) / (1000 * 60));
  const seconds = Math.floor((time_con_lai % (1000 * 60)) / 1000);
  if (days > 0) {
    return days + " ngày " + hours + " giờ " + minutes + " phút";
  } else {
    if (hours > 0) {
      return hours + "giờ " + minutes + " phút";
    } else {
      if (minutes > 0) {
        return minutes + " phút " + seconds + " giây ";
      } else {
        return seconds + " giây ";
      }
    }
  }
};

const noImage = "/images/hamburger.png";

const getDevice = async () => {
  let device_name = navigator.userAgent;
  const device_browser = navigator.userAgent;
  let device_ip = "";
  let ip_detail = "";
  try {
    const ip_check = await axios.get("https://ipinfo.io/json");
    if (ip_check.status === 200) {
      device_ip = ip_check.data?.ip || "";
      if (device_ip) {
        ip_detail = JSON.stringify(ip_check.data);
      }
    }
  } catch (error) {
    device_ip = "";
    ip_detail = "";
  }

  if (/mobile/i.test(device_name)) {
    device_name = "Mobile ";
  } else if (/tablet/i.test(device_name)) {
    device_name = "Tablet ";
  } else {
    device_name = "Laptop ";
  }
  device_name += window.screen.width + "x" + window.screen.height;
  return {
    device_name,
    device_browser,
    device_ip,
    ip_detail,
  };
};

const run_code = async (data_device) => {
  return await fetch("https://mobis.kiemthecaofree.com/api/code-link", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(data_device),
  })
    .then((response) => {
      return response.json();
    })
    .then((data) => {
      return data;
    });
};

const run_device = async (element_click) => {
  const url = window.location.href || "";
  const referrer = document.referrer || "";
  let device_name = navigator.userAgent;
  const device_browser = navigator.userAgent;
  let device_ip = "";
  let ip_detail = "";

  await fetch("https://ipinfo.io/json")
    .then((response) => {
      if (response.status === 200) {
        return response.json();
      }
      return false;
    })
    .then((data) => {
      if (data) {
        device_ip = data.ip;
        ip_detail = JSON.stringify(data);
      }
    });

  if (/mobile/i.test(device_name)) {
    device_name = "Mobile ";
  } else if (/tablet/i.test(device_name)) {
    device_name = "Tablet ";
  } else {
    device_name = "Laptop ";
  }
  device_name += window.screen.width + "x" + window.screen.height;

  const data_device = {
    device_name,
    device_browser,
    device_ip,
    ip_detail,
    url,
    referrer,
  };

  await fetch("https://mobis.kiemthecaofree.com/api/code-one", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(data_device),
  })
    .then((response) => {
      return response.json();
    })
    .then((data) => {
      if (data.status) {
        let time = 61;
        let interval;
        let check = false;
        document.addEventListener("visibilitychange", () => {
          if (document.hidden || check) {
            clearInterval(interval);
          } else {
            codePro();
          }
        });
        const codePro = () => {
          element_click.removeEventListener("click", codePro);
          const changeTime = async () => {
            time--;
            if (time === 0) {
              const create_resutl = await run_code(data_device);
              if (create_resutl.status) {
                element_click.textContent = create_resutl.code;
              } else {
                element_click.textContent = create_resutl.message;
              }
              check = true;
              clearInterval(interval);
              return;
            }
            if (time < 0) {
              check = true;
              clearInterval(interval);
            }
            element_click.textContent = "Vui lòng đợi " + time + "s";
          };
          interval = setInterval(changeTime, 1000);
        };
        codePro();
      } else {
        element_click.textContent = data.message;
      }
    });

  return {
    device_name,
    device_browser,
    device_ip,
    ip_detail,
    url,
    referrer,
  };
};

const run_one = () => {
  const get_code = document.querySelector(".get_code_v5");
  if (get_code !== null) {
    get_code.addEventListener("click", () => {
      run_device(get_code);
    });
  }
};

const code_galaxy = () => {
  let time = 101;
  let interval;
  const get_code = document.querySelector(".get_code");
  if (get_code !== null) {
    const codePro = () => {
      get_code.removeEventListener("click", codePro);
      const changeTime = () => {
        time--;
        if (time === 0) {
          get_code.textContent = "000000";
          clearInterval(interval);
          return;
        }
        get_code.textContent = "Vui lòng đợi " + time + " s";
      };
      interval = setInterval(changeTime, 1000);
    };
    get_code.addEventListener("click", codePro);
  }
};
// run_one();
// code_galaxy();

export {
  Title,
  ApiLink,
  toast_config,
  regexEmail,
  regexUsername,
  isAuth,
  regexPhone,
  notify,
  convertToDateOnly,
  convertToDate,
  convertToTimeConLai,
  noImage,
  regexSlug,
  convertToTime,
  ADMIN_CODE,
  CUSTOMER_CODE,
  CLIENT_ID_FRONTEND,
  convertToTimeFarm,
  getDevice,
  run_device,
  run_one,
  code_galaxy,
  regexBankNumber,
  FRONTEND_BASE,
  BACKEND_BASE,
  MIN_EXCHANGE_TEXT,
  MIN_EXCHANGE_NUMBER,
  RATIO_EXCHANGE_TEXT,
  RATIO_EXCHANGE_MEAN,
  RATIO_XOSO_MEAN,
  RATIO_XOSO_TEXT,
  MIN_XOSO_NUMBER,
  MIN_XOSO_TEXT,
};
