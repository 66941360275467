import React, { Fragment, useEffect, useState } from "react";
import "../../styles/App.css";
import axios from "axios";
import { ApiLink, notify, Title } from "../../utils/Title";
import { Link, useNavigate } from "react-router-dom";
import UserBoardLayout from "../../components/Layouts/BasicLayout/UserBoardLayout";
import NotifyLayout from "../../components/Layouts/BasicLayout/NotifyLayout";
import MultiplexAd from "../../components/AdSenseAd/MultiplexAd";
import AdSenseAd from "../../components/AdSenseAd/AdSenseAd";
import FooterOnline from "../../components/Layouts/BasicLayout/FooterOnline";
export default function RankPage() {
  const [loading, isLoading] = useState(true);
  const [info, setInfo] = useState(null);
  let [bxhXuList, setBXHXuList] = useState([]);
  let [bxhVNDList, setBXHVNDList] = useState([]);
  let [bxhTaskList, setBXHTaskList] = useState([]);
  let [thongKe, setThongKe] = useState(null);
  let navigate = useNavigate();
  let [username, setUsername] = useState("");
  let [showNotify, setShowNotify] = useState(false);

  useEffect(() => {
    document.title = Title.rank + Title.origin;
  }, []);

  const handleSetShowNotify = () => {
    setShowNotify(!showNotify);
  };

  useEffect(() => {
    async function getBXHXu() {
      const response = await axios.get(
        `${ApiLink.domain + "/event/ket-qua/bxh-xu"}`,
        {
          withCredentials: true,
        }
      );
      if (response.data.status) {
        setBXHXuList(response.data.data); // status, data
      } else {
        notify(false, response.data.message);
        if (response.data.must === "login") {
          window.localStorage.clear();
          return navigate("/account/login", { replace: true });
        } else {
          return navigate("/", { replace: true });
        }
      }
    }

    async function getBXHVND() {
      const response = await axios.get(
        `${ApiLink.domain + "/event/ket-qua/bxh-vnd"}`,
        {
          withCredentials: true,
        }
      );
      if (response.data.status) {
        setBXHVNDList(response.data.data); // status, data
      } else {
        notify(false, response.data.message);
        if (response.data.must === "login") {
          window.localStorage.clear();
          return navigate("/account/login", { replace: true });
        } else {
          return navigate("/", { replace: true });
        }
      }
    }

    async function getBXHTask() {
      const response = await axios.get(
        `${ApiLink.domain + "/event/ket-qua/bxh-task"}`,
        {
          withCredentials: true,
        }
      );
      if (response.data.status) {
        setBXHTaskList(response.data.data); // status, data
      } else {
        notify(false, response.data.message);
        if (response.data.must === "login") {
          window.localStorage.clear();
          return navigate("/account/login", { replace: true });
        } else {
          return navigate("/", { replace: true });
        }
      }
    }

    async function getThongKe() {
      const response = await axios.get(
        `${ApiLink.domain + "/event/ket-qua/thong-ke"}`,
        {
          withCredentials: true,
        }
      );
      if (response.data.status) {
        setThongKe({
          xu_total: response.data.xu_total,
          user_total: response.data.user_total,
          vnd_total: response.data.vnd_total,
        });
      } else {
        notify(false, response.data.message);
        if (response.data.must === "login") {
          window.localStorage.clear();
          return navigate("/account/login", { replace: true });
        } else {
          return navigate("/", { replace: true });
        }
      }
    }

    async function getUserInfo() {
      const response = await axios.get(`${ApiLink.domain + "/account/info"}`, {
        withCredentials: true,
      });
      if (response.data.status) {
        setUsername(response.data.data.username); // status, data
        setInfo(response.data.data);
      } else {
        // notify(false, response.data.message);
        if (response.data.must === "login") {
          window.localStorage.clear();
          return navigate("/account/login", { replace: true });
        }
      }
    }

    const getAll = async () => {
      Promise.all([
        Promise.resolve(getUserInfo()),
        Promise.resolve(getBXHXu()),
        Promise.resolve(getBXHVND()),
        Promise.resolve(getBXHTask()),
        Promise.resolve(getThongKe()),
      ]);
      isLoading(false);
    };
    getAll();
  }, [navigate]);

  return loading ? (
    <div></div>
  ) : (
    <div className="app_container">
      <AdSenseAd />
      <UserBoardLayout
        Element={null}
        info={info}
        setInfo={setInfo}
        username={username}
        setUsername={setUsername}
        handleShowNotify={handleSetShowNotify}
        showNotify={showNotify}
      />
      {showNotify && <NotifyLayout />}
      <div className="list_app">
        <div className="list_name">TOP 10 TRIỆU PHÚ MỚI NỔI</div>
        {bxhXuList.map((user, index) => {
          return (
            <Fragment key={index}>
              <div
                className="one_app"
                style={{
                  marginTop: index === 0 ? "0px" : "10px",
                  borderBottom: "1px dashed #ddd",
                  backgroundColor: "#fff",
                }}
              >
                <div className="one_app--top">
                  <img
                    className="one_app--top_left"
                    src={"/images/farm/user/" + user.user_image + ".gif"}
                    alt="image_app"
                    style={{
                      width: "auto",
                      height: "45px",
                    }}
                  ></img>
                  <div className="one_app--top_right">
                    <div className="app_title">
                      {index < 3 ? (
                        <img
                          className="app_title_icon"
                          alt="app_title_icon"
                          src={"/images/rank/xu_" + (index + 1) + ".gif"}
                        ></img>
                      ) : (
                        <img
                          className="app_title_icon"
                          alt="app_title_icon"
                          src={"/images/rank/" + (index + 1) + ".gif"}
                        ></img>
                      )}
                      <span
                        className="app_title_name"
                        style={{
                          color: index > 2 ? "rgb(0, 128, 0)" : "#ad4105",
                        }}
                      >
                        {`#${index + 1} ${user.username}`}
                      </span>
                    </div>
                    <div className="app_price">
                      <span className="one_bxh_child_title">Tiền mặt:</span>
                      <span className="one_app_child_content">
                        {Number(user.xu).toLocaleString("vi") + "đ"}
                      </span>
                    </div>

                    <div className="app_price">
                      <span className="one_bxh_child_title">
                        Thăm nông trại:
                      </span>
                      <Link
                        className="one_app_child_content"
                        to={"/farm/view/" + user.username}
                      >
                        Vào xem
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </Fragment>
          );
        })}
      </div>
      <div className="list_app" style={{ marginTop: "10px" }}>
        <div className="list_name">TOP 10 THẦN NÔNG SIÊU GIÀU</div>
        {bxhVNDList.map((user, index) => {
          return (
            <Fragment key={index}>
              <div
                className="one_app"
                style={{
                  marginTop: index === 0 ? "0px" : "10px",
                  borderBottom: "1px dashed #ddd",
                  backgroundColor: "#fff",
                }}
              >
                <div className="one_app--top">
                  <img
                    className="one_app--top_left"
                    src={"/images/farm/user/" + user.user_image + ".gif"}
                    alt="image_app"
                    style={{
                      width: "auto",
                      height: "45px",
                    }}
                  ></img>
                  <div className="one_app--top_right">
                    <div className="app_title">
                      {index < 3 ? (
                        <img
                          className="app_title_icon"
                          alt="app_title_icon"
                          src={"/images/rank/xu_" + (index + 1) + ".gif"}
                        ></img>
                      ) : (
                        <img
                          className="app_title_icon"
                          alt="app_title_icon"
                          src={"/images/rank/" + (index + 1) + ".gif"}
                        ></img>
                      )}
                      <span
                        className="app_title_name"
                        style={{
                          color: index > 2 ? "rgb(0, 128, 0)" : "#ad4105",
                        }}
                      >
                        {`#${index + 1} ${user.username}`}
                      </span>
                    </div>
                    <div className="app_price">
                      <span className="one_bxh_child_title">
                        Tiền nông trại:
                      </span>
                      <span className="one_app_child_content">
                        {Number(user.vnd).toLocaleString("vi") + "đ"}
                      </span>
                    </div>

                    <div className="app_price">
                      <span className="one_bxh_child_title">
                        Thăm nông trại:
                      </span>
                      <Link
                        className="one_app_child_content"
                        to={"/farm/view/" + user.username}
                      >
                        Vào xem
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </Fragment>
          );
        })}
      </div>
      <div className="list_app" style={{ marginTop: "10px" }}>
        <div className="list_name">TOP 10 THÀNH VIÊN CHĂM CHỈ</div>
        {bxhTaskList.map((user, index) => {
          return (
            <Fragment key={index}>
              <div
                className="one_app"
                style={{
                  marginTop: index === 0 ? "0px" : "10px",
                  borderBottom: "1px dashed #ddd",
                  backgroundColor: "#fff",
                }}
              >
                <div className="one_app--top">
                  <img
                    className="one_app--top_left"
                    src={"/images/farm/user/" + user.user_image + ".gif"}
                    alt="image_app"
                    style={{
                      width: "auto",
                      height: "45px",
                    }}
                  ></img>
                  <div className="one_app--top_right">
                    <div className="app_title">
                      {index < 3 ? (
                        <img
                          className="app_title_icon"
                          alt="app_title_icon"
                          src={"/images/rank/xu_" + (index + 1) + ".gif"}
                        ></img>
                      ) : (
                        <img
                          className="app_title_icon"
                          alt="app_title_icon"
                          src={"/images/rank/" + (index + 1) + ".gif"}
                        ></img>
                      )}
                      <span
                        className="app_title_name"
                        style={{
                          color: index > 2 ? "rgb(0, 128, 0)" : "#ad4105",
                        }}
                      >
                        {`#${index + 1} ${user.username}`}
                      </span>
                    </div>
                    <div className="app_price">
                      <span className="one_bxh_child_title">
                        Đã hoàn thành:
                      </span>
                      <span className="one_app_child_content">
                        {user.task_complete + " nhiệm vụ"}
                      </span>
                    </div>

                    <div className="app_price">
                      <span className="one_bxh_child_title">
                        Thăm nông trại:
                      </span>
                      <Link
                        className="one_app_child_content"
                        to={"/farm/view/" + user.username}
                      >
                        Vào xem
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </Fragment>
          );
        })}
      </div>

      <div className="list_app" style={{ marginTop: "10px" }}>
        <div className="list_name">THỐNG KÊ SERVER</div>
        {thongKe && (
          <Fragment>
            <div
              className="one_app"
              style={{
                borderBottom: "1px dashed #ddd",
                backgroundColor: "#fff",
              }}
            >
              <div className="one_app--top">
                <div className="one_app--top_right">
                  <div
                    className="app_price"
                    style={{
                      marginTop: "0px !important",
                    }}
                  >
                    <span
                      style={{
                        color: "rgb(0, 128, 0)",
                        fontWeight: "bold",
                      }}
                      className="one_bxh_child_title_v2"
                    >
                      Số thành viên:
                    </span>
                    <span className="one_app_child_content">
                      {Number(thongKe.user_total).toLocaleString("vi")}
                    </span>
                  </div>

                  <div className="app_price">
                    <span
                      style={{
                        color: "rgb(0, 128, 0)",
                        fontWeight: "bold",
                      }}
                      className="one_bxh_child_title"
                    >
                      Tổng số tiền mặt:
                    </span>
                    <span className="one_app_child_content">
                      {Number(thongKe.xu_total).toLocaleString("vi") + "đ"}
                    </span>
                  </div>
                  <div className="app_price">
                    <span
                      style={{
                        color: "rgb(0, 128, 0)",
                        fontWeight: "bold",
                      }}
                      className="one_bxh_child_title"
                    >
                      Tổng số tiền nông trại:
                    </span>
                    <span className="one_app_child_content">
                      {Number(thongKe.vnd_total).toLocaleString("vi") + "đ"}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </Fragment>
        )}
      </div>
      <MultiplexAd />
      <FooterOnline margin_top={"15px"} />
    </div>
  );
}
