import React, { Fragment, useEffect, useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import ReactPaginate from "react-paginate";
import {
  ApiLink,
  convertToDate,
  MIN_EXCHANGE_NUMBER,
  MIN_EXCHANGE_TEXT,
  notify,
  RATIO_EXCHANGE_MEAN,
  RATIO_EXCHANGE_TEXT,
  Title,
} from "../../../utils/Title";
import UserLogo from "./UserLogo";
export default function ExChange({ username }) {
  let [farmHistoryList, setFarmHistoryList] = useState([]);
  let [userInfo, setUserInfo] = useState(null);
  let [loading, isLoading] = useState(true);
  let [page, setPage] = useState(1);
  let [vndExchange, setVNDExchange] = useState("");
  const maxShow = 10;

  const handlePageClick = (event) => {
    if (page !== event.selected + 1) setPage(event.selected + 1);
  };

  let navigate = useNavigate();

  useEffect(() => {
    document.title = Title.user_exchange + Title.origin;
  }, []);

  useEffect(() => {
    const getUserInfo = async () => {
      const response = await axios.get(`${ApiLink.domain + "/account/info"}`, {
        withCredentials: true,
      });
      if (response.data.status) {
        setUserInfo(response.data.data);
      } else {
        notify(false, response.data.message);
        if (response.data.must === "login") {
          return navigate("/account/login", { replace: true });
        } else {
          return navigate("/", { replace: true });
        }
      }
    };

    const getHistoryFarmUser = async () => {
      const response = await axios.get(`${ApiLink.domain + "/history-farm"}`, {
        withCredentials: true,
      });
      if (response.data.status) {
        setFarmHistoryList(response.data.data);
      } else {
        notify(false, response.data.message);
        if (response.data.must === "login") {
          return navigate("/account/login", { replace: true });
        } else {
          return navigate("/", { replace: true });
        }
      }
    };

    const handleAPIAll = async () => {
      await Promise.all([
        Promise.resolve(getUserInfo()),
        Promise.resolve(getHistoryFarmUser()),
      ]);
      isLoading(false);
    };
    handleAPIAll();
  }, [navigate]);

  const handeleExchange = async () => {
    if (
      !Number.isInteger(vndExchange) ||
      Number(vndExchange) < MIN_EXCHANGE_NUMBER
    ) {
      notify(false, "Số tiền quy đổi không hợp lệ. Tối thiểu 20.000đ");
      return;
    } else if (Number(vndExchange) % 10000 !== 0) {
      notify(false, "Số tiền quy đổi phải chia hết cho 10.000đ");
      return;
    }

    const response = await axios.post(
      `${ApiLink.domain + "/pay-exchange"}`,
      { vnd: Number(vndExchange) },
      {
        withCredentials: true,
      }
    );
    notify(response.data.status, response.data.message);
    if (response.data.status) {
      setVNDExchange("");
      setUserInfo({
        ...userInfo,
        xu: response.data.xu,
        vnd: response.data.vnd,
      });
      setFarmHistoryList(response.data.history_list);
    }
  };

  return loading ? (
    ""
  ) : (
    <Fragment>
      <div id="main">
        <UserLogo />
        <div className="page-content">
          <section
            className="row"
            style={{
              marginLeft: "-5px",
              marginRight: "-5px",
            }}
          >
            <div className="col-12 col-lg-12">
              <div className="row">
                <div className="col-12">
                  <div
                    className="col-lg-12 stretch-card"
                    style={{ padding: 0 }}
                  >
                    <div className="card">
                      <div className="card-body">
                        <div className="ref_detail">
                          <div className="ref_friend">
                            <div>- Số dư nông trại: </div>
                            <span style={{ margin: "0px 2px" }}></span>
                            <div
                              style={{
                                color: "#28a745",
                              }}
                            >
                              {userInfo.vnd.toLocaleString("vi") + " đồng"}
                            </div>
                          </div>
                          <div className="ref_friend">
                            <div>- Tiền mặt: </div>
                            <span style={{ margin: "0px 2px" }}></span>
                            <div
                              style={{
                                color: "#28a745",
                              }}
                            >
                              {userInfo.xu.toLocaleString("vi") + " đồng"}
                            </div>
                          </div>

                          <div className="ref_friend">
                            <div>
                              - Tỉ lệ quy đổi:{" "}
                              <span
                                style={{
                                  color: "rgb(0, 123, 255)",
                                  cursor: "pointer",
                                }}
                              >
                                {RATIO_EXCHANGE_TEXT}{" "}
                                <span
                                  onClick={() => {
                                    alert(RATIO_EXCHANGE_MEAN);
                                  }}
                                  style={{
                                    color: "rgb(220, 53, 69)",
                                  }}
                                >
                                  *Giải thích
                                </span>
                              </span>
                            </div>
                          </div>

                          <div className="ref_friend">
                            <div>- Quy đổi tối thiểu: </div>
                            <span style={{ margin: "0px 2px" }}></span>
                            <div
                              style={{
                                color: "rgb(220, 53, 69)",
                              }}
                            >
                              {MIN_EXCHANGE_TEXT}
                            </div>
                          </div>

                          <div className="ref_friend">
                            <div>- Số lần quy đổi: </div>
                            <span style={{ margin: "0px 2px" }}></span>
                            <div
                              style={{
                                color: "rgb(220, 53, 69)",
                              }}
                            >
                              1 lần / ngày
                            </div>
                          </div>

                          <div className="ref_friend">
                            <span>
                              - Hướng dẫn:{" "}
                              <span
                                style={{
                                  color: "#007bff",
                                }}
                              >
                                Nhập số tiền và bấm "Quy đổi"
                              </span>
                            </span>
                            <span style={{ margin: "0px 2px" }}></span>
                          </div>

                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                            }}
                          >
                            <label
                              style={{
                                marginBottom: 0,
                                marginRight: "5px",
                              }}
                              className="form-label"
                            >
                              {" "}
                              - Nhập số tiền:
                            </label>
                            <input
                              style={{
                                width: "30vw",
                              }}
                              className="form-control showordisable"
                              name="vndExchange"
                              defaultValue={vndExchange}
                              type="number"
                              placeholder="Nhập số tiền"
                              onInput={(e) => {
                                setVNDExchange(Number(e.target.value));
                              }}
                            />
                          </div>

                          <div
                            className="ref_friend"
                            style={{
                              textAlign: "center",
                              display: "block",
                            }}
                          >
                            <button
                              className="btn btn-success btn_change_status"
                              onClick={() => {
                                handeleExchange();
                              }}
                            >
                              Quy đổi
                            </button>
                          </div>
                        </div>

                        <h4
                          className="card-title"
                          style={{
                            textAlign: "center",
                            marginTop: 20,
                          }}
                        >
                          Lịch sử nông trại
                        </h4>
                        <div className="table-responsive pt-3">
                          <table className="table table-bordered text-center">
                            <thead>
                              <tr>
                                <th>STT</th>
                                <th>Username</th>
                                <th>Thời gian</th>
                                <th>Ghi chú</th>
                                <th>Số dư đầu</th>
                                <th>Số dư cuối</th>
                              </tr>
                            </thead>
                            <tbody>
                              {farmHistoryList.map((history, index) => {
                                if (
                                  index >= (page - 1) * maxShow &&
                                  index < page * maxShow
                                ) {
                                  return (
                                    <tr key={index} className="table-white">
                                      <td>{index + 1}</td>
                                      <td>{history.User.username}</td>
                                      <td>
                                        {convertToDate(history.createdAt)}
                                      </td>
                                      <td>{history.note}</td>
                                      <td>
                                        {history.vnd_start.toLocaleString(
                                          "vi"
                                        ) + " đồng"}
                                      </td>
                                      <td>
                                        {history.vnd_end.toLocaleString("vi") +
                                          " đồng"}
                                      </td>
                                    </tr>
                                  );
                                } else return "";
                              })}
                            </tbody>
                          </table>
                        </div>
                        <div
                          className="flex_center pagination_mg"
                          style={{
                            marginBottom: 20,
                          }}
                        >
                          <div>Tổng số: {farmHistoryList.length}</div>
                          <ReactPaginate
                            breakLabel="..."
                            nextLabel="Next"
                            onPageChange={handlePageClick}
                            pageRangeDisplayed={2}
                            pageCount={
                              farmHistoryList.length <= maxShow
                                ? 1
                                : farmHistoryList.length % maxShow === 0
                                ? Math.floor(farmHistoryList.length / maxShow)
                                : Math.floor(farmHistoryList.length / maxShow) +
                                  1
                            }
                            previousLabel="Previous"
                            renderOnZeroPageCount={null}
                            pageClassName="page-item"
                            pageLinkClassName="page-link"
                            previousClassName="page-item"
                            previousLinkClassName="page-link"
                            nextClassName="page-item"
                            nextLinkClassName="page-link"
                            breakClassName="page-item"
                            breakLinkClassName="page-link"
                            containerClassName="pagination"
                            activeClassName="active"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
    </Fragment>
  );
}
