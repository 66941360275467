import ForgotPasswordLayout from "../components/Layouts/AccountLayout/ForgotPasswordLayout";
import LoginLayout from "../components/Layouts/AccountLayout/LoginLayout";
import ResendEmailLayout from "../components/Layouts/AccountLayout/ResendEmailLayout";
import SignLayout from "../components/Layouts/AccountLayout/SignLayout";
import VerifyEmailLayout from "../components/Layouts/AccountLayout/VerifyEmailLayout";
import MobileAppAdmin from "../components/Layouts/AdminLayout/MobileApp";
import CategoryAdmin from "../components/Layouts/AdminLayout/CategoryAdmin";
import StatusAppAdmin from "../components/Layouts/AdminLayout/StatusAppAdmin";
import RoleAdmin from "../components/Layouts/AdminLayout/RoleAdmin";
import CustomerAdmin from "../components/Layouts/AdminLayout/CustomerAdmin";
import OTPAdmin from "../components/Layouts/AdminLayout/OTPAdmin";
import AdminPage from "../pages/AdminPage";
import AppPage from "../pages/AppPage";
import LoginPage from "../pages/LoginPage";
import UserPage from "../pages/UserPage";
import UserInfo from "../components/Layouts/UserLayout/UserInfo";
import ErrorPage from "../pages/ErrorPage";
import TaskLinkAdmin from "../components/Layouts/AdminLayout/TaskLinkAdmin";
import TaskHistoryAdmin from "../components/Layouts/AdminLayout/TaskHistoryAdmin";
import CodeLinkAdmin from "../components/Layouts/AdminLayout/CodeLinkAdmin";
import XuHistoryAdmin from "../components/Layouts/AdminLayout/XuHistoryAdmin";
import FarmPage from "../pages/FarmPage/index";
import TaskLinkPage from "../pages/TaskLinkPage";
import HistoryXu from "../components/Layouts/UserLayout/HistoryXu";
import PayHistory from "../components/Layouts/UserLayout/PayHistory";
import PayInfo from "../components/Layouts/UserLayout/PayInfo";
import Withdraw from "../components/Layouts/UserLayout/Withdraw";
import Logout from "../components/Layouts/AccountLayout/Logout";
import ChatPage from "../pages/ChatPage";
import OrderPage from "../pages/OrderPage";
import OrderHistoryAdmin from "../components/Layouts/AdminLayout/OrderHistoryAdmin";
import ReferenceFriend from "../components/Layouts/UserLayout/ReferenceFriend";
import FarmHistoryAdmin from "../components/Layouts/AdminLayout/FarmHistoryAdmin";
import DeviceUserAdmin from "../components/Layouts/AdminLayout/DeviceUserAdmin";
import FarmSeedAdmin from "../components/Layouts/AdminLayout/FarmSeedAdmin";
import LuckyWheelPage from "../pages/LuckyWheelPage";
import EventPage from "../pages/EventPage";
import PayHistoryAdmin from "../components/Layouts/AdminLayout/PayHistoryAdmin";
import ExChange from "../components/Layouts/UserLayout/ExChange";
import FishGatePage from "../pages/FishGatePage";
import FishPage from "../pages/FishPage";
import XoSo from "../components/Layouts/UserLayout/XoSo.js";
import FarmAnimalAdmin from "../components/Layouts/AdminLayout/FarmAnimalAdmin.js";
import ShopCategoryAdmin from "../components/Layouts/AdminLayout/ShopCategoryAdmin.js";
import ShopItemAdmin from "../components/Layouts/AdminLayout/ShopItemAdmin.js";
import FishAdmin from "../components/Layouts/AdminLayout/FishAdmin.js";
import UserAnimalAdmin from "../components/Layouts/AdminLayout/UserAnimalAdmin.js";
import UserFarmAdmin from "../components/Layouts/AdminLayout/UserFarmAdmin.js";
import UserShopAdmin from "../components/Layouts/AdminLayout/UserShopAdmin.js";
import UserFarmStockAdmin from "../components/Layouts/AdminLayout/UserFarmStockAdmin.js";
import UserAnimalStockAdmin from "../components/Layouts/AdminLayout/UserAnimalStockAdmin.js";
import UserSeedAdmin from "../components/Layouts/AdminLayout/UserSeedAdmin.js";
import DuDoanXoSoAdmin from "../components/Layouts/AdminLayout/DuDoanXoSoAdmin.js";
import KetQuaXoSoAdmin from "../components/Layouts/AdminLayout/KetQuaXoSoAdmin.js";
import TaskEverydayAdmin from "../components/Layouts/AdminLayout/TaskEverydayAdmin.js";
import TaskEverydayHistoryAdmin from "../components/Layouts/AdminLayout/TaskEverydayHistoryAdmin.js";
import TaskEverydayPage from "../pages/TaskEverydayPage/index.js";
import GiftOpen from "../components/Layouts/UserLayout/GiftOpen.js";
import RankPage from "../pages/RankPage/index.js";
import FarmViewPage from "../pages/FarmViewPage/index.js";
import PayBankAdmin from "../components/Layouts/AdminLayout/PayBankAdmin.js";
import PayMethodAdmin from "../components/Layouts/AdminLayout/PayMethodAdmin.js";
import PayDetailAdmin from "../components/Layouts/AdminLayout/PayDetailAdmin.js";
import FishHistory from "../components/Layouts/UserLayout/FishHistory.js";

const publicRoutes = [
  { path: "/", component: AppPage },
  { path: "/event", component: EventPage },
  { path: "/task-link", component: TaskLinkPage },
  { path: "/account/login", component: LoginPage, layout: LoginLayout },
  { path: "/account/signup", component: LoginPage, layout: SignLayout },
  { path: "/account/logout", component: Logout },
  { path: "/404", component: ErrorPage },
  {
    path: "/account/lost-password",
    component: LoginPage,
    layout: ForgotPasswordLayout,
  },
  {
    path: "/account/verify/email/:email/:token",
    component: LoginPage,
    layout: VerifyEmailLayout,
  },
  {
    path: "/account/resend-email",
    component: LoginPage,
    layout: ResendEmailLayout,
  },
];

const privateRoutes = [
  {
    path: "/admin/mobile-app",
    component: AdminPage,
    layout: MobileAppAdmin,
    active_id: "ADMIN_MOBILE_APP",
  },
  {
    path: "/admin/category",
    component: AdminPage,
    layout: CategoryAdmin,
    active_id: "ADMIN_CATEGORY",
  },
  {
    path: "/admin/status-app",
    component: AdminPage,
    layout: StatusAppAdmin,
    active_id: "ADMIN_STATUS_APP",
  },
  {
    path: "/admin/customer",
    component: AdminPage,
    layout: CustomerAdmin,
    active_id: "ADMIN_CUSTOMER",
  },
  {
    path: "/admin/role",
    component: AdminPage,
    layout: RoleAdmin,
    active_id: "ADMIN_ROLE",
  },
  {
    path: "/admin/otp",
    component: AdminPage,
    layout: OTPAdmin,
    active_id: "ADMIN_OTP",
  },

  {
    path: "/admin/task-link",
    component: AdminPage,
    layout: TaskLinkAdmin,
    active_id: "ADMIN_TASKLINK",
  },

  {
    path: "/admin/task-history",
    component: AdminPage,
    layout: TaskHistoryAdmin,
    active_id: "ADMIN_TASKHISTORY",
  },

  {
    path: "/admin/code-link",
    component: AdminPage,
    layout: CodeLinkAdmin,
    active_id: "ADMIN_CODELINK",
  },

  {
    path: "/admin/history",
    component: AdminPage,
    layout: XuHistoryAdmin,
    active_id: "ADMIN_XUHISTORY",
  },

  {
    path: "/admin/farm-history",
    component: AdminPage,
    layout: FarmHistoryAdmin,
    active_id: "ADMIN_FARMHISTORY",
  },

  {
    path: "/admin/du-doan-xo-so",
    component: AdminPage,
    layout: DuDoanXoSoAdmin,
    active_id: "ADMIN_DU_DOAN_XO_SO",
  },
  {
    path: "/admin/ket-qua-xo-so",
    component: AdminPage,
    layout: KetQuaXoSoAdmin,
    active_id: "ADMIN_KET_QUA_XO_SO",
  },

  {
    path: "/admin/task-everyday",
    component: AdminPage,
    layout: TaskEverydayAdmin,
    active_id: "ADMIN_TASK_EVERYDAY",
  },

  {
    path: "/admin/task-everyday-history",
    component: AdminPage,
    layout: TaskEverydayHistoryAdmin,
    active_id: "ADMIN_TASK_EVERYDAY_HISTORY",
  },

  {
    path: "/admin/device-user",
    component: AdminPage,
    layout: DeviceUserAdmin,
    active_id: "ADMIN_DEVICE_USER",
  },
  {
    path: "/admin/farm-seed",
    component: AdminPage,
    layout: FarmSeedAdmin,
    active_id: "ADMIN_FARM_SEED",
  },

  {
    path: "/admin/farm-animal",
    component: AdminPage,
    layout: FarmAnimalAdmin,
    active_id: "ADMIN_FARM_ANIMAL",
  },
  {
    path: "/admin/fish",
    component: AdminPage,
    layout: FishAdmin,
    active_id: "ADMIN_FISH",
  },

  {
    path: "/admin/user-animal",
    component: AdminPage,
    layout: UserAnimalAdmin,
    active_id: "ADMIN_USER_ANIMAL",
  },
  {
    path: "/admin/user-farm",
    component: AdminPage,
    layout: UserFarmAdmin,
    active_id: "ADMIN_USER_FARM",
  },
  {
    path: "/admin/user-shop",
    component: AdminPage,
    layout: UserShopAdmin,
    active_id: "ADMIN_USER_SHOP",
  },
  {
    path: "/admin/user-farm-stock",
    component: AdminPage,
    layout: UserFarmStockAdmin,
    active_id: "ADMIN_USER_FARM_STOCK",
  },
  {
    path: "/admin/user-animal-stock",
    component: AdminPage,
    layout: UserAnimalStockAdmin,
    active_id: "ADMIN_USER_ANIMAL_STOCK",
  },
  {
    path: "/admin/user-seed",
    component: AdminPage,
    layout: UserSeedAdmin,
    active_id: "ADMIN_USER_SEED",
  },

  {
    path: "/admin/shop-category",
    component: AdminPage,
    layout: ShopCategoryAdmin,
    active_id: "ADMIN_SHOP_CATEGORY",
  },

  {
    path: "/admin/shop-item",
    component: AdminPage,
    layout: ShopItemAdmin,
    active_id: "ADMIN_SHOP_ITEM",
  },

  {
    path: "/admin/order-history",
    component: AdminPage,
    layout: OrderHistoryAdmin,
    active_id: "ADMIN_ORDER_HISTORY",
  },

  {
    path: "/admin/pay-history",
    component: AdminPage,
    layout: PayHistoryAdmin,
    active_id: "ADMIN_PAY_HISTORY",
  },

  {
    path: "/admin/pay-method",
    component: AdminPage,
    layout: PayMethodAdmin,
    active_id: "ADMIN_PAY_METHOD",
  },

  {
    path: "/admin/pay-detail",
    component: AdminPage,
    layout: PayDetailAdmin,
    active_id: "ADMIN_PAY_DETAIL",
  },

  {
    path: "/admin/pay-bank",
    component: AdminPage,
    layout: PayBankAdmin,
    active_id: "ADMIN_PAY_BANK",
  },

  {
    path: "/account/info",
    component: UserPage,
    layout: UserInfo,
    active_id: 1,
  },
  {
    path: "/account/history",
    component: UserPage,
    layout: HistoryXu,
    active_id: 2,
  },

  {
    path: "/account/pay-history",
    component: UserPage,
    layout: PayHistory,
    active_id: 3,
  },

  {
    path: "/account/pay-info",
    component: UserPage,
    layout: PayInfo,
    active_id: 4,
  },
  {
    path: "/account/withdraw",
    component: UserPage,
    layout: Withdraw,
    active_id: 5,
  },
  {
    path: "/account/invite",
    component: UserPage,
    layout: ReferenceFriend,
    active_id: 6,
  },
  {
    path: "/account/exchange",
    component: UserPage,
    layout: ExChange,
    active_id: 7,
  },

  {
    path: "/account/ket-qua-xo-so",
    component: UserPage,
    layout: XoSo,
    active_id: 8,
  },
  {
    path: "/account/open-gift",
    component: UserPage,
    layout: GiftOpen,
    active_id: 9,
  },
  {
    path: "/account/fish-history",
    component: UserPage,
    layout: FishHistory,
    active_id: 10,
  },

  {
    path: "/farm",
    component: FarmPage,
  },

  {
    path: "/task-everyday",
    component: TaskEverydayPage,
  },

  {
    path: "/fish-gate",
    component: FishGatePage,
  },
  {
    path: "/fish",
    component: FishPage,
  },

  {
    path: "/chat",
    component: ChatPage,
  },
  {
    path: "/rank",
    component: RankPage,
  },
  {
    path: "/farm/view/:username",
    component: FarmViewPage,
  },
  {
    path: "/lucky-wheel",
    component: LuckyWheelPage,
  },

  {
    path: "/order-check",
    component: OrderPage,
  },
];

export { publicRoutes, privateRoutes };
