import React, { Fragment, useEffect, useState } from "react";
import "../../styles/App.css";
import axios from "axios";
import {
  ApiLink,
  convertToDate,
  getDevice,
  notify,
  Title,
} from "../../utils/Title";
import { useNavigate } from "react-router-dom";
import UserBoardLayout from "../../components/Layouts/BasicLayout/UserBoardLayout";
import NotifyLayout from "../../components/Layouts/BasicLayout/NotifyLayout";
import Footer from "../../components/Layouts/BasicLayout/Footer";
import MultiplexAd from "../../components/AdSenseAd/MultiplexAd";
import AdSenseAd from "../../components/AdSenseAd/AdSenseAd";
export default function TaskLinkPage() {
  const [show, setShow] = useState(-1);
  const [loading, isLoading] = useState(true);
  const [info, setInfo] = useState(null);
  let [taskList, setTaskList] = useState([]);
  let navigate = useNavigate();
  let [inputCode, setInputCode] = useState([]);
  let [username, setUsername] = useState("");
  let [showNotify, setShowNotify] = useState(false);

  useEffect(() => {
    document.title = Title.task_link + Title.origin;
  }, []);

  const handelAddInputCode = (task_list) => {
    const code_list = task_list.map((task) => ({
      task_id: task.task_id,
      code: "",
    }));
    setInputCode([...code_list]);
  };

  const handleSetShowNotify = () => {
    setShowNotify(!showNotify);
  };

  useEffect(() => {
    async function getAllTaskLink() {
      const response = await axios.get(`${ApiLink.domain + "/tasks"}`, {
        withCredentials: true,
      });
      if (response.data.status) {
        setTaskList(response.data.data); // status, data
        handelAddInputCode(response.data.data);
      } else {
        if (response.data.must === "login") {
          window.localStorage.clear();
          notify(false, response.data.message);
          return navigate("/account/login", { replace: true });
        } else if (response.data.must === "permission") {
          notify(false, response.data.message);
          return navigate("/", { replace: true });
        } else {
          return navigate("/404", { replace: true });
        }
      }
    }

    async function getUserInfo() {
      const response = await axios.get(`${ApiLink.domain + "/account/info"}`, {
        withCredentials: true,
      });
      if (response.data.status) {
        setUsername(response.data.data.username); // status, data
        setInfo(response.data.data);
      } else {
        // notify(false, response.data.message);
        if (response.data.must === "login") {
          window.localStorage.clear();
          return navigate("/account/login", { replace: true });
        }
      }
    }

    const getAll = async () => {
      Promise.all([
        Promise.resolve(getUserInfo()),
        Promise.resolve(getAllTaskLink()),
      ]);
      isLoading(false);
    };
    getAll();
  }, [navigate]);

  async function handleBatDauLam(task_id) {
    const device_info = await getDevice();

    const task_history = {
      ...device_info,
      task_id,
    };
    const response = await axios.post(
      `${ApiLink.domain + "/task-history"}`,
      task_history,
      {
        withCredentials: true,
      }
    );
    notify(response.data.status, response.data.message);
    if (response.data.status) {
      setTaskList(response.data.data); // status, data
      handelAddInputCode(response.data.data);
    } else {
      if (response.data.must === "login") {
        window.localStorage.clear();
        return navigate("/account/login", { replace: true });
      } else if (response.data.must === "permission") {
        return navigate("/", { replace: true });
      }
    }
  }

  const handleViewDetail = (id) => {
    id === show ? setShow(-1) : setShow(id);
  };

  const getTimeKetThuc = (time_start) => {
    let date = new Date(Number(Date.parse(time_start)));
    date = new Date(date.setMinutes(date.getMinutes() + 10));
    const time_toi_uu =
      String(date.getDate()).padStart(2, "0") +
      "/" +
      String(date.getMonth() + 1).padStart(2, "0") +
      "/" +
      date.getFullYear() +
      " " +
      String(date.getHours()).padStart(2, "0") +
      ":" +
      String(date.getMinutes()).padStart(2, "0") +
      ":" +
      String(date.getSeconds()).padStart(2, "0");
    return time_toi_uu;
  };

  const handleChangeInputCode = (value, task_id) => {
    const index = inputCode.findIndex((item) => item.task_id === task_id);
    inputCode[index].code = value;
    setInputCode([...inputCode]);
  };

  const handleCheckCode = async (task_id, task_history_id) => {
    const input_code = inputCode.find((code) => code.task_id === task_id);
    const code_value = {
      task_id,
      task_history_id,
      code: input_code.code,
    };
    const response = await axios.post(
      `${ApiLink.domain + "/task-history/code"}`,
      code_value,
      {
        withCredentials: true,
      }
    );
    notify(response.data.status, response.data.message);
    if (response.data.status) {
      setTaskList(response.data.data); // status, data
      handelAddInputCode(response.data.data);
    } else {
      if (response.data?.data) {
        setTaskList(response.data.data); // status, data
      }
      if (response.data.must === "login") {
        window.localStorage.clear();
        return navigate("/account/login", { replace: true });
      } else if (response.data.must === "permission") {
        return navigate("/", { replace: true });
      }
    }
  };

  return loading ? (
    <div></div>
  ) : (
    <div className="app_container">
      <AdSenseAd/>
      <UserBoardLayout
        Element={null}
        info={info}
        setInfo={setInfo}
        username={username}
        setUsername={setUsername}
        handleShowNotify={handleSetShowNotify}
        showNotify={showNotify}
      />
      {showNotify && <NotifyLayout />}
      <div className="list_app">
        <div className="list_name">Danh sách nhiệm vụ</div>
        {taskList.map((item, index) => {
          if (item.task_status === 2) {
            return <Fragment></Fragment>;
          } else {
            return (
              <Fragment key={index}>
                <div
                  className="one_app"
                  style={{
                    marginTop: index === 0 ? "0px" : "10px",
                  }}
                >
                  <div className="one_app--top">
                    <img
                      className="one_app--top_left"
                      src="/images/image/kiem-tien-task-link.webp"
                      alt="image_app"
                    ></img>
                    <div className="one_app--top_right">
                      <div className="app_title">
                        <img
                          className="app_title_icon"
                          alt="app_title_icon"
                          src="https://kiemthecaofree.com/hot.gif"
                        ></img>
                        <span className="app_title_name">
                          {`#${index + 1} ${item?.task_name}`}
                        </span>
                      </div>
                      <div className="app_price">
                        <span className="one_app_child_title">
                          Tiền thưởng:
                        </span>
                        <span className="one_app_child_content">
                          {item?.xu + " đồng."}
                        </span>
                      </div>
                      <div className="app_price">
                        <span className="one_app_child_title">Thời gian:</span>
                        <span className="one_app_child_content">
                          {item?.time_get + " giây."}
                        </span>
                      </div>
                      <div className="app_price">
                        <span className="one_app_child_title">Trạng thái:</span>
                        <span className="one_app_child_content">
                          {item.task_history_id &&
                          item.history_status === 1 &&
                          item.temp < 3 ? (
                            <span
                              style={{
                                color: "#007bff",
                                fontWeight: 700,
                              }}
                            >
                              Đang làm
                            </span>
                          ) : item.task_history_id &&
                            item.history_status === 2 ? (
                            <span
                              style={{
                                color: "#28a745",
                                fontWeight: 700,
                              }}
                            >
                              Hoàn thành
                            </span>
                          ) : item.task_history_id &&
                            (item.history_status === 3 || item.temp >= 3) ? (
                            <span
                              style={{
                                color: "#dc3545",
                                fontWeight: 700,
                              }}
                            >
                              Đã bị khóa
                            </span>
                          ) : (
                            <span
                              style={{
                                color: "#dc3545",
                                fontWeight: 700,
                              }}
                            >
                              Chưa làm
                            </span>
                          )}
                        </span>
                      </div>

                      {item.task_history_id && (
                        <Fragment>
                          <div className="app_price">
                            <span className="one_app_child_title">
                              Bắt đầu:
                            </span>
                            <span className="one_app_child_content">
                              {convertToDate(item.time_start)}
                            </span>
                          </div>
                          <div className="app_price">
                            <span className="one_app_child_title">
                              Kết thúc:
                            </span>
                            <span className="one_app_child_content">
                              {getTimeKetThuc(item.time_start)}
                            </span>
                          </div>
                        </Fragment>
                      )}

                      <div className="app_download_error">
                        Sau khi bấm bắt đầu làm, bạn sẽ có 10 phút để hoàn
                        thành. Tối đa 3 lần nhập sai.
                      </div>

                      {item.task_history_id === null && (
                        <div
                          onClick={() => {
                            handleBatDauLam(item.task_id);
                          }}
                          className="app_download"
                          style={{ cursor: "pointer" }}
                        >
                          Bắt đầu làm
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="one_app--bottom">
                    <div className="one_app--bottom-top">
                      <p
                        className="one_app--bottom_step_title"
                        style={{ color: "#17a2b8" }}
                      >
                        Các bước thực hiện:
                      </p>
                      <div
                        className="one_app--bottom_step"
                        onClick={() => {
                          handleViewDetail(item?.task_id);
                        }}
                      >
                        <img
                          className="icon_down"
                          alt="icon_down"
                          src={
                            show === item?.task_id
                              ? "/images/icons/up-arrow.png"
                              : "/images/icons/down-arrow.png"
                          }
                        ></img>
                        <p className="one_app--bottom_step_title">
                          {show === item?.task_id
                            ? "Tắt hướng dẫn"
                            : "Xem hướng dẫn"}
                        </p>
                      </div>
                    </div>
                    <div
                      className="one_app--bottom-bottom"
                      style={{
                        display: show === item?.task_id ? "block" : "none",
                      }}
                    >
                      {
                        <div
                          className="content"
                          dangerouslySetInnerHTML={{
                            __html: item?.content,
                          }}
                        ></div>
                      }

                      {item.task_history_id && (
                        <div
                          className="app_note"
                          style={{
                            display: "flex",
                            alignItems: "center",
                          }}
                        >
                          <span className="app_note--title">Lấy code:</span>
                          <div
                            className="app_get_code"
                            style={{
                              cursor: "pointer",
                            }}
                          >
                            <a
                              target="_blank"
                              rel="noreferrer"
                              href={item.short_url || item.url}
                              style={{ color: "#fff" }}
                            >
                              Lấy code
                            </a>
                          </div>
                        </div>
                      )}

                      {item.task_history_id &&
                        item.history_status === 1 &&
                        item.temp < 3 && (
                          <Fragment>
                            <div
                              className="app_note"
                              style={{
                                display: "flex",
                                alignItems: "center",
                              }}
                            >
                              <span className="app_note--title">
                                Nhập code:
                              </span>
                              <input
                                onChange={(e) => {
                                  handleChangeInputCode(
                                    e.target.value,
                                    item.task_id
                                  );
                                }}
                                className="input_username_app"
                                placeholder="Ví dụ: IBu4ua"
                                style={{
                                  marginLeft: 5,
                                }}
                              />
                            </div>

                            <div
                              className="app_check_code"
                              style={{ cursor: "pointer" }}
                              onClick={() => {
                                handleCheckCode(
                                  item.task_id,
                                  item.task_history_id
                                );
                              }}
                            >
                              <span>Kiểm tra</span>
                            </div>
                          </Fragment>
                        )}

                      <div className="app_note">
                        <p className="app_note--title">Lưu ý:</p>
                        <div className="app_note--content">
                          Sau khi bấm bắt đầu làm, bạn sẽ có 10 phút để hoàn
                          thành. Nhập sai mã code 3 lần, nhiệm vụ sẽ bị khóa.
                        </div>
                      </div>

                      <div className="app_video">
                        <p className="app_video--title">Video hướng dẫn:</p>
                        <p style={{ textAlign: "center" }}>Chưa có video...</p>
                      </div>
                    </div>
                  </div>
                </div>
                {index === taskList.length - 1 ? (
                  <div className="end_list">Đã tới cuối danh sách</div>
                ) : (
                  ""
                )}
              </Fragment>
            );
          }
        })}
      </div>
      <MultiplexAd />
      <Footer margin_top={"15px"} />
    </div>
  );
}
