import React, { Fragment, useEffect, useState } from "react";
import "../../styles/Toast.css";
import "../../styles/Custome.css";
import AccountLeft from "../../components/Layouts/UserLayout/AccountLeft";
import { ApiLink, notify } from "../../utils/Title";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import Footer from "../../components/Layouts/BasicLayout/Footer";
import AdSenseAd from "../../components/AdSenseAd/AdSenseAd";
import MultiplexAd from "../../components/AdSenseAd/MultiplexAd";

export default function UserPage({ children, active_id }) {
  let [loading, isLoading] = useState(true);
  let [username, setUsername] = useState("");
  let [role, setRole] = useState("");
  let [showSidebar, setShowSidebar] = useState(false);
  let navigate = useNavigate();
  useEffect(() => {
    async function checkPermission() {
      const response = await axios.get(`${ApiLink.domain + "/check/all"}`, {
        withCredentials: true,
      });
      if (response.data.status) {
        isLoading(false);
        setUsername(response.data.username);
        setRole(response.data.role);
      } else {
        notify(false, response.data.message);
        if (response.data.must === "login") {
          return navigate("/account/login", { replace: true });
        } else return navigate("/", { replace: true });
      }
    }
    checkPermission();
  }, [navigate, active_id]);

  const handeleSetShowSideBar = (status) => {
    setShowSidebar(status);
  };

  return (
    <Fragment>
      {loading ? (
        ""
      ) : (
        <Fragment>
          <AdSenseAd />
          <div
            onClick={() => {
              handeleSetShowSideBar(!showSidebar);
            }}
            className="icon_bars"
          >
            <i className="fas fa-bars"></i>
          </div>
          <AccountLeft
            showSidebar={showSidebar}
            active_id={active_id}
            role={role}
            handeleSetShowSideBar={handeleSetShowSideBar}
          />
          {React.cloneElement(children, {
            username,
          })}
        </Fragment>
      )}
      <MultiplexAd />
      <Footer margin_top={"-25px"} />
    </Fragment>
  );
}
