import React from "react";
export default function FormDoiThuong({ closeFormConfirm, itemChoose }) {
  const handleCloseEvent = () => {
    closeFormConfirm();
  };

  return (
    <div
      className="confirm_container remove_customer_class bg-white"
      style={{ fontSize: "15px" }}
    >
      <div
        className="confirm_header text-white bg-primary"
        style={{ padding: 10, fontWeight: 700 }}
      >
        <i className="fas fa-check-circle" style={{ color: "#47f764" }} />
        <span style={{ marginLeft: 3 }}>Thông tin đổi thưởng</span>
      </div>
      <div
        className="confirm_content"
        style={{ padding: 10, textAlign: "center" }}
      >
        <div style={{ textAlign: "left" }}>
          <label className="form-label">Tên phần thưởng:</label>
          <input
            style={{
              fontSize: "12px",
            }}
            className="form-control showordisable"
            placeholder="Tên phần thưởng"
            type="text"
            disabled
            defaultValue={itemChoose.PayDetail.name}
          ></input>
        </div>
        <div style={{ textAlign: "left", marginTop: 5 }}>
          <label className="form-label">Mệnh giá:</label>
          <input
            style={{
              fontSize: "12px",
            }}
            className="form-control showordisable"
            placeholder="Mệnh giá"
            type="number"
            disabled
            defaultValue={itemChoose.PayDetail.xu}
          ></input>
        </div>
        {itemChoose.PayDetail.pay_method_id < 4 &&
          itemChoose.pay_history_status === 2 && (
            <div style={{ textAlign: "left", marginTop: 5 }}>
              <label className="form-label">Thông tin thẻ cào:</label>
              <textarea
                style={{
                  fontSize: "12px",
                }}
                className="form-control showordisable"
                placeholder="Thông tin thẻ cào"
                type="text"
                disabled
                defaultValue={itemChoose.note}
                rows={2}
              ></textarea>
            </div>
          )}
        {itemChoose.PayDetail.pay_method_id < 4 &&
          itemChoose.pay_history_status === 1 && (
            <div style={{ textAlign: "left", marginTop: 5 }}>
              <label className="form-label">Thông tin thẻ cào:</label>
              <textarea
                style={{
                  fontSize: "12px",
                }}
                className="form-control showordisable"
                placeholder="Thông tin thẻ cào"
                type="text"
                disabled
                defaultValue="Đang chờ xét duyệt"
                rows={2}
              ></textarea>
            </div>
          )}

        {itemChoose.PayDetail.pay_method_id > 3 && (
          <div style={{ textAlign: "left", marginTop: 5 }}>
            <label className="form-label">Thông tin đổi thưởng:</label>
            <textarea
              style={{
                fontSize: "12px",
              }}
              className="form-control showordisable"
              placeholder="Thông tin đổi thưởng"
              type="text"
              disabled
              defaultValue={itemChoose.detail}
              rows={2}
            ></textarea>
          </div>
        )}

        {itemChoose.pay_history_status === 3 && (
          <div style={{ textAlign: "left", marginTop: 5 }}>
            <label className="form-label">Ghi chú:</label>
            <textarea
              style={{
                fontSize: "12px",
              }}
              className="form-control showordisable"
              placeholder="Ghi chú"
              type="text"
              disabled
              defaultValue={itemChoose.note}
              rows={1}
            ></textarea>
          </div>
        )}
      </div>
      <div
        className="confirm_buttons"
        style={{ display: "flex", justifyContent: "center" }}
      >
        <button
          onClick={() => handleCloseEvent()}
          className="btn btn-danger me-1 mb-2 btn_huy_xoa"
          style={{ margin: "0px 10px" }}
          type="button"
        >
          Đóng
        </button>
      </div>
    </div>
  );
}
