import React from "react";
import { Link } from "react-router-dom";

export default function MenuLayout() {
  return (
    <div>
      <div className="list_name">Danh mục</div>
      <div className="list_danhmuc">
        <div className="list_item">
          <Link to={"/farm"} className="list_item_name">
            <img src="/images/icons/farm.png" alt="icon" />
            <div> Nông trại</div>
          </Link>
        </div>
        <div className="list_item">
          <Link to={"/fish-gate"} className="list_item_name">
            <img src="/images/icons/cauca.png" alt="icon" />
            <div> Câu cá</div>
          </Link>
        </div>
        <div className="list_item">
          <Link to={"/account/ket-qua-xo-so"} className="list_item_name">
            <img src="/images/icons/giaitri.png" alt="icon" />
            <div> Xổ số</div>
          </Link>
        </div>
        <div className="list_item">
          <Link to={"/event"} className="list_item_name">
            <img src="/images/icons/event.png" alt="icon" />
            <div> Sự kiện</div>
          </Link>
        </div>
        <div className="list_item">
          <Link to={"/account/invite"} className="list_item_name">
            <img src="/images/icons/invite.png" alt="icon" />
            <div>Giới thiệu</div>
          </Link>
        </div>
        <div className="list_item">
          <Link to={"/task-link"} className="list_item_name">
            <img src="/images/icons/bank.png" alt="icon" />
            <div>Nhập code</div>
          </Link>
        </div>

        <div className="list_item">
          <Link to={"/task-everyday"} className="list_item_name">
            <img src="/images/icons/congvien.png" alt="icon" />
            <div>Nhiệm vụ</div>
          </Link>
        </div>

        <div className="list_item">
          <Link to={"/account/open-gift"} className="list_item_name">
            <img src="/images/icons/muasam.png" alt="icon" />
            <div>Mở quà</div>
          </Link>
        </div>
        <div className="list_item">
          <Link to={"/"} className="list_item_name">
            <img src="/images/icons/ngoaio.png" alt="icon" />
            <div>Tải App</div>
          </Link>
        </div>

        <div className="list_item">
          <Link to={"/rank"} className="list_item_name">
            <img src="/images/icons/dancu.png" alt="icon" />
            <div>Xếp hạng</div>
          </Link>
        </div>

        <div className="list_item">
          <Link to={"/chat"} className="list_item_name">
            <img src="/images/icons/sanbay.png" alt="icon" />
            <div>Chém gió</div>
          </Link>
        </div>
        <div className="list_item">
          <Link to={"/account/withdraw"} className="list_item_name">
            <img src="/images/icons/chotroi.png" alt="icon" />
            <div>Rút tiền</div>
          </Link>
        </div>
      </div>
    </div>
  );
}
