import React, { Fragment, useCallback, useEffect, useState } from "react";
import "../../styles/Farm.css";
import axios from "axios";
import { ApiLink, convertToDate, notify, Title } from "../../utils/Title";
import { Link, useNavigate } from "react-router-dom";
import UserBoardLayout from "../../components/Layouts/BasicLayout/UserBoardLayout";
import NotifyLayout from "../../components/Layouts/BasicLayout/NotifyLayout";
import FormFarmStore from "../../components/Layouts/UserLayout/Form/FormFarmStore";
import FormFarmStock from "../../components/Layouts/UserLayout/Form/FormFarmStock";
import AdSenseAd from "../../components/AdSenseAd/AdSenseAd";
import MultiplexAd from "../../components/AdSenseAd/MultiplexAd";
import FormUserAnimal from "../../components/Layouts/UserLayout/Form/FormUserAnimal";
import BangXepHangThuHoach from "../../components/Layouts/BasicLayout/BangXepHangThuHoach";
import Footer from "../../components/Layouts/BasicLayout/Footer";

// Component được memo hóa để tránh render lại không cần thiết
const isSinhTruong_TOIUU = (vat_nuoi) => {
  let current_time = Date.now();
  let time_sinhtruong = new Date(vat_nuoi.time_mua);
  time_sinhtruong = time_sinhtruong.setMinutes(
    time_sinhtruong.getMinutes() + Number(vat_nuoi.FarmAnimal.time_sinhtruong)
  );

  if (current_time > time_sinhtruong) {
    return true;
  }
  return false;
};

const isDaChoAnNo_TOIUU = (vat_nuoi) => {
  let current_time = Date.now();
  let time_choan_last = new Date(vat_nuoi.time_choan);
  time_choan_last = time_choan_last.setMinutes(
    time_choan_last.getMinutes() + Number(vat_nuoi.FarmAnimal.time_choan)
  );

  const time_chet_doi = Math.round(
    (time_choan_last - current_time) / (1000 * 60)
  );

  if (time_chet_doi < 30) {
    return false;
  }
  return true;
};

const isSanSinh = (vat_nuoi) => {
  let current_time = new Date();
  let time_san_sinh_next = new Date(vat_nuoi.time_thuhoach);

  if (current_time < time_san_sinh_next) {
    return false;
  }
  return true;
};

const generateRandomPx_TOIUU = (min, max) => {
  const randomValue = Math.floor(Math.random() * (max - min + 1)) + min;
  return randomValue + "px";
};

const TOI_UU_RENDER_GA = React.memo(
  ({ userAnimalList, setShowFormUserAnimal, setVatNuoiChoose }) => {
    let arr = [];
    let checkSanSinhGa = 0;
    userAnimalList.forEach((vat_nuoi, index) => {
      if (vat_nuoi.FarmAnimal.animal_name === "Gà") {
        if (isSanSinh(vat_nuoi)) {
          checkSanSinhGa += 1;
        }
        arr.push(
          <img
            onClick={() => {
              setShowFormUserAnimal(true);
              setVatNuoiChoose(vat_nuoi.id);
            }}
            key={index}
            className="one_con_bo"
            src={
              isSinhTruong_TOIUU(vat_nuoi)
                ? "/images/farm/vatnuoi/lon/" + vat_nuoi.animal_id + ".gif"
                : "/images/farm/vatnuoi/nho/" + vat_nuoi.animal_id + ".gif"
            }
            alt="con_vat"
            style={{
              top: generateRandomPx_TOIUU(-80, 20),
              left: generateRandomPx_TOIUU(10, 300),
            }}
          />
        );
      }
    });
    if (arr.length > 0) {
      arr.push(
        <img
          onClick={() => {
            notify(true, "Ổ gà có " + checkSanSinhGa + " quả trứng");
          }}
          className="one_con_bo"
          style={{
            left: "240px",
            top: "-55px",
          }}
          src={
            checkSanSinhGa
              ? "/images/farm/vatnuoi/trungga.png"
              : "/images/farm/vatnuoi/oga.png"
          }
          alt="con_vat"
        />
      );
    }
    return arr;
  }
);
const TOI_UU_RENDER_CA = React.memo(
  ({ userAnimalList, setShowFormUserAnimal, setVatNuoiChoose }) => {
    let arr = [];
    userAnimalList.forEach((vat_nuoi, index) => {
      if (vat_nuoi.FarmAnimal.animal_name === "Cá") {
        arr.push(
          <img
            onClick={() => {
              setShowFormUserAnimal(true);
              setVatNuoiChoose(vat_nuoi.id);
            }}
            key={index}
            className="one_con_bo"
            src={
              isSinhTruong_TOIUU(vat_nuoi)
                ? "/images/farm/vatnuoi/lon/" + vat_nuoi.animal_id + ".gif"
                : "/images/farm/vatnuoi/nho/" + vat_nuoi.animal_id + ".gif"
            }
            alt="con_vat"
            style={{
              top: generateRandomPx_TOIUU(10, 60),
              left: generateRandomPx_TOIUU(10, 60),
            }}
          />
        );
      }
    });
    return arr;
  }
);
const TOI_UU_RENDER_BOCUUHEO = React.memo(
  ({ userAnimalList, setShowFormUserAnimal, setVatNuoiChoose }) => {
    let arr = [];
    let isCoBo = false;
    let isCoCuu = false;
    let checkNo = true;
    let checkSanSinhCuu = 0;
    let checkSanSinhBo = 0;
    userAnimalList.forEach((vat_nuoi, index) => {
      if (
        vat_nuoi.FarmAnimal.animal_name === "Bò sữa" ||
        vat_nuoi.FarmAnimal.animal_name === "Cừu" ||
        vat_nuoi.FarmAnimal.animal_name === "Heo"
      ) {
        if (vat_nuoi.FarmAnimal.animal_name === "Bò sữa") {
          isCoBo = true;
          if (isSanSinh(vat_nuoi) === true) {
            checkSanSinhBo += 1;
          }
        } else if (vat_nuoi.FarmAnimal.animal_name === "Cừu") {
          isCoCuu = true;
          if (isSanSinh(vat_nuoi) === true) {
            checkSanSinhCuu += 1;
          }
        }
        if (isDaChoAnNo_TOIUU(vat_nuoi) === false) {
          checkNo = false;
        }
        arr.push(
          <img
            onClick={() => {
              setShowFormUserAnimal(true);
              setVatNuoiChoose(vat_nuoi.id);
            }}
            key={index}
            className="one_con_bo"
            src={
              isSinhTruong_TOIUU(vat_nuoi)
                ? "/images/farm/vatnuoi/lon/" + vat_nuoi.animal_id + ".gif"
                : "/images/farm/vatnuoi/nho/" + vat_nuoi.animal_id + ".gif"
            }
            alt="con_vat"
            style={{
              top: generateRandomPx_TOIUU(10, 60),
              left: generateRandomPx_TOIUU(10, 120),
            }}
          />
        );
      }
    });
    if (arr.length > 0) {
      // Máng cho ăn cả bò và cừu
      arr.push(
        <img
          key={2000}
          src={
            checkNo
              ? "/images/farm/vatnuoi/mangan.png"
              : "/images/farm/vatnuoi/mang.png"
          }
          alt="con_vat"
          style={{
            bottom: 10,
            left: 20,
            position: "absolute",
          }}
        />
      );
      // Máng thu sản lượng
      if (isCoBo && isCoCuu) {
        arr.push(
          <div
            key={2001}
            className="one_mang_an"
            style={{
              display: "flex",
              bottom: 10,
              right: 25,
            }}
          >
            <img
              onClick={() => {
                notify(true, "Hiện có " + checkSanSinhCuu + " lông cừu");
              }}
              style={{ margin: "0px 5px" }}
              src={
                checkSanSinhCuu
                  ? "/images/farm/vatnuoi/longcuu.png"
                  : "/images/farm/vatnuoi/mangcuu.png"
              }
              alt="con_vat"
            />
            <img
              onClick={() => {
                notify(true, "Hiện có " + checkSanSinhBo + " hộp sữa bò");
              }}
              src={
                checkSanSinhBo
                  ? "/images/farm/vatnuoi/suabo.png"
                  : "/images/farm/vatnuoi/xodung.png"
              }
              alt="con_vat"
            />
          </div>
        );
      } else {
        arr.push(
          <img
            key={2003}
            className="one_mang_an"
            src={
              isCoBo
                ? checkSanSinhBo
                  ? "/images/farm/vatnuoi/suabo.png"
                  : "/images/farm/vatnuoi/xodung.png"
                : checkSanSinhCuu
                ? "/images/farm/vatnuoi/longcuu.png"
                : "/images/farm/vatnuoi/mangcuu.png"
            }
            alt="con_vat"
            style={{
              bottom: 10,
              right: 25,
            }}
          />
        );
      }
    }
    return arr;
  }
);

export default function FarmPage() {
  const [loading, isLoading] = useState(true);
  const [info, setInfo] = useState(null);
  let navigate = useNavigate();
  let [username, setUsername] = useState("");
  let [showNotify, setShowNotify] = useState(false);
  const [isCheckedAll, setIsCheckedAll] = useState(false);
  const [userFarmList, setUserFarmList] = useState([]);
  const [userSeedList, setUserSeedList] = useState([]);
  const [userAnimalList, setUserAnimalList] = useState([]);
  const [bxhThuHoach, setBXHThuHoach] = useState([]);
  const [userSeedChoose, setUserSeedChoose] = useState(0);
  const [showStore, setShowStore] = useState(false);
  const [showStock, setShowStock] = useState(false);
  const [vatNuoiChoose, setVatNuoiChoose] = useState(false);
  const [showFormUserAnimal, setShowFormUserAnimal] = useState(false);
  const [thuHoachKheState, isThuHoachKhe] = useState(false);
  useEffect(() => {
    document.title = Title.mobile_app + Title.origin;
  }, []);

  const handleCheckboxAllChange = () => {
    const old_check_all = isCheckedAll;
    setIsCheckedAll(!isCheckedAll);
    const new_user_farm_list = userFarmList.map((item) => ({
      land_choose: old_check_all ? false : true,
      detail: item.detail,
    }));
    setUserFarmList(new_user_farm_list);
  };

  // const generateRandomPx = (min, max) => {
  //   const randomValue = Math.floor(Math.random() * (max - min + 1)) + min;
  //   return randomValue + "px";
  // };

  // const isSinhTruong = (vat_nuoi) => {
  //   let current_time = Date.now();
  //   let time_sinhtruong = new Date(vat_nuoi.time_mua);
  //   time_sinhtruong = time_sinhtruong.setMinutes(
  //     time_sinhtruong.getMinutes() + Number(vat_nuoi.FarmAnimal.time_sinhtruong)
  //   );

  //   if (current_time > time_sinhtruong) {
  //     return true;
  //   }
  //   return false;
  // };

  // const renderBoVaCuuHeo = () => {
  //   let arr = [];
  //   let isCoBo = false;
  //   let isCoCuu = false;
  //   userAnimalList.forEach((vat_nuoi, index) => {
  //     if (
  //       vat_nuoi.FarmAnimal.animal_name === "Bò sữa" ||
  //       vat_nuoi.FarmAnimal.animal_name === "Cừu" ||
  //       vat_nuoi.FarmAnimal.animal_name === "Heo"
  //     ) {
  //       if (vat_nuoi.FarmAnimal.animal_name === "Bò sữa") {
  //         isCoBo = true;
  //       } else {
  //         isCoCuu = true;
  //       }
  //       arr.push(
  //         <img
  //           onClick={() => {
  //             setShowFormUserAnimal(true);
  //             setVatNuoiChoose(vat_nuoi.id);
  //           }}
  //           key={index}
  //           className="one_con_bo"
  //           src={
  //             isSinhTruong(vat_nuoi)
  //               ? "/images/farm/vatnuoi/lon/" + vat_nuoi.animal_id + ".gif"
  //               : "/images/farm/vatnuoi/nho/" + vat_nuoi.animal_id + ".gif"
  //           }
  //           alt="con_vat"
  //           style={{
  //             top: generateRandomPx(10, 60),
  //             left: generateRandomPx(10, 120),
  //           }}
  //         />
  //       );
  //     }
  //   });
  //   if (arr.length > 0) {
  //     // Máng cho ăn cả bò và cừu
  //     arr.push(
  //       <img
  //         key={2000}
  //         src="/images/farm/vatnuoi/mang.png"
  //         alt="con_vat"
  //         style={{
  //           bottom: 10,
  //           left: 20,
  //           position: "absolute",
  //         }}
  //       />
  //     );
  //     // Máng thu sản lượng
  //     if (isCoBo && isCoCuu) {
  //       arr.push(
  //         <div
  //           key={2001}
  //           className="one_mang_an"
  //           style={{
  //             display: "flex",
  //             bottom: 10,
  //             right: 25,
  //           }}
  //         >
  //           <img
  //             style={{ margin: "0px 5px" }}
  //             src="/images/farm/vatnuoi/mangcuu.png"
  //             alt="con_vat"
  //           />
  //           <img src="/images/farm/vatnuoi/xodung.png" alt="con_vat" />
  //         </div>
  //       );
  //     } else {
  //       arr.push(
  //         <img
  //           key={2003}
  //           className="one_mang_an"
  //           src={
  //             isCoBo
  //               ? "/images/farm/vatnuoi/xodung.png"
  //               : "/images/farm/vatnuoi/mangcuu.png"
  //           }
  //           alt="con_vat"
  //           style={{
  //             bottom: 10,
  //             right: 25,
  //           }}
  //         />
  //       );
  //     }
  //   }
  //   return arr;
  // };

  // const renderCa = () => {
  //   let arr = [];
  //   userAnimalList.forEach((vat_nuoi, index) => {
  //     if (vat_nuoi.FarmAnimal.animal_name === "Cá") {
  //       arr.push(
  //         <img
  //           onClick={() => {
  //             setShowFormUserAnimal(true);
  //             setVatNuoiChoose(vat_nuoi.id);
  //           }}
  //           key={index}
  //           className="one_con_bo"
  //           src={
  //             isSinhTruong(vat_nuoi)
  //               ? "/images/farm/vatnuoi/lon/" + vat_nuoi.animal_id + ".gif"
  //               : "/images/farm/vatnuoi/nho/" + vat_nuoi.animal_id + ".gif"
  //           }
  //           alt="con_vat"
  //           style={{
  //             top: generateRandomPx(10, 60),
  //             left: generateRandomPx(10, 60),
  //           }}
  //         />
  //       );
  //     }
  //   });
  //   return arr;
  // };

  const renderGa = useCallback(() => {
    return (
      <TOI_UU_RENDER_GA
        userAnimalList={userAnimalList}
        setShowFormUserAnimal={setShowFormUserAnimal}
        setVatNuoiChoose={setVatNuoiChoose}
      />
    );
  }, [userAnimalList]);
  const renderCa = useCallback(() => {
    return (
      <TOI_UU_RENDER_CA
        userAnimalList={userAnimalList}
        setShowFormUserAnimal={setShowFormUserAnimal}
        setVatNuoiChoose={setVatNuoiChoose}
      />
    );
  }, [userAnimalList]);

  const renderBoVaCuuHeo = useCallback(() => {
    return (
      <TOI_UU_RENDER_BOCUUHEO
        userAnimalList={userAnimalList}
        setShowFormUserAnimal={setShowFormUserAnimal}
        setVatNuoiChoose={setVatNuoiChoose}
      />
    );
  }, [userAnimalList]);

  // const renderGa = () => {
  //   let arr = [];
  //   userAnimalList.forEach((vat_nuoi, index) => {
  //     if (vat_nuoi.FarmAnimal.animal_name === "Gà") {
  //       arr.push(
  //         <img
  //           onClick={() => {
  //             setShowFormUserAnimal(true);
  //             setVatNuoiChoose(vat_nuoi.id);
  //           }}
  //           key={index}
  //           className="one_con_bo"
  //           src={
  //             isSinhTruong(vat_nuoi)
  //               ? "/images/farm/vatnuoi/lon/" + vat_nuoi.animal_id + ".gif"
  //               : "/images/farm/vatnuoi/nho/" + vat_nuoi.animal_id + ".gif"
  //           }
  //           alt="con_vat"
  //           style={{
  //             top: generateRandomPx(-80, 20),
  //             left: generateRandomPx(10, 300),
  //           }}
  //         />
  //       );
  //     }
  //   });
  //   return arr;
  // };

  const renderImageSeed = (time_thuhoach, time_trong, image_trongcay) => {
    const thu_hoach = new Date(time_thuhoach);
    const trong_cay = new Date(time_trong);
    const current_time = new Date();

    const time_total_minute = (thu_hoach - trong_cay) / (100 * 60);
    const current_to_thu_hoach = (thu_hoach - current_time) / (100 * 60);

    if (current_to_thu_hoach <= 0) {
      return "/images/farm/cay/" + image_trongcay + "-chin.png";
    } else if ((current_to_thu_hoach / time_total_minute) * 100 <= 25) {
      return "/images/farm/cay/" + image_trongcay + "-uong.png";
    } else if ((current_to_thu_hoach / time_total_minute) * 100 <= 65) {
      return "/images/farm/cay/" + image_trongcay + ".png";
    } else if ((current_to_thu_hoach / time_total_minute) * 100 <= 100) {
      return "/images/farm/cay/gieohat.png";
    }
  };

  const handleCheckboxChange = (land_index) => {
    const new_user_farm_list = userFarmList.map((item, index) => ({
      land_choose:
        land_index === index
          ? item.land_choose
            ? false
            : true
          : item.land_choose,
      detail: item.detail,
    }));
    setUserFarmList(new_user_farm_list);
  };

  const handleGieoHat = async () => {
    if (!userSeedChoose) {
      notify(false, "Vui lòng chọn hạt giống gieo");
      return;
    }
    let land_gieo_list = [];
    userFarmList.forEach((item, index) => {
      if (item.land_choose === true) {
        land_gieo_list.push({
          land_location: index + 1,
          seed_id: Number(userSeedChoose),
        });
      }
    });
    if (land_gieo_list.length < 1) {
      notify(false, "Vui lòng chọn ô đất gieo hạt");
      return;
    }

    const response = await axios.post(
      `${ApiLink.domain + "/user-farm/farm/gieo-hat"}`,
      { list_seed: land_gieo_list },
      {
        withCredentials: true,
      }
    );
    if (response.data.status) {
      notify(true, response.data.message);
      setUserSeedList(response.data.userSeedList);
      const land_amount = info.land_amount;
      const user_farm_list = response.data.data;
      const list_farm = new Array(land_amount).fill({
        land_choose: false,
        detail: null,
      });
      user_farm_list.forEach((user_farm) => {
        if (user_farm.land_location) {
          list_farm[user_farm.land_location - 1] = {
            land_choose: false,
            detail: user_farm,
          };
        }
      });

      setIsCheckedAll(false);
      setUserFarmList(list_farm);
    } else {
      notify(false, response.data.message);
      if (response.data.must === "login") {
        return navigate("/account/login", { replace: true });
      } else if (response.data.must === "permission") {
        return;
      }
    }
  };

  const handleThuHoach = async () => {
    let land_thu_hoach_list = [];
    userFarmList.forEach((item, index) => {
      if (item.land_choose === true) {
        land_thu_hoach_list.push({
          land_location: index + 1,
          seed_id: Number(userSeedChoose),
        });
      }
    });
    if (land_thu_hoach_list.length < 1) {
      notify(false, "Vui lòng chọn ô đất thu hoạch");
      return;
    }

    const response = await axios.post(
      `${ApiLink.domain + "/user-farm/farm/thu-hoach"}`,
      { list_seed: land_thu_hoach_list },
      {
        withCredentials: true,
      }
    );
    if (response.data.status) {
      notify(true, response.data.message);
      const land_amount = info.land_amount;
      const user_farm_list = response.data.data;
      const list_farm = new Array(land_amount).fill({
        land_choose: false,
        detail: null,
      });
      user_farm_list.forEach((user_farm) => {
        if (user_farm.land_location) {
          list_farm[user_farm.land_location - 1] = {
            land_choose: false,
            detail: user_farm,
          };
        }
      });
      if (response.data.bxh.status) {
        setBXHThuHoach(response.data.bxh.data);
      }
      if (response.data.user_bxh) {
        setInfo({
          ...info,
          thu_hoach_quanty: response.data.user_bxh.thu_hoach_quanty,
          xu: response.data.user_bxh.xu,
          vnd: response.data.user_bxh.vnd,
        });
      }
      setIsCheckedAll(false);
      setUserFarmList(list_farm);
    } else {
      notify(false, response.data.message);
      if (response.data.must === "login") {
        return navigate("/account/login", { replace: true });
      } else if (response.data.must === "permission") {
        return;
      }
    }
  };

  const handleTuoiNuoc = async () => {
    let land_tuoi_nuoc_list = [];
    userFarmList.forEach((item, index) => {
      if (item.land_choose === true) {
        land_tuoi_nuoc_list.push({
          land_location: index + 1,
          seed_id: Number(userSeedChoose),
        });
      }
    });
    if (land_tuoi_nuoc_list.length < 1) {
      notify(false, "Vui lòng chọn ô đất để tưới nước");
      return;
    }

    const response = await axios.post(
      `${ApiLink.domain + "/user-farm/farm/tuoi-nuoc"}`,
      { list_seed: land_tuoi_nuoc_list },
      {
        withCredentials: true,
      }
    );
    if (response.data.status) {
      notify(true, response.data.message);
      const land_amount = info.land_amount;
      const user_farm_list = response.data.data;
      const list_farm = new Array(land_amount).fill({
        land_choose: false,
        detail: null,
      });

      user_farm_list.forEach((user_farm) => {
        if (user_farm.land_location) {
          list_farm[user_farm.land_location - 1] = {
            land_choose: false,
            detail: user_farm,
          };
        }
      });
      setIsCheckedAll(false);
      setUserFarmList(list_farm);
    } else {
      notify(false, response.data.message);
      if (response.data.must === "login") {
        return navigate("/account/login", { replace: true });
      } else if (response.data.must === "permission") {
        return;
      }
    }
  };

  const handleChoAn = async () => {
    const response = await axios.get(
      `${ApiLink.domain + "/user-animal/cho-an"}`,
      {
        withCredentials: true,
      }
    );
    if (response.data.status) {
      notify(true, response.data.message);
      setUserAnimalList(response.data.data);
    } else {
      notify(false, response.data.message);
      if (response.data.must === "login") {
        return navigate("/account/login", { replace: true });
      } else if (response.data.must === "permission") {
        return;
      }
    }
  };

  const handleThuHoachSanSinh = async () => {
    const response = await axios.post(
      `${ApiLink.domain + "/user-animal/thu-hoach-all"}`,
      {},
      {
        withCredentials: true,
      }
    );
    if (response.data.status) {
      notify(true, response.data.message);
      setUserAnimalList(response.data.data);
    } else {
      notify(false, response.data.message);
      if (response.data.must === "login") {
        return navigate("/account/login", { replace: true });
      } else if (response.data.must === "permission") {
        return;
      }
    }
  };

  const handleThuHoachKhe = async () => {
    const response = await axios.post(
      `${ApiLink.domain + "/user-farm/farm/thu-hoach-khe"}`,
      {},
      {
        withCredentials: true,
      }
    );
    if (response.data.status) {
      notify(true, response.data.message);
      const land_amount = info.land_amount;
      const user_farm_list = response.data.data;
      const list_farm = new Array(land_amount).fill({
        land_choose: false,
        detail: null,
      });

      user_farm_list.forEach((user_farm) => {
        if (user_farm.land_location) {
          list_farm[user_farm.land_location - 1] = {
            land_choose: false,
            detail: user_farm,
          };
        }
      });
      setIsCheckedAll(false);
      isThuHoachKhe(false);
      setUserFarmList(list_farm);
    } else {
      notify(false, response.data.message);
      if (response.data.must === "login") {
        return navigate("/account/login", { replace: true });
      } else if (response.data.must === "permission") {
        return;
      }
    }
  };

  const handlePhaBo = async () => {
    let land_pha_bo_list = [];
    userFarmList.forEach((item, index) => {
      if (item.land_choose === true) {
        land_pha_bo_list.push({
          land_location: index + 1,
          seed_id: Number(userSeedChoose),
        });
      }
    });
    if (land_pha_bo_list.length < 1) {
      notify(false, "Vui lòng chọn ô đất gieo hạt");
      return;
    }

    const response = await axios.post(
      `${ApiLink.domain + "/user-farm/farm/pha-bo"}`,
      { list_seed: land_pha_bo_list },
      {
        withCredentials: true,
      }
    );
    if (response.data.status) {
      notify(true, response.data.message);
      const land_amount = info.land_amount;
      const user_farm_list = response.data.data;
      const list_farm = new Array(land_amount).fill({
        land_choose: false,
        detail: null,
      });
      user_farm_list.forEach((user_farm) => {
        if (user_farm.land_location) {
          list_farm[user_farm.land_location - 1] = {
            land_choose: false,
            detail: user_farm,
          };
        }
      });
      setIsCheckedAll(false);
      setUserFarmList(list_farm);
    } else {
      notify(false, response.data.message);
      if (response.data.must === "login") {
        return navigate("/account/login", { replace: true });
      } else if (response.data.must === "permission") {
        return;
      }
    }
  };

  const handleSetShowNotify = () => {
    setShowNotify(!showNotify);
  };

  useEffect(() => {
    async function getUserInfo() {
      const response = await axios.get(`${ApiLink.domain + "/account/info"}`, {
        withCredentials: true,
      });
      if (response.data.status) {
        setUsername(response.data.data.username); // status, data
        setInfo(response.data.data);
        const land_amount = Number(response.data.data.land_amount);
        return land_amount;
      } else {
        // notify(false, response.data.message);
        if (response.data.must === "login") {
          window.localStorage.clear();
          return navigate("/account/login", { replace: true });
        }
        return false;
      }
    }
    async function checkThuHoachKhe() {
      const response = await axios.get(
        `${ApiLink.domain + "/user-farm/farm/check-cay-khe"}`,
        {
          withCredentials: true,
        }
      );
      isThuHoachKhe(response.data.status);
    }

    async function getUserFarm() {
      const response = await axios.get(`${ApiLink.domain + "/user-farm"}`, {
        withCredentials: true,
      });
      if (response.data.status) {
        return response.data.data;
      } else {
        // notify(false, response.data.message);
        if (response.data.must === "login") {
          window.localStorage.clear();
          return navigate("/account/login", { replace: true });
        }
        return false;
      }
    }

    async function getUserAnimal() {
      const response = await axios.get(`${ApiLink.domain + "/user-animal"}`, {
        withCredentials: true,
      });
      if (response.data.status) {
        setUserAnimalList(response.data.data);
      } else {
        notify(false, response.data.message);
        if (response.data.must === "login") {
          window.localStorage.clear();
          return navigate("/account/login", { replace: true });
        }
        return false;
      }
    }
    async function getBXHThuHoach() {
      const response = await axios.get(
        `${ApiLink.domain + "/event/ket-qua/bxh-thu-hoach"}`,
        {
          withCredentials: true,
        }
      );
      if (response.data.status) {
        setBXHThuHoach(response.data.data);
      } else {
        // notify(false, response.data.message);
        if (response.data.must === "login") {
          window.localStorage.clear();
          return navigate("/account/login", { replace: true });
        }
        return false;
      }
    }

    async function getUserSeedList() {
      const response = await axios.get(`${ApiLink.domain + "/user-seed"}`, {
        withCredentials: true,
      });
      if (response.data.status) {
        setUserSeedList(response.data.data);
        return response.data.data;
      } else {
        // notify(false, response.data.message);
        if (response.data.must === "login") {
          window.localStorage.clear();
          return navigate("/account/login", { replace: true });
        }
        return false;
      }
    }

    const getAll = async () => {
      const result = await Promise.all([
        Promise.resolve(getUserInfo()),
        Promise.resolve(getUserFarm()),
        Promise.resolve(getUserSeedList()),
        Promise.resolve(getBXHThuHoach()),
        Promise.resolve(checkThuHoachKhe()),
        Promise.resolve(getUserAnimal()),
      ]);
      if (result[0] && result[1]) {
        const land_amount = result[0];
        const user_farm_list = result[1];

        const list_farm = new Array(land_amount).fill({
          land_choose: false,
          detail: null,
        });
        user_farm_list.forEach((user_farm) => {
          if (user_farm.land_location) {
            list_farm[user_farm.land_location - 1] = {
              land_choose: false,
              detail: user_farm,
            };
          }
        });
        setUserFarmList(list_farm);
      }
      isLoading(false);
    };
    getAll();
  }, [navigate]);

  return loading ? (
    <Fragment></Fragment>
  ) : (
    <Fragment>
      <AdSenseAd />
      {showStore || showStock || showFormUserAnimal ? (
        <div
          className="background_black"
          id="background_black"
          style={{ display: "block" }}
        >
          {showStore ? (
            <FormFarmStore
              closeFormAdd={() => setShowStore(false)}
              loadData={(data, type) => {
                if (type === "SEED") {
                  setUserSeedList(data.data);
                  setInfo({
                    ...info,
                    vnd: data.vnd,
                  });
                  setUserSeedChoose(0);
                } else if (type === "ANIMAL") {
                  setUserAnimalList(data.data);
                  setInfo({
                    ...info,
                    vnd: data.vnd,
                  });
                } else {
                  setInfo({
                    ...info,
                    vnd: data.vnd,
                  });
                }
              }}
            />
          ) : (
            ""
          )}
          {showFormUserAnimal ? (
            <FormUserAnimal
              closeFormAdd={() => setShowFormUserAnimal(false)}
              vatNuoiChoose={vatNuoiChoose}
              loadData={(data) => {
                setUserAnimalList(data.data);
                setInfo({
                  ...info,
                  vnd: data.vnd,
                });
              }}
            />
          ) : (
            ""
          )}
          {showStock ? (
            <FormFarmStock
              closeFormAdd={() => setShowStock(false)}
              loadData={(data) => {
                setInfo({
                  ...info,
                  vnd: data.data,
                });
              }}
            />
          ) : (
            ""
          )}
        </div>
      ) : (
        ""
      )}
      <div className="farm_container">
        <UserBoardLayout
          Element={null}
          info={info}
          setInfo={setInfo}
          username={username}
          setUsername={setUsername}
          handleShowNotify={handleSetShowNotify}
          showNotify={showNotify}
        />
        {showNotify && <NotifyLayout />}
        <div className="farm_content">
          <div className="farm_name">Nông trại GoFarm</div>
          <div className="dat">
            <div className="list_shop">
              <div className="list_shop_item list_shop-nongtrai">
                <img src="/images/farm/farm.png" alt="farm" />
              </div>
              <div className="list_shop_item list_shop-cuahang">
                <img
                  src="/images/farm/cuahangfarm.png"
                  alt="cuahangfarm"
                  onClick={() => {
                    setShowStore(true);
                  }}
                />
              </div>
              <div
                className="list_shop_item list_shop-nhakho"
                onClick={() => {
                  setShowStock(true);
                }}
              >
                <img src="/images/farm/nhakho.png" alt="nhakho" />
              </div>
              <div className="list_shop_item list_shop-nhabep">
                <img
                  src="/images/farm/nhabep.png"
                  alt="nhabep"
                  onClick={() => {
                    notify(false, "Nhà bếp chưa mở :D");
                  }}
                />
              </div>
              <div
                className="list_shop_item list_shop-caykhe"
                onClick={() => {
                  handleThuHoachKhe();
                }}
              >
                {thuHoachKheState ? (
                  <img src="/images/farm/caykhechin.png" alt="caykhe" />
                ) : (
                  <img src="/images/farm/caykhe.png" alt="caykhe" />
                )}
              </div>
            </div>

            <div className="cola">
              <div className="list_batdongsan">
                {userFarmList.map((farm, index) => (
                  <div className="one_o_dat" key={index}>
                    {farm.detail ? (
                      <img
                        className="ok_click"
                        src={renderImageSeed(
                          farm.detail.time_thuhoach,
                          farm.detail.time_trong,
                          farm.detail.FarmSeed.image_trongcay
                        )}
                        alt="o-dat"
                        onClick={() => {
                          notify(
                            true,
                            "Thời gian thu hoạch " +
                              farm.detail.FarmSeed.seed_name +
                              ": " +
                              convertToDate(farm.detail.time_thuhoach)
                          );
                        }}
                      />
                    ) : (
                      <img src="/images/farm/o-dat.png" alt="o-dat" />
                    )}
                    <input
                      type="checkbox"
                      checked={farm.land_choose}
                      onChange={() => {
                        handleCheckboxChange(index);
                      }}
                    ></input>
                  </div>
                ))}
                <div className="mua_dat">
                  <img
                    onClick={() => {
                      notify(
                        false,
                        "Tính năng mua thêm ô đất đang bảo trì nhằm đảm bảo công bằng cho giải đua Top thu hoạch"
                      );
                    }}
                    src="/images/farm/muadat.png"
                    alt="muadat"
                  />
                </div>
              </div>
            </div>
            <div className="conduong">{renderGa()}</div>

            <div className="cola">
              <div className="list_chuongtrai">
                <div className="chuongbo">
                  <img src="/images/farm/chuongbo.png" alt="chuongbo" />
                  {renderBoVaCuuHeo()}
                </div>
                <div className="laibuon">
                  <img
                    onClick={() => {
                      notify(true, "Bác Lái Buôn: Làm nhiệm vụ hàng ngày để nhanh rút tiền nào!")
                      return navigate("/task-everyday", { replace: true });
                    }}
                    src="/images/farm/laibuon.gif"
                    alt="laibuon"
                  />
                </div>
                <div className="hoca">
                  {renderCa()}
                  <img src="/images/farm/hoca.png" alt="hoca" />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="list_name_no_border">
          Gieo hạt - Tưới nước - Thu hoạch
        </div>
        <div className="farm_action">
          <div className="seed_choose">
            <div className="seed_check_all">
              <input
                type="checkbox"
                checked={isCheckedAll}
                onChange={handleCheckboxAllChange}
              ></input>
              <label className="form-label">Chọn tất cả</label>
            </div>
            <div>
              <select
                className="form-select"
                name="seed_id"
                value={userSeedChoose}
                onChange={(e) => setUserSeedChoose(e.target.value)}
              >
                <option value={0}>Chọn hạt giống</option>
                {userSeedList.map((user_seed, index) => {
                  return (
                    <option key={index} value={user_seed.FarmSeed.seed_id}>
                      {user_seed.FarmSeed.seed_name +
                        "  [" +
                        user_seed.quanty +
                        "]"}
                    </option>
                  );
                })}
              </select>
            </div>
          </div>
          <div className="farm_action_btn_list">
            <div
              className="farm_action_btn"
              onClick={() => {
                handleGieoHat();
              }}
            >
              Gieo hạt
            </div>

            <div
              className="farm_action_btn"
              onClick={() => {
                handleTuoiNuoc();
              }}
            >
              Tưới nước
            </div>
            <div
              className="farm_action_btn"
              onClick={() => {
                handleThuHoach();
              }}
            >
              Thu hoạch
            </div>

            <div
              className="farm_action_btn"
              onClick={() => {
                handlePhaBo();
              }}
            >
              Phá bỏ
            </div>
          </div>
          <div
            style={{
              margin: "5px 0px",
            }}
            className="farm_action_btn_list"
          >
            <div
              className="farm_action_btn"
              onClick={() => {
                handleChoAn();
              }}
            >
              Cho vật nuôi ăn
            </div>

            <div
              className="farm_action_btn"
              onClick={() => {
                handleThuHoachSanSinh();
              }}
            >
              Thu trứng, sữa bò, cừu
            </div>
          </div>
        </div>
        <div className="bxh">
          <div
            className="list_name_no_border"
            style={{
              marginTop: 10,
              borderRadius: 5,
            }}
          >
            Bảng xếp hạng thu hoạch
          </div>
          <BangXepHangThuHoach bxhThuHoach={bxhThuHoach} info={info} />

          <div
            style={{
              textAlign: "center",
              marginTop: 10,
            }}
          >
            <Link
              style={{
                color: "#fff",
              }}
              className="farm_action_btn"
              to={"/chat"}
            >
              Khu vực chém gió :D
            </Link>
          </div>
        </div>
        <Footer margin_top={"15px"} />
      </div>
      <MultiplexAd />
    </Fragment>
  );
}
