import React, { useEffect, useState } from "react";
import axios from "axios";
import ReactPaginate from "react-paginate";
import SearchTheme from "./SearchTheme";
import { useNavigate } from "react-router-dom";
import { ApiLink, convertToDateOnly, notify } from "../../../utils/Title";
import AdminLogo from "./AdminLogo";
import FormEditKQXS from "./Form/FormEditKQXS";
import FormAddKQXS from "./Form/FormAddKQXS";

export default function KetQuaXoSoAdmin({ username }) {
  let [ketQuaXoSoList, setKetQuaXoSoList] = useState([]);
  let [page, setPage] = useState(1);
  const maxShow = 10;
  let navigate = useNavigate();
  let [itemEdit, setItemEdit] = useState(false);
  let [itemAdd, setItemAdd] = useState(false);
  let [keyword, setKeyword] = useState("");

  const handleShowFormEdit = (item) => {
    setItemEdit(item);
  };

  const handleShowFormAdd = () => {
    setItemAdd(true);
  };

  const handleCloseFormEdit = async (edit_submit, result) => {
    if (result & !edit_submit) {
      await reloadData();
    }
    setItemEdit(false);
  };

  useEffect(() => {
    async function getAll() {
      const response = await axios.get(
        `${ApiLink.domain + "/admin/ket-qua-xo-so"}`,
        {
          withCredentials: true,
        }
      );
      if (response.data.status) {
        setKetQuaXoSoList(response.data.data); // status, data
      } else {
        notify(false, response.data.message);
        if (response.data.must === "login") {
          window.localStorage.clear();
          return navigate("/", { replace: true });
        } else if (response.data.must === "permission") {
          return navigate("/", { replace: true });
        }
      }
    }
    getAll();
  }, [navigate]);

  const runTraThuong = async (id) => {
    const response = await axios.put(
      `${ApiLink.domain + "/admin/ket-qua-xo-so/tra-thuong-vip/" + id}`,
      {},
      {
        withCredentials: true,
      }
    );
    if (response.data.status) {
      notify(true, response.data.message);
      setKetQuaXoSoList(response.data.data); // status, data
    } else {
      notify(false, response.data.message);
      if (response.data.must === "login") {
        window.localStorage.clear();
        return navigate("/", { replace: true });
      } else if (response.data.must === "permission") {
        return navigate("/", { replace: true });
      } else {
        if (response.data.key) {
          setKetQuaXoSoList(response.data.data); // status, data
        }
      }
    }
  };

  const handlePageClick = (event) => {
    if (page !== event.selected + 1) setPage(event.selected + 1);
  };

  const loadData = (data) => {
    setKetQuaXoSoList(data);
  };

  const reloadData = async () => {
    const response = await axios.get(`${ApiLink.domain + "/admin/shop-item"}`, {
      withCredentials: true,
    });
    if (response.data.status) {
      setKetQuaXoSoList(response.data.data);
    } else {
      notify(false, response.data.message);
      if (response.data.must === "login") {
        window.localStorage.clear();
        return navigate("/", { replace: true });
      } else if (response.data.must === "permission") {
        return navigate("/", { replace: true });
      }
    }
  };

  const handleCloseFormAdd = async (add_edit, result) => {
    if (result & !add_edit) {
      // reload_data
      await reloadData();
    }
    setItemAdd(false);
  };

  const handleSearch = (keyword) => {
    setKeyword(keyword.toLowerCase().trim());
  };

  const filterSearch = () => {
    return ketQuaXoSoList.filter((item) => {
      return (
        item.ket_qua_id.toLowerCase().includes(keyword) ||
        item.note.toLowerCase().includes(keyword) ||
        Number(item.number_trung_thuong) === Number(keyword) ||
        String(item.is_tra_thuong) === keyword
      );
    });
  };

  return (
    <div id="main">
      {" "}
      <AdminLogo />
      {itemAdd || itemEdit ? (
        <div
          className="background_black"
          id="background_black"
          style={{ display: "block" }}
        >
          {itemEdit ? (
            <FormEditKQXS
              itemChoose={itemEdit}
              closeForm={handleCloseFormEdit}
              loadData={loadData}
            />
          ) : (
            ""
          )}

          {itemAdd ? (
            <FormAddKQXS closeForm={handleCloseFormAdd} loadData={loadData} />
          ) : (
            ""
          )}
        </div>
      ) : (
        ""
      )}
      <div className="page-content">
        <section
          className="row"
          style={{
            marginLeft: "-5px",
            marginRight: "-5px",
          }}
        >
          <div className="col-12 col-lg-12">
            {/* <AdminThemeTop username={username} /> */}
            <SearchTheme
              title={"Tìm kiếm kết quả"}
              handleSearch={handleSearch}
            />
            <div className="row">
              <div className="col-12">
                <div className="col-lg-12 stretch-card" style={{ padding: 0 }}>
                  <div className="card">
                    <div className="card-body">
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "space-between",
                        }}
                      >
                        <h4
                          className="card-title"
                          style={{ marginBottom: "0 !important" }}
                        >
                          Danh sách kết quả
                        </h4>

                        <div style={{ display: "flex" }}>
                          <button
                            id="info"
                            className="btn btn-success btn_add"
                            onClick={() => handleShowFormAdd()}
                          >
                            <i className="fas fa-plus" />{" "}
                            <span>Thêm kết quả</span>
                          </button>
                        </div>
                      </div>
                      <div className="table-responsive pt-3">
                        <table className="table table-bordered text-center">
                          <thead>
                            <tr>
                              <th style={{ whiteSpace: "nowrap" }}>ID</th>
                              <th style={{ whiteSpace: "nowrap" }}>
                                Số trúng thưởng
                              </th>
                              <th style={{ whiteSpace: "nowrap" }}>
                                Kết quả ngày
                              </th>
                              <th style={{ whiteSpace: "nowrap" }}>Ghi chú</th>

                              <th style={{ whiteSpace: "nowrap" }}>
                                Trả thưởng
                              </th>
                              <th style={{ whiteSpace: "nowrap" }}>Cập nhật</th>
                            </tr>
                          </thead>
                          <tbody>
                            {filterSearch().map((item, index) => {
                              if (
                                index >= (page - 1) * maxShow &&
                                index < page * maxShow
                              ) {
                                return (
                                  <tr key={index} className="table-white">
                                    <td>{item.ket_qua_id}</td>
                                    <td>{item.number_trung_thuong}</td>
                                    <td>
                                      {convertToDateOnly(item.ket_qua_date)}
                                    </td>
                                    <td>{item.note}</td>
                                    {item.is_tra_thuong ? (
                                      <td
                                        style={{
                                          color: "#28a745",
                                          fontWeight: 700,
                                          whiteSpace: "nowrap",
                                        }}
                                      >
                                        Đã trả thưởng
                                      </td>
                                    ) : (
                                      <td
                                        style={{
                                          color: "#dc3545",
                                          fontWeight: 700,
                                          whiteSpace: "nowrap",
                                        }}
                                      >
                                        Chưa trả thưởng
                                      </td>
                                    )}
                                    <td style={{ whiteSpace: "nowrap" }}>
                                      <button
                                        onClick={() => handleShowFormEdit(item)}
                                        type="button"
                                        className="btn btn-warning m-1 btn_edit"
                                      >
                                        <i className="far fa-edit" />
                                      </button>

                                      <button
                                        style={{ whiteSpace: "nowrap" }}
                                        onClick={() =>
                                          runTraThuong(item.ket_qua_id)
                                        }
                                        type="button"
                                        className="btn btn-success m-1 btn_edit"
                                      >
                                        Trả thưởng
                                      </button>
                                    </td>
                                  </tr>
                                );
                              } else return "";
                            })}
                          </tbody>
                        </table>
                      </div>
                      <div className="flex_center pagination_mg">
                        <div>Tổng số: {filterSearch().length}</div>
                        <ReactPaginate
                          breakLabel="..."
                          nextLabel="Next"
                          onPageChange={handlePageClick}
                          pageRangeDisplayed={2}
                          pageCount={
                            filterSearch().length <= maxShow
                              ? 1
                              : filterSearch().length % maxShow === 0
                              ? Math.floor(filterSearch().length / maxShow)
                              : Math.floor(filterSearch().length / maxShow) + 1
                          }
                          previousLabel="Previous"
                          renderOnZeroPageCount={null}
                          pageClassName="page-item"
                          pageLinkClassName="page-link"
                          previousClassName="page-item"
                          previousLinkClassName="page-link"
                          nextClassName="page-item"
                          nextLinkClassName="page-link"
                          breakClassName="page-item"
                          breakLinkClassName="page-link"
                          containerClassName="pagination"
                          activeClassName="active"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  );
}
