import React, { useEffect, useState } from "react";
import axios from "axios";
import ReactPaginate from "react-paginate";
import SearchTheme from "./SearchTheme";
import { useNavigate } from "react-router-dom";
import { ApiLink, convertToDate, notify } from "../../../utils/Title";
import AdminLogo from "./AdminLogo";

export default function UserAnimalAdmin({ username }) {
  let [itemList, setItemList] = useState([]);
  let [page, setPage] = useState(1);
  const maxShow = 10;
  let navigate = useNavigate();
  let [keyword, setKeyword] = useState("");

  useEffect(() => {
    async function getAll() {
      const response = await axios.get(
        `${ApiLink.domain + "/admin/user-animal"}`,
        {
          withCredentials: true,
        }
      );
      if (response.data.status) {
        setItemList(response.data.data); // status, data
      } else {
        notify(false, response.data.message);
        if (response.data.must === "login") {
          window.localStorage.clear();
          return navigate("/", { replace: true });
        } else if (response.data.must === "permission") {
          return navigate("/", { replace: true });
        }
      }
    }
    getAll();
  }, [navigate]);

  const handlePageClick = (event) => {
    if (page !== event.selected + 1) setPage(event.selected + 1);
  };

  const handleSearch = (keyword) => {
    setKeyword(keyword.toLowerCase().trim());
  };

  const filterSearch = () => {
    return itemList.filter((item) => {
      return (
        item.FarmAnimal.animal_name.toLowerCase().includes(keyword) ||
        item.User.username.toLowerCase().includes(keyword) ||
        item.note.toLowerCase().includes(keyword) ||
        Number(item.xu) === Number(keyword) ||
        Number(item.time_mua) === Number(keyword) ||
        Number(item.time_ban) === Number(keyword) ||
        Number(item.time_choan) === Number(keyword) ||
        Number(item.time_thuhoach) === Number(keyword) ||
        Number(item.time_song) === Number(keyword) ||
        Number(item.sell) === Number(keyword) ||
        Number(item.status) === Number(keyword) ||
        Number(item.animal_id) === Number(keyword)
      );
    });
  };

  return (
    <div id="main">
      <AdminLogo />
      <div className="page-content">
        <section
          className="row"
          style={{
            marginLeft: "-5px",
            marginRight: "-5px",
          }}
        >
          <div className="col-12 col-lg-12">
            {/* <AdminThemeTop username={username} /> */}
            <SearchTheme
              title={"Tìm kiếm vật nuôi"}
              handleSearch={handleSearch}
            />
            <div className="row">
              <div className="col-12">
                <div className="col-lg-12 stretch-card" style={{ padding: 0 }}>
                  <div className="card">
                    <div className="card-body">
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "space-between",
                        }}
                      >
                        <h4
                          className="card-title"
                          style={{ marginBottom: "0 !important" }}
                        >
                          Danh sách vật nuôi
                        </h4>
                      </div>
                      <div className="table-responsive pt-3">
                        <table className="table table-bordered text-center">
                          <thead>
                            <tr>
                              <th style={{ whiteSpace: "nowrap" }}>ID</th>
                              <th style={{ whiteSpace: "nowrap" }}>Username</th>
                              <th style={{ whiteSpace: "nowrap" }}>Hình ảnh</th>
                              <th style={{ whiteSpace: "nowrap" }}>
                                Tên vật nuôi
                              </th>

                              <th style={{ whiteSpace: "nowrap" }}>Time mua</th>
                              <th style={{ whiteSpace: "nowrap" }}>Time bán</th>
                              <th style={{ whiteSpace: "nowrap" }}>
                                Time cho ăn
                              </th>
                              <th style={{ whiteSpace: "nowrap" }}>
                                Time thu hoạch
                              </th>
                              <th style={{ whiteSpace: "nowrap" }}>
                                Time sống
                              </th>
                              <th style={{ whiteSpace: "nowrap" }}>Giá bán</th>
                              <th style={{ whiteSpace: "nowrap" }}>Ghi chú</th>
                              <th style={{ whiteSpace: "nowrap" }}>
                                Trạng thái
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            {filterSearch().map((item, index) => {
                              if (
                                index >= (page - 1) * maxShow &&
                                index < page * maxShow
                              ) {
                                return (
                                  <tr key={index} className="table-white">
                                    <td>{item.id}</td>
                                    <td>{item.User.username}</td>
                                    <td>
                                      <img
                                        src={
                                          "/images/" + item.FarmAnimal.avatar
                                        }
                                        alt="item"
                                      />
                                    </td>
                                    <td style={{ whiteSpace: "nowrap" }}>
                                      {item.FarmAnimal.animal_name}
                                    </td>

                                    <td style={{ whiteSpace: "nowrap" }}>
                                      {convertToDate(item.time_mua)}
                                    </td>
                                    <td style={{ whiteSpace: "nowrap" }}>
                                      {item.status === 2
                                        ? convertToDate(item.time_ban)
                                        : " - "}
                                    </td>
                                    <td style={{ whiteSpace: "nowrap" }}>
                                      {convertToDate(item.time_choan)}
                                    </td>
                                    <td style={{ whiteSpace: "nowrap" }}>
                                      {convertToDate(item.time_thuhoach)}
                                    </td>
                                    <td style={{ whiteSpace: "nowrap" }}>
                                      {convertToDate(item.time_song)}
                                    </td>
                                    <td>
                                      {item.status === 2
                                        ? Number(item.sell).toLocaleString("vi")
                                        : " - "}
                                    </td>
                                    <td>{item.note}</td>
                                    {item.status === 1 ? (
                                      <td
                                        style={{
                                          color: "#28a745",
                                          fontWeight: 700,
                                          whiteSpace: "nowrap",
                                        }}
                                      >
                                        Đang nuôi
                                      </td>
                                    ) : item.status === 2 ? (
                                      <td
                                        style={{
                                          color: "#007bff",
                                          fontWeight: 700,
                                          whiteSpace: "nowrap",
                                        }}
                                      >
                                        Đã bán
                                      </td>
                                    ) : (
                                      <td
                                        style={{
                                          color: "#dc3545",
                                          fontWeight: 700,
                                          whiteSpace: "nowrap",
                                        }}
                                      >
                                        Đã out
                                      </td>
                                    )}
                                  </tr>
                                );
                              } else return "";
                            })}
                          </tbody>
                        </table>
                      </div>
                      <div className="flex_center pagination_mg">
                        <div>Tổng số: {filterSearch().length}</div>
                        <ReactPaginate
                          breakLabel="..."
                          nextLabel="Next"
                          onPageChange={handlePageClick}
                          pageRangeDisplayed={2}
                          pageCount={
                            filterSearch().length <= maxShow
                              ? 1
                              : filterSearch().length % maxShow === 0
                              ? Math.floor(filterSearch().length / maxShow)
                              : Math.floor(filterSearch().length / maxShow) + 1
                          }
                          previousLabel="Previous"
                          renderOnZeroPageCount={null}
                          pageClassName="page-item"
                          pageLinkClassName="page-link"
                          previousClassName="page-item"
                          previousLinkClassName="page-link"
                          nextClassName="page-item"
                          nextLinkClassName="page-link"
                          breakClassName="page-item"
                          breakLinkClassName="page-link"
                          containerClassName="pagination"
                          activeClassName="active"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  );
}
