import React, { Fragment, useEffect, useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import ReactPaginate from "react-paginate";
import {
  ApiLink,
  convertToDate,
  convertToDateOnly,
  MIN_XOSO_NUMBER,
  MIN_XOSO_TEXT,
  notify,
  RATIO_XOSO_MEAN,
  RATIO_XOSO_TEXT,
  Title,
} from "../../../utils/Title";
import UserLogo from "./UserLogo";
export default function XoSo({ username }) {
  let [serverDuDoanList, setServerDuDoanList] = useState([]);
  let [userDuDoanList, setUserDuDoanList] = useState([]);
  let [ketQuaXoSoList, setKetQuaXoSoList] = useState([]);
  let [userInfo, setUserInfo] = useState(null);
  let [loading, isLoading] = useState(true);
  let [pageServer, setPageServer] = useState(1);
  let [pageUser, setPageUser] = useState(1);
  let [pageKetQua, setPageKetQua] = useState(1);
  let [thongTinDuDoan, setThongTinDuDoan] = useState({
    soTienDuDoan: "",
    conSoDuDoan: "",
  });
  const maxShow = 10;
  const maxShowUser = 6;
  const maxShowKetQua = 3;

  const getDayNow = () => {
    const ket_qua_date = new Date();
    const day = String(ket_qua_date.getDate()).padStart(2, "0");
    const month = String(ket_qua_date.getMonth() + 1).padStart(2, "0");
    const year = ket_qua_date.getFullYear();
    return day + "/" + month + "/" + year;
  };

  const handlePageServerClick = (event) => {
    if (pageServer !== event.selected + 1) setPageServer(event.selected + 1);
  };

  const handlePageUserClick = (event) => {
    if (pageUser !== event.selected + 1) setPageUser(event.selected + 1);
  };

  const handlePageKetQuaClick = (event) => {
    if (pageKetQua !== event.selected + 1) setPageKetQua(event.selected + 1);
  };

  let navigate = useNavigate();

  useEffect(() => {
    document.title = Title.user_exchange + Title.origin;
  }, []);

  useEffect(() => {
    const getUserInfo = async () => {
      const response = await axios.get(`${ApiLink.domain + "/account/info"}`, {
        withCredentials: true,
      });
      if (response.data.status) {
        setUserInfo(response.data.data);
      } else {
        notify(false, response.data.message);
        if (response.data.must === "login") {
          return navigate("/account/login", { replace: true });
        } else {
          return navigate("/", { replace: true });
        }
      }
    };

    const getAllDuDoanUser = async () => {
      const response = await axios.get(
        `${ApiLink.domain + "/du-doan-xo-so/by-user"}`,
        {
          withCredentials: true,
        }
      );
      if (response.data.status) {
        setUserDuDoanList(response.data.data);
      } else {
        notify(false, response.data.message);
        if (response.data.must === "login") {
          return navigate("/account/login", { replace: true });
        } else {
          return navigate("/", { replace: true });
        }
      }
    };

    const getAllKetQuaXoSo = async () => {
      const response = await axios.get(`${ApiLink.domain + "/ket-qua-xo-so"}`, {
        withCredentials: true,
      });
      if (response.data.status) {
        setKetQuaXoSoList(response.data.data);
      } else {
        notify(false, response.data.message);
        if (response.data.must === "login") {
          return navigate("/account/login", { replace: true });
        } else {
          return navigate("/", { replace: true });
        }
      }
    };

    const getAllDuDoanServer = async () => {
      const response = await axios.get(`${ApiLink.domain + "/du-doan-xo-so"}`, {
        withCredentials: true,
      });
      if (response.data.status) {
        setServerDuDoanList(response.data.data);
      } else {
        notify(false, response.data.message);
        if (response.data.must === "login") {
          return navigate("/account/login", { replace: true });
        } else {
          return navigate("/", { replace: true });
        }
      }
    };

    const handleAPIAll = async () => {
      await Promise.all([
        Promise.resolve(getUserInfo()),
        Promise.resolve(getAllDuDoanUser()),
        Promise.resolve(getAllDuDoanServer()),
        Promise.resolve(getAllKetQuaXoSo()),
      ]);
      isLoading(false);
    };
    handleAPIAll();
  }, [navigate]);

  const handeleDuDoanXoSo = async () => {
    if (
      !Number.isInteger(thongTinDuDoan.conSoDuDoan) ||
      (Number.isInteger(thongTinDuDoan.conSoDuDoan) &&
        (thongTinDuDoan.conSoDuDoan > 99 || thongTinDuDoan.conSoDuDoan < 0))
    ) {
      notify(false, "Con số dự đoán không hợp lệ. Phải từ 0 - 99");
      return;
    }
    if (
      !Number.isInteger(thongTinDuDoan.soTienDuDoan) ||
      (Number.isInteger(thongTinDuDoan.soTienDuDoan) &&
        thongTinDuDoan.soTienDuDoan < MIN_XOSO_NUMBER)
    ) {
      notify(false, "Số tiền dự đoán không hợp lệ. Tối thiểu 20đ");
      return;
    } else if (Number(thongTinDuDoan.soTienDuDoan) % 10 !== 0) {
      notify(false, "Số tiền dự đoán phải chia hết cho 10đ");
      return;
    }

    const response = await axios.post(
      `${ApiLink.domain + "/du-doan-xo-so/du-doan"}`,
      {
        xu_du_doan: Number(thongTinDuDoan.soTienDuDoan),
        number_du_doan: Number(thongTinDuDoan.conSoDuDoan),
      },
      {
        withCredentials: true,
      }
    );
    notify(response.data.status, response.data.message);
    if (response.data.status) {
      setThongTinDuDoan({
        conSoDuDoan: "",
        soTienDuDoan: "",
      });
      setUserInfo({
        ...userInfo,
        xu: response.data.xu,
      });
      setServerDuDoanList(response.data.data_all);
      setUserDuDoanList(response.data.data_user);
    }
  };

  return loading ? (
    ""
  ) : (
    <Fragment>
      <div id="main">
        <UserLogo />
        <div className="page-content">
          <section
            className="row"
            style={{
              marginLeft: "-5px",
              marginRight: "-5px",
            }}
          >
            <div className="col-12 col-lg-12">
              <div className="row">
                <div className="col-12">
                  <div
                    className="col-lg-12 stretch-card"
                    style={{ padding: 0 }}
                  >
                    <div className="card">
                      <div className="card-body">
                        <div className="ref_detail">
                          <div className="ref_friend">
                            <span>
                              - Game dự đoán:{" "}
                              <span
                                style={{
                                  color: "#28a745",
                                }}
                              >
                                Kết quả xổ số Miền Bắc
                              </span>
                              <span> ngày {getDayNow()}</span>
                            </span>
                          </div>
                          <div className="ref_friend">
                            <div>- Số dư nông trại: </div>
                            <span style={{ margin: "0px 2px" }}></span>
                            <div
                              style={{
                                color: "#28a745",
                              }}
                            >
                              {userInfo.vnd.toLocaleString("vi") + " đồng"}
                            </div>
                          </div>
                          <div className="ref_friend">
                            <div>- Số dư tiền mặt: </div>
                            <span style={{ margin: "0px 2px" }}></span>
                            <div
                              style={{
                                color: "#28a745",
                              }}
                            >
                              {userInfo.xu.toLocaleString("vi") + " đồng"}
                            </div>
                          </div>

                          <div className="ref_friend">
                            <div>
                              - Tỉ lệ nhận thưởng:{" "}
                              <span
                                style={{
                                  color: "rgb(0, 123, 255)",
                                  cursor: "pointer",
                                }}
                              >
                                {RATIO_XOSO_TEXT}{" "}
                                <span
                                  onClick={() => {
                                    alert(RATIO_XOSO_MEAN);
                                  }}
                                  style={{
                                    color: "rgb(220, 53, 69)",
                                  }}
                                >
                                  *Giải thích
                                </span>
                              </span>
                            </div>
                          </div>

                          <div className="ref_friend">
                            <div>- Số tiền đặt tối thiểu: </div>
                            <span style={{ margin: "0px 2px" }}></span>
                            <div
                              style={{
                                color: "rgb(220, 53, 69)",
                              }}
                            >
                              {MIN_XOSO_TEXT}
                            </div>
                          </div>

                          <div className="ref_friend">
                            <div>- Dự đoán tối đa: </div>
                            <span style={{ margin: "0px 2px" }}></span>
                            <div
                              style={{
                                color: "rgb(220, 53, 69)",
                              }}
                            >
                              3 lần / ngày
                            </div>
                          </div>

                          <div className="ref_friend">
                            <div>- Thời gian cập nhật kết quả: </div>
                            <span style={{ margin: "0px 2px" }}></span>
                            <div
                              style={{
                                color: "rgb(220, 53, 69)",
                              }}
                            >
                              Sau 18h30 mỗi ngày
                            </div>
                          </div>

                          <div className="ref_friend">
                            <span>
                              <span
                                style={{
                                  color: "#007bff",
                                }}
                              >
                                * Nhập một con số từ 0 đến 99, số tiền và bấm
                                "Dự đoán" trước 17h:
                              </span>
                            </span>
                            <span style={{ margin: "0px 2px" }}></span>
                          </div>

                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                              marginBottom: "10px",
                            }}
                          >
                            <label
                              style={{
                                marginBottom: 0,
                                marginRight: "5px",
                              }}
                              className="form-label"
                            >
                              {" "}
                              - Con số [0 - 99]:
                            </label>
                            <input
                              style={{
                                width: "30vw",
                              }}
                              className="form-control showordisable"
                              name="conSoDuDoan"
                              defaultValue={thongTinDuDoan.conSoDuDoan}
                              type="number"
                              placeholder="Số dự đoán"
                              onInput={(e) => {
                                setThongTinDuDoan({
                                  ...thongTinDuDoan,
                                  conSoDuDoan: Number(e.target.value),
                                });
                              }}
                            />
                          </div>

                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                            }}
                          >
                            <label
                              style={{
                                marginBottom: 0,
                                marginRight: "5px",
                              }}
                              className="form-label"
                            >
                              {" "}
                              - Nhập số tiền:
                            </label>
                            <input
                              style={{
                                width: "30vw",
                              }}
                              className="form-control showordisable"
                              name="soTienDuDoan"
                              defaultValue={thongTinDuDoan.soTienDuDoan}
                              type="number"
                              placeholder="Số tiền dự đoán"
                              onInput={(e) => {
                                setThongTinDuDoan({
                                  ...thongTinDuDoan,
                                  soTienDuDoan: Number(e.target.value),
                                });
                              }}
                            />
                          </div>

                          <div
                            className="ref_friend"
                            style={{
                              textAlign: "center",
                              display: "block",
                            }}
                          >
                            <button
                              className="btn btn-success btn_change_status"
                              onClick={() => {
                                handeleDuDoanXoSo();
                              }}
                            >
                              Dự đoán
                            </button>
                          </div>
                        </div>
                        {/* Kết quả xổ số */}
                        <div>
                          <h4
                            className="card-title"
                            style={{
                              textAlign: "center",
                              marginTop: 20,
                            }}
                          >
                            Kết quả xổ số mới nhất
                          </h4>
                          <div className="table-responsive pt-3">
                            <table className="table table-bordered text-center">
                              <thead>
                                <tr>
                                  <th
                                    style={{
                                      whiteSpace: "nowrap",
                                    }}
                                  >
                                    STT
                                  </th>
                                  <th
                                    style={{
                                      whiteSpace: "nowrap",
                                    }}
                                  >
                                    Kết quả ngày
                                  </th>
                                  <th
                                    style={{
                                      whiteSpace: "nowrap",
                                    }}
                                  >
                                    Con số trúng
                                  </th>
                                  <th
                                    style={{
                                      whiteSpace: "nowrap",
                                    }}
                                  >
                                    Trạng thái
                                  </th>
                                </tr>
                              </thead>
                              <tbody>
                                {ketQuaXoSoList.map((history, index) => {
                                  if (
                                    index >= (pageKetQua - 1) * maxShowKetQua &&
                                    index < pageKetQua * maxShowKetQua
                                  ) {
                                    return (
                                      <tr key={index} className="table-white">
                                        <td>{index + 1}</td>
                                        <td>
                                          {convertToDateOnly(
                                            history.ket_qua_date
                                          )}
                                        </td>
                                        <td>{history.number_trung_thuong}</td>
                                        {history.is_tra_thuong ? (
                                          <td
                                            style={{
                                              whiteSpace: "nowrap",
                                            }}
                                          >
                                            <span
                                              style={{
                                                backgroundColor: "#28a745",
                                                color: "#fff",
                                                padding: "5px 10px",
                                                borderRadius: "5px",
                                                whiteSpace: "nowrap",
                                              }}
                                            >
                                              Đã trả thưởng
                                            </span>
                                          </td>
                                        ) : (
                                          <td
                                            style={{
                                              whiteSpace: "nowrap",
                                            }}
                                          >
                                            <span
                                              style={{
                                                backgroundColor: "#007bff",
                                                color: "#fff",
                                                padding: "5px 10px",
                                                borderRadius: "5px",
                                                whiteSpace: "nowrap",
                                              }}
                                            >
                                              Chưa trả thưởng
                                            </span>
                                          </td>
                                        )}
                                      </tr>
                                    );
                                  } else return "";
                                })}
                              </tbody>
                            </table>
                          </div>
                          <div
                            className="flex_center pagination_mg"
                            style={{
                              marginBottom: 20,
                            }}
                          >
                            {/* <div>Tổng số: {serverDuDoanList.length}</div> */}
                            <ReactPaginate
                              breakLabel="..."
                              nextLabel="Next"
                              onPageChange={handlePageKetQuaClick}
                              pageRangeDisplayed={2}
                              pageCount={
                                ketQuaXoSoList.length <= maxShowKetQua
                                  ? 1
                                  : ketQuaXoSoList.length % maxShowKetQua === 0
                                  ? Math.floor(
                                      ketQuaXoSoList.length / maxShowKetQua
                                    )
                                  : Math.floor(
                                      ketQuaXoSoList.length / maxShowKetQua
                                    ) + 1
                              }
                              previousLabel="Previous"
                              renderOnZeroPageCount={null}
                              pageClassName="page-item"
                              pageLinkClassName="page-link"
                              previousClassName="page-item"
                              previousLinkClassName="page-link"
                              nextClassName="page-item"
                              nextLinkClassName="page-link"
                              breakClassName="page-item"
                              breakLinkClassName="page-link"
                              containerClassName="pagination"
                              activeClassName="active"
                            />
                          </div>
                        </div>
                        {/* Dự đoán cá nhân */}
                        <div>
                          <h4
                            className="card-title"
                            style={{
                              textAlign: "center",
                              marginTop: 20,
                            }}
                          >
                            Lịch sử dự đoán của bạn
                          </h4>
                          <div className="table-responsive pt-3">
                            <table className="table table-bordered text-center">
                              <thead>
                                <tr>
                                  <th
                                    style={{
                                      whiteSpace: "nowrap",
                                    }}
                                  >
                                    STT
                                  </th>
                                  <th
                                    style={{
                                      whiteSpace: "nowrap",
                                    }}
                                  >
                                    Username
                                  </th>
                                  <th
                                    style={{
                                      whiteSpace: "nowrap",
                                    }}
                                  >
                                    Dự đoán ngày
                                  </th>
                                  <th
                                    style={{
                                      whiteSpace: "nowrap",
                                    }}
                                  >
                                    Con số
                                  </th>
                                  <th
                                    style={{
                                      whiteSpace: "nowrap",
                                    }}
                                  >
                                    Số tiền
                                  </th>

                                  <th
                                    style={{
                                      whiteSpace: "nowrap",
                                    }}
                                  >
                                    Số tiền nhận nếu trúng
                                  </th>
                                  <th
                                    style={{
                                      whiteSpace: "nowrap",
                                    }}
                                  >
                                    Thời gian
                                  </th>
                                  <th
                                    style={{
                                      whiteSpace: "nowrap",
                                    }}
                                  >
                                    Trạng thái
                                  </th>
                                </tr>
                              </thead>
                              <tbody>
                                {userDuDoanList.map((history, index) => {
                                  if (
                                    index >= (pageUser - 1) * maxShowUser &&
                                    index < pageUser * maxShowUser
                                  ) {
                                    return (
                                      <tr key={index} className="table-white">
                                        <td>{index + 1}</td>
                                        <td>{history.User.username}</td>
                                        <td>
                                          {convertToDateOnly(
                                            history.date_du_doan
                                          )}
                                        </td>

                                        <td>{history.number_du_doan}</td>
                                        <td>
                                          {history.xu_du_doan.toLocaleString(
                                            "vi"
                                          ) + "đ"}
                                        </td>
                                        <td>
                                          {history.xu_neu_trung.toLocaleString(
                                            "vi"
                                          ) + "đ"}
                                        </td>
                                        <td
                                          style={{
                                            whiteSpace: "nowrap",
                                          }}
                                        >
                                          {convertToDate(history.createdAt)}
                                        </td>
                                        {history.is_trung_thuong === 1 ? (
                                          <td
                                            style={{
                                              whiteSpace: "nowrap",
                                            }}
                                          >
                                            <span
                                              style={{
                                                backgroundColor: "#007bff",
                                                color: "#fff",
                                                padding: "5px 10px",
                                                borderRadius: "5px",
                                                whiteSpace: "nowrap",
                                              }}
                                            >
                                              Chờ kết quả
                                            </span>
                                          </td>
                                        ) : history.is_trung_thuong === 2 &&
                                          history.is_cong_xu ? (
                                          <td
                                            style={{
                                              whiteSpace: "nowrap",
                                            }}
                                          >
                                            <span
                                              style={{
                                                backgroundColor: "#28a745",
                                                color: "#fff",
                                                padding: "5px 10px",
                                                borderRadius: "5px",
                                                whiteSpace: "nowrap",
                                              }}
                                            >
                                              Trúng và đã cộng tiền
                                            </span>
                                          </td>
                                        ) : history.is_trung_thuong === 2 &&
                                          history.is_cong_xu === false ? (
                                          <td
                                            style={{
                                              whiteSpace: "nowrap",
                                            }}
                                          >
                                            <span
                                              style={{
                                                backgroundColor: "yellow",
                                                color: "#fff",
                                                padding: "5px 10px",
                                                borderRadius: "5px",
                                                whiteSpace: "nowrap",
                                              }}
                                            >
                                              Trúng và chưa cộng tiền
                                            </span>
                                          </td>
                                        ) : (
                                          <td
                                            style={{
                                              whiteSpace: "nowrap",
                                            }}
                                          >
                                            <span
                                              style={{
                                                backgroundColor: "#dc3545",
                                                color: "#fff",
                                                padding: "5px 10px",
                                                borderRadius: "5px",
                                                whiteSpace: "nowrap",
                                              }}
                                            >
                                              Không trúng
                                            </span>
                                          </td>
                                        )}
                                      </tr>
                                    );
                                  } else return "";
                                })}
                              </tbody>
                            </table>
                          </div>
                          <div
                            className="flex_center pagination_mg"
                            style={{
                              marginBottom: 20,
                            }}
                          >
                            {/* <div>Tổng số: {serverDuDoanList.length}</div> */}
                            <ReactPaginate
                              breakLabel="..."
                              nextLabel="Next"
                              onPageChange={handlePageUserClick}
                              pageRangeDisplayed={2}
                              pageCount={
                                userDuDoanList.length <= maxShowUser
                                  ? 1
                                  : userDuDoanList.length % maxShowUser === 0
                                  ? Math.floor(
                                      userDuDoanList.length / maxShowUser
                                    )
                                  : Math.floor(
                                      userDuDoanList.length / maxShowUser
                                    ) + 1
                              }
                              previousLabel="Previous"
                              renderOnZeroPageCount={null}
                              pageClassName="page-item"
                              pageLinkClassName="page-link"
                              previousClassName="page-item"
                              previousLinkClassName="page-link"
                              nextClassName="page-item"
                              nextLinkClassName="page-link"
                              breakClassName="page-item"
                              breakLinkClassName="page-link"
                              containerClassName="pagination"
                              activeClassName="active"
                            />
                          </div>
                        </div>
                        {/* 50 dự đoán server */}
                        <h4
                          className="card-title"
                          style={{
                            textAlign: "center",
                            marginTop: 20,
                          }}
                        >
                          50 người dự đoán gần nhất
                        </h4>
                        <div className="table-responsive pt-3">
                          <table className="table table-bordered text-center">
                            <thead>
                              <tr>
                                <th
                                  style={{
                                    whiteSpace: "nowrap",
                                  }}
                                >
                                  STT
                                </th>
                                <th
                                  style={{
                                    whiteSpace: "nowrap",
                                  }}
                                >
                                  Username
                                </th>
                                <th
                                  style={{
                                    whiteSpace: "nowrap",
                                  }}
                                >
                                  Dự đoán ngày
                                </th>
                                <th
                                  style={{
                                    whiteSpace: "nowrap",
                                  }}
                                >
                                  Con số
                                </th>
                                <th
                                  style={{
                                    whiteSpace: "nowrap",
                                  }}
                                >
                                  Số tiền
                                </th>

                                <th
                                  style={{
                                    whiteSpace: "nowrap",
                                  }}
                                >
                                  Số tiền nhận nếu trúng
                                </th>
                                <th
                                  style={{
                                    whiteSpace: "nowrap",
                                  }}
                                >
                                  Thời gian
                                </th>
                                <th
                                  style={{
                                    whiteSpace: "nowrap",
                                  }}
                                >
                                  Trạng thái
                                </th>
                              </tr>
                            </thead>
                            <tbody>
                              {serverDuDoanList.map((history, index) => {
                                if (
                                  index >= (pageServer - 1) * maxShow &&
                                  index < pageServer * maxShow
                                ) {
                                  return (
                                    <tr key={index} className="table-white">
                                      <td>{index + 1}</td>
                                      <td>{history.User.username}</td>
                                      <td>
                                        {convertToDateOnly(
                                          history.date_du_doan
                                        )}
                                      </td>
                                      <td>{history.number_du_doan}</td>
                                      <td>
                                        {history.xu_du_doan.toLocaleString(
                                          "vi"
                                        ) + "đ"}
                                      </td>
                                      <td>
                                        {history.xu_neu_trung.toLocaleString(
                                          "vi"
                                        ) + "đ"}
                                      </td>
                                      <td
                                        style={{
                                          whiteSpace: "nowrap",
                                        }}
                                      >
                                        {convertToDate(history.createdAt)}
                                      </td>
                                      {history.is_trung_thuong === 1 ? (
                                        <td
                                          style={{
                                            whiteSpace: "nowrap",
                                          }}
                                        >
                                          <span
                                            style={{
                                              backgroundColor: "#007bff",
                                              color: "#fff",
                                              padding: "5px 10px",
                                              borderRadius: "5px",
                                              whiteSpace: "nowrap",
                                            }}
                                          >
                                            Chờ kết quả
                                          </span>
                                        </td>
                                      ) : history.is_trung_thuong === 2 &&
                                        history.is_cong_xu ? (
                                        <td
                                          style={{
                                            whiteSpace: "nowrap",
                                          }}
                                        >
                                          <span
                                            style={{
                                              backgroundColor: "#28a745",
                                              color: "#fff",
                                              padding: "5px 10px",
                                              borderRadius: "5px",
                                              whiteSpace: "nowrap",
                                            }}
                                          >
                                            Trúng và đã cộng tiền
                                          </span>
                                        </td>
                                      ) : history.is_trung_thuong === 2 &&
                                        history.is_cong_xu === false ? (
                                        <td
                                          style={{
                                            whiteSpace: "nowrap",
                                          }}
                                        >
                                          <span
                                            style={{
                                              backgroundColor: "yellow",
                                              color: "#fff",
                                              padding: "5px 10px",
                                              borderRadius: "5px",
                                              whiteSpace: "nowrap",
                                            }}
                                          >
                                            Trúng và chưa cộng tiền
                                          </span>
                                        </td>
                                      ) : (
                                        <td
                                          style={{
                                            whiteSpace: "nowrap",
                                          }}
                                        >
                                          <span
                                            style={{
                                              backgroundColor: "#dc3545",
                                              color: "#fff",
                                              padding: "5px 10px",
                                              borderRadius: "5px",
                                              whiteSpace: "nowrap",
                                            }}
                                          >
                                            Không trúng
                                          </span>
                                        </td>
                                      )}
                                    </tr>
                                  );
                                } else return "";
                              })}
                            </tbody>
                          </table>
                        </div>
                        <div
                          className="flex_center pagination_mg"
                          style={{
                            marginBottom: 20,
                          }}
                        >
                          {/* <div>Tổng số: {serverDuDoanList.length}</div> */}
                          <ReactPaginate
                            breakLabel="..."
                            nextLabel="Next"
                            onPageChange={handlePageServerClick}
                            pageRangeDisplayed={2}
                            pageCount={
                              serverDuDoanList.length <= maxShow
                                ? 1
                                : serverDuDoanList.length % maxShow === 0
                                ? Math.floor(serverDuDoanList.length / maxShow)
                                : Math.floor(
                                    serverDuDoanList.length / maxShow
                                  ) + 1
                            }
                            previousLabel="Previous"
                            renderOnZeroPageCount={null}
                            pageClassName="page-item"
                            pageLinkClassName="page-link"
                            previousClassName="page-item"
                            previousLinkClassName="page-link"
                            nextClassName="page-item"
                            nextLinkClassName="page-link"
                            breakClassName="page-item"
                            breakLinkClassName="page-link"
                            containerClassName="pagination"
                            activeClassName="active"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
    </Fragment>
  );
}
