import React, { Fragment, useEffect, useState } from "react";
import "../../styles/App.css";
import axios from "axios";
import { ApiLink, convertToDateOnly, notify, Title } from "../../utils/Title";
import { useNavigate } from "react-router-dom";
import UserBoardLayout from "../../components/Layouts/BasicLayout/UserBoardLayout";
import InputUsernameLayout from "../../components/Layouts/BasicLayout/InputUsernameLayout";
import NotifyLayout from "../../components/Layouts/BasicLayout/NotifyLayout";
import AdSenseAd from "../../components/AdSenseAd/AdSenseAd";
import Footer from "../../components/Layouts/BasicLayout/Footer";
export default function AppPage() {
  const [show, setShow] = useState(-1);
  const [loading, isLoading] = useState(true);
  const [info, setInfo] = useState(null);
  let [mobileAppList, setMobileAppList] = useState([]);
  let navigate = useNavigate();
  let [usernameInput, setUsernameInput] = useState("");
  let [username, setUsername] = useState("");
  let [showNotify, setShowNotify] = useState(false);

  useEffect(() => {
    document.title = Title.mobile_app + Title.origin;
  }, []);

  useEffect(() => {
    async function getAllMobileApp() {
      const response = await axios.get(`${ApiLink.domain + "/mobile-app"}`, {
        withCredentials: true,
      });
      if (response.data.status) {
        setMobileAppList(response.data.data); // status, data
      } else {
        if (response.data.must === "login") {
          window.localStorage.clear();
          return navigate("/", { replace: true });
        } else if (response.data.must === "permission") {
          notify(false, response.data.message);
          return navigate("/", { replace: true });
        } else {
          return navigate("/404", { replace: true });
        }
      }
    }

    async function getUserInfo() {
      const response = await axios.get(`${ApiLink.domain + "/account/info"}`, {
        withCredentials: true,
      });
      if (response.data.status) {
        setUsername(response.data.data.username); // status, data
        setInfo(response.data.data);
      } else {
        if (response.data.must === "login") {
          window.localStorage.clear();
        } else {
          notify(false, response.data.message);
        }
      }
    }

    const getAll = async () => {
      await Promise.all([
        Promise.resolve(getAllMobileApp()),
        Promise.resolve(getUserInfo()),
      ]);
      isLoading(false);
    };
    getAll();
  }, [navigate]);

  async function checkUsername(username) {
    const response = await axios.post(
      `${ApiLink.domain + "/account/username"}`,
      { username },
      {
        withCredentials: true,
      }
    );
    notify(response.data.status, response.data.message);
    if (response.data.status) {
      setUsername(username.trim());
      setInfo(null);
    } else {
      setUsername("");
      setInfo(null);
    }
  }

  const handleSetShowNotify = () => {
    setShowNotify(!showNotify);
  };

  const handleViewDetail = (id) => {
    id === show ? setShow(-1) : setShow(id);
  };

  return loading ? (
    <div></div>
  ) : (
    <div className="app_container">
      <UserBoardLayout
        Element={
          <InputUsernameLayout
            checkUsername={checkUsername}
            setUsernameInput={setUsernameInput}
            usernameInput={usernameInput}
          />
        }
        info={info}
        setInfo={setInfo}
        username={username}
        setUsername={setUsername}
        usernameInput={usernameInput}
        setUsernameInput={setUsernameInput}
        checkUsername={checkUsername}
        handleShowNotify={handleSetShowNotify}
        showNotify={showNotify}
      />

      {showNotify && <NotifyLayout />}

      <div className="list_app">
        <div className="list_name">Các chương trình đang diễn ra</div>
        {mobileAppList.map((item, index) => {
          return (
            <Fragment key={index}>
              <div
                className="one_app"
                style={{
                  marginTop: index === 0 ? "0px" : "10px",
                }}
              >
                <div className="one_app--top">
                  <img
                    className="one_app--top_left"
                    src={item.app_logo}
                    alt="image_app"
                  ></img>
                  <div className="one_app--top_right">
                    <div className="app_title">
                      <img
                        className="app_title_icon"
                        alt="app_title_icon"
                        src="https://kiemthecaofree.com/hot.gif"
                      ></img>
                      <span className="app_title_name">{item.app_name}</span>
                    </div>
                    <div className="app_price">
                      <span className="one_app_child_title">Tiền thưởng:</span>
                      <span className="one_app_child_content">
                        {item.app_price}
                      </span>
                    </div>

                    <div className="app_price">
                      <span className="one_app_child_title">Thời gian:</span>
                      <span className="one_app_child_content">
                        {
                          <span
                            className="content"
                            dangerouslySetInnerHTML={{
                              __html:
                                item.Status_App.status_code === "ACTIVE"
                                  ? "Diễn ra từ " +
                                    convertToDateOnly(item.app_start) +
                                    " - " +
                                    convertToDateOnly(item.app_expired)
                                  : item.stop_notify,
                            }}
                          ></span>
                        }
                      </span>
                    </div>

                    <div className="app_price">
                      <span className="one_app_child_title">Hệ điều hành:</span>
                      <span className="one_app_child_content">
                        {item.app_system}
                      </span>
                    </div>

                    <div className="app_price">
                      <span className="one_app_child_title">Yêu cầu:</span>
                      <span className="one_app_child_content">
                        {
                          <span
                            className="content"
                            dangerouslySetInnerHTML={{
                              __html: item.app_require,
                            }}
                          ></span>
                        }
                      </span>
                    </div>

                    {item.status_id === 2 ? (
                      <div className="app_download_error">
                        {item.stop_notify}
                      </div>
                    ) : item.app_utm && username === "" ? (
                      <div className="app_download_error">
                        Bạn cần tạo Username để tải App này.
                      </div>
                    ) : (
                      <div
                        className="app_download"
                        style={{ cursor: "pointer" }}
                      >
                        <a
                          target="_blank"
                          rel="noreferrer"
                          href={
                            item.app_utm
                              ? item.app_first_download +
                                username +
                                item.app_end_download
                              : item.app_first_download
                          }
                          style={{ color: "#fff" }}
                        >
                          Tải App
                        </a>
                      </div>
                    )}
                  </div>
                </div>
                <div className="one_app--bottom">
                  <div className="one_app--bottom-top">
                    <p
                      className="one_app--bottom_step_title"
                      style={{ color: "#17a2b8" }}
                    >
                      Các bước thực hiện:
                    </p>
                    <div
                      className="one_app--bottom_step"
                      onClick={() => {
                        handleViewDetail(item.app_id);
                      }}
                    >
                      <img
                        className="icon_down"
                        alt="icon_down"
                        src={
                          show === item.app_id
                            ? "/images/icons/up-arrow.png"
                            : "/images/icons/down-arrow.png"
                        }
                      ></img>
                      <p className="one_app--bottom_step_title">
                        {show === item.app_id
                          ? "Tắt hướng dẫn"
                          : "Xem hướng dẫn"}
                      </p>
                    </div>
                  </div>
                  <div
                    className="one_app--bottom-bottom"
                    style={{
                      display: show === item.app_id ? "block" : "none",
                    }}
                  >
                    {
                      <div
                        className="content"
                        dangerouslySetInnerHTML={{
                          __html: item.app_description,
                        }}
                      ></div>
                    }

                    <div className="app_note">
                      <p className="app_note--title">Lưu ý:</p>
                      <div className="app_note--content">
                        <div
                          className="content"
                          dangerouslySetInnerHTML={{ __html: item.app_note }}
                        ></div>
                      </div>
                    </div>

                    <div className="app_video">
                      <p className="app_video--title">Video hướng dẫn:</p>
                      <iframe
                        className="video_source"
                        width="100%"
                        height="220px"
                        src={item.app_video}
                        title="YouTube video player"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                        allowFullScreen
                      ></iframe>
                    </div>
                  </div>
                </div>
              </div>
              {index === mobileAppList.length - 1 ? (
                <div className="end_list">Đã tới cuối danh sách</div>
              ) : (
                ""
              )}
            </Fragment>
          );
        })}
      </div>
      <AdSenseAd />
      <Footer margin_top={"15px"} />
    </div>
  );
}
