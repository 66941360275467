import React, { useEffect, useState } from "react";
import axios from "axios";
import {
  ApiLink,
  convertToDate,
  convertToTimeFarm,
  notify,
} from "../../../../utils/Title";
import { useNavigate } from "react-router-dom";

export default function FormUserAnimal({
  status,
  closeFormAdd,
  loadData,
  vatNuoiChoose,
}) {
  let [userAnimalList, setUseruserAnimalList] = useState([]);
  let navigate = useNavigate();
  let [keyword, setKeyword] = useState(vatNuoiChoose);

  const filterSearch = () => {
    return userAnimalList.filter((item) => {
      return (
        item.FarmAnimal.animal_name.toLowerCase().includes(keyword) ||
        Number(item.id) === Number(keyword)
      );
    });
  };

  const isSinhTruong = (vat_nuoi) => {
    let current_time = Date.now();
    let time_sinhtruong = new Date(vat_nuoi.time_mua);
    time_sinhtruong = time_sinhtruong.setMinutes(
      time_sinhtruong.getMinutes() + Number(vat_nuoi.FarmAnimal.time_sinhtruong)
    );

    if (current_time > time_sinhtruong) {
      return true;
    }
    return false;
  };

  const getTimeSinhTruong = (vat_nuoi) => {
    let time_sinhtruong = new Date(vat_nuoi.time_mua);
    time_sinhtruong = time_sinhtruong.setMinutes(
      time_sinhtruong.getMinutes() + Number(vat_nuoi.FarmAnimal.time_sinhtruong)
    );
    return convertToDate(new Date(time_sinhtruong));
  };

  const getTimeChetDoi = (vat_nuoi) => {
    let current_time = Date.now();
    let time_choan_last = new Date(vat_nuoi.time_choan);
    time_choan_last = time_choan_last.setMinutes(
      time_choan_last.getMinutes() + Number(vat_nuoi.FarmAnimal.time_choan)
    );

    const time_chet_doi = Math.round(
      (time_choan_last - current_time) / (1000 * 60)
    );
    return convertToTimeFarm(time_chet_doi);
  };

  useEffect(() => {
    async function getAllUserAnimal() {
      const response = await axios.get(`${ApiLink.domain + "/user-animal"}`, {
        withCredentials: true,
      });
      if (response.data.status) {
        const list = response.data.data.map((item) => ({
          ...item,
          quanty: 0,
        }));
        setUseruserAnimalList(list);
      } else {
        notify(false, response.data.message);
        if (response.data.must === "login") {
          return navigate("/account/login", { replace: true });
        } else if (response.data.must === "permission") {
          return;
        }
      }
    }
    const getAll = async () => {
      await Promise.all([Promise.resolve(getAllUserAnimal())]);
    };

    getAll();
  }, [navigate]);

  const sellFarmAnimal = async (id, animal_id) => {
    const response = await axios.post(
      `${ApiLink.domain + "/user-animal/ban/" + id}`,
      { animal_id },
      {
        withCredentials: true,
      }
    );
    if (response.data.status) {
      notify(true, response.data.message);
      loadData(response.data);
      closeFormAdd();
    } else {
      notify(false, response.data.message);
      if (response.data.must === "login") {
        return navigate("/account/login", { replace: true });
      } else if (response.data.must === "permission") {
        return;
      }
    }
  };

  const handleSellAnimal = async (id, animal_id) => {
    if (!id || !animal_id) {
      notify(false, "Vui lòng chọn vật nuôi bán");
      return;
    }
    await sellFarmAnimal(id, animal_id);
  };

  return (
    <div
      className="row col-9 add_edit_class"
      style={{
        display: "block",
        backgroundColor: "rgb(242, 247, 255)",
        borderRadius: "3px 3px 0px 0px",
        boxShadow:
          "rgb(98, 176, 253) 0px 4px 8px, rgba(0, 0, 0, 0.08) 0px 4px 12px",
        padding: "0px !important",
        margin: 0,
      }}
    >
      <div
        className="bg-primary row"
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          padding: "15px 20px",
          margin: 0,
        }}
      >
        <div className="text-white add_book_class_header">
          <span>Vật nuôi của bạn</span>
        </div>
        <div onClick={() => closeFormAdd()}>
          <i
            className="far fa-times-circle btn_close_form"
            style={{ color: "white", fontSize: 25 }}
          />
        </div>
      </div>

      <div
        className="row col-12 sroll_form style-15 mobile_min_width_100 mobile_no_wrap"
        style={{
          padding: "0px !important",
          display: "flex",
          flexDirection: "row",
          margin: 0,
        }}
      >
        <div
          className="one_farm_seed__bottom input"
          style={{
            display: "flex",
            alignItems: "center",
            minWidth: "200px",
            marginTop: "10px",
          }}
        >
          <div>Tìm vật nuôi:</div>
          <input
            style={{
              width: "50%",
            }}
            type="text"
            defaultValue={vatNuoiChoose}
            onChange={(e) => {
              setKeyword(e.target.value);
            }}
          />
        </div>
        <div
          className="row col-12 mobile_min_width_100"
          style={{
            paddingLeft: 20,
            paddingRight: 20,
            paddingTop: 20,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <div className="shop_list">
            {filterSearch().map((user_animal, index) => {
              return (
                <div key={index} className="one_farm_seed">
                  <div className="one_farm_seed__top">
                    <img
                      id="vatnuoi"
                      src={"/images/" + user_animal.FarmAnimal.avatar}
                      alt="rau_cu"
                    />
                    <div>
                      <b>{"[ " + user_animal.FarmAnimal.animal_name + " ]"}</b>
                      <div className="one_farm_seed__price">
                        Giá đã mua: <b>{user_animal.FarmAnimal.xu}</b> xu / con
                      </div>
                    </div>
                  </div>{" "}
                  <div className="one_farm_seed__mid">
                    <div>Thời điểm lớn: {getTimeSinhTruong(user_animal)}</div>
                    {user_animal.FarmAnimal.is_sell && (
                      <div>
                        Thu{" "}
                        {user_animal.FarmAnimal.sansinh_name +
                          ": " +
                          convertToDate(user_animal.time_thuhoach)}
                      </div>
                    )}
                    <div>Sống tới: {convertToDate(user_animal.time_song)}</div>

                    <div>
                      Giá bán hiện tại:{" "}
                      {isSinhTruong(user_animal)
                        ? "[ " + user_animal.FarmAnimal.sell_full + "đ ]"
                        : "[ " + user_animal.FarmAnimal.sell + "đ ]"}
                    </div>
                    <div
                      style={{
                        color: "red",
                      }}
                    >
                      Chết đói sau: {getTimeChetDoi(user_animal)}
                    </div>
                  </div>
                  <div className="one_farm_seed__bottom">
                    <div>
                      Bán 1 con {user_animal.FarmAnimal.animal_name + ": "}
                    </div>
                    <div
                      style={{
                        marginLeft: "10px",
                      }}
                      className="one_farm_seed__buy"
                      onClick={() => {
                        handleSellAnimal(user_animal.id, user_animal.animal_id);
                      }}
                    >
                      Bán luôn
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
      <div
        style={{ display: "flex", justifyContent: "center", marginBottom: 10 }}
      ></div>
    </div>
  );
}
