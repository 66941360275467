import React, { useEffect, useState } from "react";
import axios from "axios";
import ReactPaginate from "react-paginate";
import SearchTheme from "./SearchTheme";
import { useNavigate } from "react-router-dom";
import { ApiLink, convertToDate, notify } from "../../../utils/Title";
import AdminLogo from "./AdminLogo";

export default function TaskEverydayHistoryAdmin({ username }) {
  let [itemList, setItemList] = useState([]);
  let [page, setPage] = useState(1);
  const maxShow = 10;
  let navigate = useNavigate();
  let [keyword, setKeyword] = useState("");

  useEffect(() => {
    async function getAll() {
      const response = await axios.get(
        `${ApiLink.domain + "/admin/task-everyday-history"}`,
        {
          withCredentials: true,
        }
      );
      if (response.data.status) {
        setItemList(response.data.data); // status, data
      } else {
        notify(false, response.data.message);
        if (response.data.must === "login") {
          window.localStorage.clear();
          return navigate("/", { replace: true });
        } else if (response.data.must === "permission") {
          return navigate("/", { replace: true });
        }
      }
    }
    getAll();
  }, [navigate]);

  const handlePageClick = (event) => {
    if (page !== event.selected + 1) setPage(event.selected + 1);
  };

  const handleSearch = (keyword) => {
    setKeyword(keyword.toLowerCase().trim());
  };

  const filterSearch = () => {
    return itemList.filter((item) => {
      return (
        item.User.username.toLowerCase().includes(keyword) ||
        item.note.toLowerCase().includes(keyword) ||
        item.task_prize_text.toLowerCase().includes(keyword) ||
        Number(item.xu) === Number(keyword) ||
        Number(item.mo_qua_quanty) === Number(keyword) ||
        Number(item.vong_quay_quanty) === Number(keyword) ||
        Number(item.user_id) === Number(keyword)
      );
    });
  };

  return (
    <div id="main">
      <AdminLogo />
      <div className="page-content">
        <section
          className="row"
          style={{
            marginLeft: "-5px",
            marginRight: "-5px",
          }}
        >
          <div className="col-12 col-lg-12">
            {/* <AdminThemeTop username={username} /> */}
            <SearchTheme
              title={"Tìm kiếm lịch sử"}
              handleSearch={handleSearch}
            />
            <div className="row">
              <div className="col-12">
                <div className="col-lg-12 stretch-card" style={{ padding: 0 }}>
                  <div className="card">
                    <div className="card-body">
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "space-between",
                        }}
                      >
                        <h4
                          className="card-title"
                          style={{ marginBottom: "0 !important" }}
                        >
                          Danh sách lịch sử
                        </h4>
                      </div>
                      <div className="table-responsive pt-3">
                        <table className="table table-bordered text-center">
                          <thead>
                            <tr>
                              <th style={{ whiteSpace: "nowrap" }}>ID</th>
                              <th style={{ whiteSpace: "nowrap" }}>Username</th>
                              <th style={{ whiteSpace: "nowrap" }}>
                                Tên nhiệm vụ
                              </th>
                              <th style={{ whiteSpace: "nowrap" }}>
                                Phần thưởng
                              </th>
                              <th style={{ whiteSpace: "nowrap" }}>
                                Xu thưởng
                              </th>

                              <th style={{ whiteSpace: "nowrap" }}>
                                Lần mở quà
                              </th>
                              <th style={{ whiteSpace: "nowrap" }}>
                                Số vòng quay
                              </th>
                              <th style={{ whiteSpace: "nowrap" }}>
                                Ngày hoàn thành
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            {filterSearch().map((item, index) => {
                              if (
                                index >= (page - 1) * maxShow &&
                                index < page * maxShow
                              ) {
                                return (
                                  <tr key={index} className="table-white">
                                    <td style={{ whiteSpace: "nowrap" }}>
                                      {item.task_history_id}
                                    </td>
                                    <td>{item.User.username}</td>
                                    <td style={{ whiteSpace: "nowrap" }}>
                                      {item.User.TaskEveryday
                                        ? item.User.TaskEveryday
                                        : " - "}
                                    </td>
                                    <td style={{ whiteSpace: "nowrap" }}>
                                      {item.task_prize_text}
                                    </td>
                                    <td>
                                      {item.xu.toLocaleString("vi") + "đ"}
                                    </td>
                                    <td>{item.mo_qua_quanty}</td>
                                    <td>{item.vong_quay_quanty}</td>
                                    <td style={{ whiteSpace: "nowrap" }}>
                                      {convertToDate(item.updatedAt)}
                                    </td>
                                  </tr>
                                );
                              } else return "";
                            })}
                          </tbody>
                        </table>
                      </div>
                      <div className="flex_center pagination_mg">
                        <div>Tổng số: {filterSearch().length}</div>
                        <ReactPaginate
                          breakLabel="..."
                          nextLabel="Next"
                          onPageChange={handlePageClick}
                          pageRangeDisplayed={2}
                          pageCount={
                            filterSearch().length <= maxShow
                              ? 1
                              : filterSearch().length % maxShow === 0
                              ? Math.floor(filterSearch().length / maxShow)
                              : Math.floor(filterSearch().length / maxShow) + 1
                          }
                          previousLabel="Previous"
                          renderOnZeroPageCount={null}
                          pageClassName="page-item"
                          pageLinkClassName="page-link"
                          previousClassName="page-item"
                          previousLinkClassName="page-link"
                          nextClassName="page-item"
                          nextLinkClassName="page-link"
                          breakClassName="page-item"
                          breakLinkClassName="page-link"
                          containerClassName="pagination"
                          activeClassName="active"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  );
}
