import React, { useState } from "react";
import axios from "axios";
import { ApiLink, notify } from "../../../../utils/Title";
import { useNavigate } from "react-router-dom";

export default function FormAddAnimal({ closeForm, loadData }) {
  let navigate = useNavigate();
  let [itemState, setItemState] = useState({
    animal_name: "",
    xu: "",
    note: "",
    time_song: "",
    time_choan: "",
    time_sinhtruong: "",
    time_sansinh: "",
    is_sell: false,
    sell_sansinh: "",
    max_buy: "",
    sansinh_name: "",
    sell: "",
    sell_full: "",
    image_nuoi: "",
    avatar_sansinh: "",
    avatar: "",
    status: 1,
  });

  const handleOnChange = (e) => {
    let { name, value } = e.target;
    if (
      name === "time_song" ||
      name === "time_choan" ||
      name === "time_sinhtruong" ||
      name === "time_sansinh" ||
      name === "sell_sansinh" ||
      name === "max_buy" ||
      name === "xu" ||
      name === "sell_full" ||
      name === "status" ||
      name === "sell"
    ) {
      value = Number(value);
    } else if (name === "is_sell") {
      if (value === "true") {
        value = true;
      } else {
        value = false;
      }
    }
    setItemState({ ...itemState, [name]: value });
  };

  const editItem = async (newItem) => {
    const response = await axios.post(
      `${ApiLink.domain + "/admin/farm-animal/"}`,
      newItem,
      {
        withCredentials: true,
      }
    );
    if (response.data.status) {
      notify(true, response.data.message);
      loadData(response.data.data);
      closeForm(true, false);
    } else {
      notify(false, response.data.message);
      if (response.data.must === "login") {
        return navigate("/", { replace: true });
      } else if (response.data.must === "permission") {
        return navigate("/", { replace: true });
      }
    }
  };

  const handleEditItem = async () => {
    if (itemState.animal_name.trim().length < 2) {
      notify(false, "Tên vật nuôi cần >= 2 ký tự");
      return;
    }
    if (itemState.avatar.trim().length < 1) {
      notify(false, "Chưa có hình ảnh vật nuôi");
      return;
    }
    if (
      itemState.status !== 1 &&
      itemState.status !== 0 &&
      itemState.status !== false &&
      itemState.status !== true
    ) {
      notify(false, "Vui lòng chọn trạng thái vật nuôi");
      return;
    }
    if (itemState.is_sell !== false && itemState.is_sell !== true) {
      notify(false, "Vui lòng chọn trạng thái sản sinh");
      return;
    } else if (!Number.isInteger(itemState.xu) || Number(itemState.xu) < 0) {
      notify(false, "Số xu không hợp lệ");
      return;
    } else if (
      !Number.isInteger(itemState.sell) ||
      Number(itemState.sell) < 0
    ) {
      notify(false, "Số tiền bán min không hợp lệ");
      return;
    } else if (
      !Number.isInteger(itemState.sell_full) ||
      Number(itemState.sell_full) < 0
    ) {
      notify(false, "Số tiền bán max không hợp lệ");
      return;
    } else if (
      !Number.isInteger(itemState.sell_sansinh) ||
      Number(itemState.sell_sansinh) < 0
    ) {
      notify(false, "Số tiền bán sản sinh không hợp lệ");
      return;
    } else if (
      !Number.isInteger(itemState.time_song) ||
      Number(itemState.time_song) < 0
    ) {
      notify(false, "Thời gian sống không hợp lệ");
      return;
    } else if (
      !Number.isInteger(itemState.time_sinhtruong) ||
      Number(itemState.time_sinhtruong) < 0
    ) {
      notify(false, "Thời gian sinh trưởng không hợp lệ");
      return;
    } else if (
      !Number.isInteger(itemState.time_choan) ||
      Number(itemState.time_choan) < 0
    ) {
      notify(false, "Thời gian cho ăn không hợp lệ");
      return;
    } else if (
      !Number.isInteger(itemState.time_sansinh) ||
      Number(itemState.time_sansinh) < 0
    ) {
      notify(false, "Thời gian sản sinh không hợp lệ");
      return;
    } else if (
      !Number.isInteger(itemState.max_buy) ||
      Number(itemState.max_buy) < 0
    ) {
      notify(false, "Số lượng mua tối đa không hợp lệ");
      return;
    }
    const newItem = {
      animal_name: itemState.animal_name.trim(),
      note: itemState.note.trim(),
      avatar: itemState.avatar.trim(),
      image_nuoi: itemState.image_nuoi.trim(),
      avatar_sansinh: itemState.avatar_sansinh.trim(),
      sansinh_name: itemState.sansinh_name.trim(),
      status: itemState.status ? true : false,
      is_sell: itemState.is_sell ? true : false,
      xu: Number(itemState.xu),
      sell: Number(itemState.sell),
      sell_full: Number(itemState.sell_full),
      sell_sansinh: Number(itemState.sell_sansinh),
      time_song: Number(itemState.time_song),
      time_sansinh: Number(itemState.time_sansinh),
      time_sinhtruong: Number(itemState.time_sinhtruong),
      time_choan: Number(itemState.time_choan),
      max_buy: Number(itemState.max_buy),
    };
    await editItem(newItem);
  };
  return (
    <div
      className="col-6 add_edit_class"
      style={{
        backgroundColor: "rgb(242, 247, 255)",
        borderRadius: "3px 3px 0px 0px",
        boxShadow:
          "rgb(98, 176, 253) 0px 4px 8px, rgba(0, 0, 0, 0.08) 0px 4px 12px",
        padding: "0px !important",
        display: "block",
      }}
    >
      <div
        className="bg-primary"
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          padding: "15px 20px",
        }}
      >
        <div className="text-white add_book_class_header">
          Thêm Vật Nuôi
        </div>
        <div onClick={() => closeForm(false, false)}>
          <i
            className="far fa-times-circle btn_close_form"
            style={{ color: "white", fontSize: 25 }}
          />
        </div>
      </div>
      <div className="sroll_form style-15">
        <div style={{ padding: 20, display: "flex" }}>
          <div className="col-6">
            <div style={{ marginBottom: 20 }}>
              <label className="form-label">Tên vật nuôi</label>
              <input
                onChange={(e) => handleOnChange(e)}
                className="form-control showordisable"
                name="animal_name"
                defaultValue={itemState.animal_name}
                type="text"
                placeholder="VD: Gà"
              />
            </div>
            <div style={{ marginBottom: 20 }}>
              <label className="form-label">Tên sản sinh</label>
              <input
                onChange={(e) => handleOnChange(e)}
                className="form-control showordisable"
                name="sansinh_name"
                defaultValue={itemState.sansinh_name}
                type="text"
                placeholder="VD: Trứng gà"
              />
            </div>

            <div style={{ marginBottom: 20 }}>
              <label className="form-label">Mua tối đa</label>
              <input
                onChange={(e) => handleOnChange(e)}
                className="form-control"
                name="max_buy"
                type="number"
                defaultValue={itemState.max_buy}
                placeholder="VD: 5"
              />
            </div>

            <div style={{ marginBottom: 20 }}>
              <label className="form-label">Ảnh shop</label>
              <input
                onChange={(e) => handleOnChange(e)}
                className="form-control"
                name="avatar"
                type="text"
                placeholder="VD: farm/vatnuoi/shop/ga.png"
                defaultValue={itemState.avatar}
              />
            </div>
            <div style={{ marginBottom: 20 }}>
              <label className="form-label">Ảnh sản sinh</label>
              <input
                onChange={(e) => handleOnChange(e)}
                className="form-control"
                name="avatar_sansinh"
                type="text"
                placeholder="VD: farm/vatnuoi/trungga.png"
                defaultValue={itemState.avatar_sansinh}
              />
            </div>
            <div style={{ marginBottom: 20 }}>
              <label className="form-label">Ảnh lúc nuôi</label>
              <input
                onChange={(e) => handleOnChange(e)}
                className="form-control"
                name="image_nuoi"
                type="text"
                placeholder="VD: farm/vatnuoi/trungga.png"
                defaultValue={itemState.image_nuoi}
              />
            </div>
            <div style={{ marginBottom: 20 }}>
              <label className="form-label">Trạng thái</label>
              <select
                onChange={(e) => handleOnChange(e)}
                className="form-select"
                name="status"
                value={itemState.status}
                aria-label="Default select example"
              >
                <option value={1}>Đang bán</option>
                <option value={0}>Ngưng bán</option>
              </select>
            </div>

            <div style={{ marginBottom: 20 }}>
              <label className="form-label">Có sản sinh</label>
              <select
                onChange={(e) => handleOnChange(e)}
                className="form-select"
                name="is_sell"
                value={itemState.is_sell}
                aria-label="Default select example"
              >
                <option value={true}>Có sản sinh</option>
                <option value={false}>Không sản sinh</option>
              </select>
            </div>
          </div>
          <div className="col-6">
            <div style={{ marginBottom: 20 }}>
              <label className="form-label">Time sống</label>
              <input
                onChange={(e) => handleOnChange(e)}
                className="form-control"
                name="time_song"
                type="number"
                defaultValue={itemState.time_song}
                placeholder="VD: 1440"
              />
            </div>

            <div style={{ marginBottom: 20 }}>
              <label className="form-label">Time cho ăn</label>
              <input
                onChange={(e) => handleOnChange(e)}
                className="form-control"
                name="time_choan"
                type="number"
                defaultValue={itemState.time_choan}
                placeholder="VD: 1440"
              />
            </div>

            <div style={{ marginBottom: 20 }}>
              <label className="form-label">Time sinh trưởng</label>
              <input
                onChange={(e) => handleOnChange(e)}
                className="form-control"
                name="time_sinhtruong"
                type="number"
                defaultValue={itemState.time_sinhtruong}
                placeholder="VD: 1440"
              />
            </div>

            <div style={{ marginBottom: 20 }}>
              <label className="form-label">Time sản sinh</label>
              <input
                onChange={(e) => handleOnChange(e)}
                className="form-control"
                name="time_sansinh"
                type="number"
                defaultValue={itemState.time_sansinh}
                placeholder="VD: 1440"
              />
            </div>

            <div style={{ marginBottom: 20 }}>
              <label className="form-label">Giá mua</label>
              <input
                onChange={(e) => handleOnChange(e)}
                className="form-control"
                name="xu"
                type="number"
                defaultValue={itemState.xu}
                placeholder="VD: 10"
              />
            </div>
            <div style={{ marginBottom: 20 }}>
              <label className="form-label">Giá bán min</label>
              <input
                onChange={(e) => handleOnChange(e)}
                className="form-control"
                name="sell"
                type="number"
                defaultValue={itemState.sell}
                placeholder="VD: 720"
              />
            </div>

            <div style={{ marginBottom: 20 }}>
              <label className="form-label">Giá bán max</label>
              <input
                onChange={(e) => handleOnChange(e)}
                className="form-control"
                name="sell_full"
                type="number"
                defaultValue={itemState.sell_full}
                placeholder="VD: 360"
              />
            </div>

            <div style={{ marginBottom: 20 }}>
              <label className="form-label">Giá sản sinh</label>
              <input
                onChange={(e) => handleOnChange(e)}
                className="form-control"
                name="sell_sansinh"
                type="number"
                defaultValue={itemState.sell_sansinh}
                placeholder="VD: 360"
              />
            </div>
          </div>
        </div>

        <div className="col-12" style={{ padding: "0px 35px", margin: 0 }}>
          <label className="form-label">Ghi chú</label>
          <textarea
            onChange={(e) => handleOnChange(e)}
            className="form-control style-15"
            name="note"
            type="text"
            rows="3"
            value={itemState.note}
            placeholder="VD: Lúa"
          />
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            margin: 10,
          }}
        >
          <button
            onClick={() => handleEditItem()}
            className="btn btn-success btn_add_edit_customer_submit"
          >
            Xác Nhận
          </button>
        </div>
      </div>
    </div>
  );
}
