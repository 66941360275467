import React, { Fragment, useEffect, useState } from "react";
import axios from "axios";
import "../../../styles/Gift.css";
import { Link, useNavigate } from "react-router-dom";
import ReactPaginate from "react-paginate";
import { ApiLink, convertToDate, notify, Title } from "../../../utils/Title";
import UserLogo from "./UserLogo";
export default function GiftOpen({ username }) {
  let [moQuaHistoryList, setMoQuaHistoryList] = useState([]);
  let [userInfo, setUserInfo] = useState(null);
  let [loading, isLoading] = useState(true);
  let [page, setPage] = useState(1);
  const [isOpened, setIsOpened] = useState(false);
  const maxShow = 10;

  const handlePageClick = (event) => {
    if (page !== event.selected + 1) setPage(event.selected + 1);
  };

  let navigate = useNavigate();

  useEffect(() => {
    document.title = Title.user_exchange + Title.origin;
  }, []);

  useEffect(() => {
    const getUserInfo = async () => {
      const response = await axios.get(`${ApiLink.domain + "/account/info"}`, {
        withCredentials: true,
      });
      if (response.data.status) {
        setUserInfo(response.data.data);
      } else {
        notify(false, response.data.message);
        if (response.data.must === "login") {
          return navigate("/account/login", { replace: true });
        } else {
          return navigate("/", { replace: true });
        }
      }
    };

    const getHistoryMoQua = async () => {
      const response = await axios.post(
        `${ApiLink.domain + "/history/keyword"}`,
        { keyword: "%Mở hộp quà%" },
        {
          withCredentials: true,
        }
      );
      if (response.data.status) {
        setMoQuaHistoryList(response.data.data);
      } else {
        notify(false, response.data.message);
        if (response.data.must === "login") {
          return navigate("/account/login", { replace: true });
        } else {
          return navigate("/", { replace: true });
        }
      }
    };

    const handleAPIAll = async () => {
      await Promise.all([
        Promise.resolve(getUserInfo()),
        Promise.resolve(getHistoryMoQua()),
      ]);
      isLoading(false);
    };
    handleAPIAll();
  }, [navigate]);

  const handleOpenGift = async () => {
    if (userInfo.mo_qua_quanty <= 0) {
      notify(
        false,
        "Bạn đã hết lượt mở quà. Làm nhiệm vụ để nhận thêm lượt nhé"
      );
      return;
    }

    const response = await axios.post(
      `${ApiLink.domain + "/account/mo-qua"}`,
      {},
      {
        withCredentials: true,
      }
    );
    notify(response.data.status, response.data.message);
    if (response.data.status) {
      setIsOpened(true);
      setUserInfo(response.data.user);
      setMoQuaHistoryList(response.data.data);
    }
  };

  return loading ? (
    ""
  ) : (
    <Fragment>
      <div id="main">
        <UserLogo />
        <div className="page-content">
          <section
            className="row"
            style={{
              marginLeft: "-5px",
              marginRight: "-5px",
            }}
          >
            <div className="col-12 col-lg-12">
              <div className="row">
                <div className="col-12">
                  <div
                    className="col-lg-12 stretch-card"
                    style={{ padding: 0 }}
                  >
                    <div className="card">
                      <div className="card-body">
                        <div
                          className="ref_detail"
                          style={{
                            backgroundImage: isOpened
                              ? "url('/images/image/gift_happy.gif')"
                              : "none",
                          }}
                        >
                          <div className="ref_friend">
                            <div>- Số dư nông trại: </div>
                            <span style={{ margin: "0px 2px" }}></span>
                            <div
                              style={{
                                color: "#28a745",
                              }}
                            >
                              {userInfo.vnd.toLocaleString("vi") + " đồng"}
                            </div>
                          </div>
                          <div className="ref_friend">
                            <div>- Tiền mặt: </div>
                            <span style={{ margin: "0px 2px" }}></span>
                            <div
                              style={{
                                color: "#28a745",
                              }}
                            >
                              {userInfo.xu.toLocaleString("vi") + " đồng"}
                            </div>
                          </div>

                          <div className="ref_friend">
                            <div>
                              - Số lần mở quà:{" "}
                              <span
                                style={{
                                  color: "rgb(0, 123, 255)",
                                }}
                              >
                                {userInfo.mo_qua_quanty}
                                {" lượt mở"}
                              </span>
                            </div>
                          </div>

                          <div className="ref_friend">
                            <span>
                              - Phần thưởng:{" "}
                              <span
                                style={{
                                  color: "#007bff",
                                }}
                              >
                                Lên tới 50.000đ
                              </span>
                            </span>
                            <span style={{ margin: "0px 2px" }}></span>
                          </div>

                          <div className="ref_friend">
                            <span>
                              - Nhận thêm lượt mở quà:{" "}
                              <Link
                                to={"/task-everyday"}
                                style={{
                                  color: "#007bff",
                                }}
                              >
                                Làm nhiệm vụ
                              </Link>
                            </span>
                          </div>

                          <div className="ref_friend">
                            <span>
                              - Hướng dẫn:{" "}
                              <span
                                style={{
                                  color: "rgb(220, 53, 69)",
                                }}
                              >
                                Bấm vào hộp quà để mở
                              </span>
                            </span>
                          </div>

                          <div className="gift-container">
                            {!isOpened ? (
                              <div
                                className="gift-box"
                                onClick={handleOpenGift}
                              >
                                <img
                                  src="/images/image/gift_close.gif"
                                  alt="Gift Box"
                                  className="gift-box-image"
                                />
                                <p className="click-to-open">
                                  Nhấn vào hộp quà để mở!
                                </p>
                              </div>
                            ) : (
                              <div className="opened-box">
                                <img
                                  src="/images/image/gift_open.gif"
                                  alt="Confetti"
                                  className="confetti-image"
                                />
                                <h2 className="congratulations-text">
                                  Chúc mừng! 🎁
                                </h2>
                                <p className="reward-text">
                                  Bạn vừa nhận được phần quà đặc biệt!
                                </p>
                              </div>
                            )}
                          </div>

                          {isOpened && (
                            <div
                              className="ref_friend"
                              style={{
                                textAlign: "center",
                                display: "block",
                              }}
                            >
                              <button
                                className="btn btn-success btn_change_status"
                                onClick={() => {
                                  setIsOpened(false);
                                }}
                              >
                                Mở tiếp
                              </button>
                            </div>
                          )}
                        </div>

                        <h4
                          className="card-title"
                          style={{
                            textAlign: "center",
                            marginTop: 20,
                          }}
                        >
                          Lịch sử mở quà
                        </h4>
                        <div className="table-responsive pt-3">
                          <table className="table table-bordered text-center">
                            <thead>
                              <tr>
                                <th>STT</th>
                                <th>Username</th>
                                <th>Thời gian</th>
                                <th>Ghi chú</th>
                                <th>Số dư đầu</th>
                                <th>Số dư cuối</th>
                              </tr>
                            </thead>
                            <tbody>
                              {moQuaHistoryList.map((history, index) => {
                                if (
                                  index >= (page - 1) * maxShow &&
                                  index < page * maxShow
                                ) {
                                  return (
                                    <tr key={index} className="table-white">
                                      <td>{index + 1}</td>
                                      <td>{history.User.username}</td>
                                      <td>
                                        {convertToDate(history.time_history)}
                                      </td>
                                      <td>{history.note}</td>
                                      <td>
                                        {history.xu_start.toLocaleString("vi") +
                                          " đồng"}
                                      </td>
                                      <td>
                                        {history.xu_end.toLocaleString("vi") +
                                          " đồng"}
                                      </td>
                                    </tr>
                                  );
                                } else return "";
                              })}
                            </tbody>
                          </table>
                        </div>
                        <div
                          className="flex_center pagination_mg"
                          style={{
                            marginBottom: 20,
                          }}
                        >
                          <div>Tổng số: {moQuaHistoryList.length}</div>
                          <ReactPaginate
                            breakLabel="..."
                            nextLabel="Next"
                            onPageChange={handlePageClick}
                            pageRangeDisplayed={2}
                            pageCount={
                              moQuaHistoryList.length <= maxShow
                                ? 1
                                : moQuaHistoryList.length % maxShow === 0
                                ? Math.floor(moQuaHistoryList.length / maxShow)
                                : Math.floor(
                                    moQuaHistoryList.length / maxShow
                                  ) + 1
                            }
                            previousLabel="Previous"
                            renderOnZeroPageCount={null}
                            pageClassName="page-item"
                            pageLinkClassName="page-link"
                            previousClassName="page-item"
                            previousLinkClassName="page-link"
                            nextClassName="page-item"
                            nextLinkClassName="page-link"
                            breakClassName="page-item"
                            breakLinkClassName="page-link"
                            containerClassName="pagination"
                            activeClassName="active"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
    </Fragment>
  );
}
