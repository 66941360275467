import React, { createContext, useState, useEffect } from "react";
import { socket } from "../../socket";
import { useLocation } from "react-router-dom";

// Tạo context
export const OnlineUsersContext = createContext();

export const OnlineUsersProvider = ({ children }) => {
  const [usersOnline, setUsersOnline] = useState([]);
  const location = useLocation(); // Dùng useLocation để lấy trang hiện tại

  //   console.log("location", location.pathname);
  //   console.log("location", location);
  //   console.log("userOnlineList", usersOnline);

  useEffect(() => {
    // Gửi sự kiện "user_connected" với thông tin người dùng
    socket.emit("user_connected", location.pathname);

    // Lắng nghe sự kiện "users_online" để cập nhật danh sách người dùng online
    socket.on("users_online", (users) => {
      setUsersOnline(users);
    });

    // Gửi trạng thái trang mỗi khi location thay đổi
    socket.emit("update_page", { path: location.pathname });

    // Cleanup khi component unmount
    return () => {
      socket.off("users_online");
    };
  }, [location]);

  return (
    <OnlineUsersContext.Provider value={{ usersOnline }}>
      {children}
    </OnlineUsersContext.Provider>
  );
};
