import React, { useState } from "react";
import axios from "axios";
import { ApiLink, notify } from "../../../../utils/Title";
import { useNavigate } from "react-router-dom";

export default function FormAddKQXS({ closeForm, loadData }) {
  let navigate = useNavigate();
  let [itemState, setItemState] = useState({
    number_trung_thuong: "",
    note: "",
    ket_qua_date: "",
    is_tra_thuong: 0,
  });

  const handleOnChange = (e) => {
    let { name, value } = e.target;
    if (name === "is_tra_thuong" || name === "number_trung_thuong") {
      value = Number(value);
    }
    setItemState({ ...itemState, [name]: value });
  };

  const editItem = async (newItem) => {
    const response = await axios.post(
      `${ApiLink.domain + "/admin/ket-qua-xo-so/"}`,
      newItem,
      {
        withCredentials: true,
      }
    );
    if (response.data.status) {
      notify(true, response.data.message);
      loadData(response.data.data);
      closeForm(true, false);
    } else {
      notify(false, response.data.message);
      if (response.data.must === "login") {
        return navigate("/", { replace: true });
      } else if (response.data.must === "permission") {
        return navigate("/", { replace: true });
      }
    }
  };

  const handleEditItem = async () => {
    console.log("number_trung_thuong", itemState.number_trung_thuong);

    if (
      itemState.is_tra_thuong !== 1 &&
      itemState.is_tra_thuong !== 0 &&
      itemState.is_tra_thuong !== false &&
      itemState.is_tra_thuong !== true
    ) {
      notify(false, "Vui lòng chọn trạng thái trả thưởng");
      return;
    } else if (
      !Number.isInteger(itemState.number_trung_thuong) ||
      Number(itemState.number_trung_thuong) < 0 ||
      Number(itemState.number_trung_thuong) > 99
    ) {
      notify(false, "Con số trúng thưởng không hợp lệ");
      return;
    }
    const newItem = {
      number_trung_thuong: Number(itemState.number_trung_thuong),
      note: itemState.note.trim(),
      is_tra_thuong: itemState.is_tra_thuong ? true : false,
      ket_qua_date: itemState.ket_qua_date,
    };
    await editItem(newItem);
  };
  return (
    <div
      className="col-6 add_edit_class"
      style={{
        backgroundColor: "rgb(242, 247, 255)",
        borderRadius: "3px 3px 0px 0px",
        boxShadow:
          "rgb(98, 176, 253) 0px 4px 8px, rgba(0, 0, 0, 0.08) 0px 4px 12px",
        padding: "0px !important",
        display: "block",
      }}
    >
      <div
        className="bg-primary"
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          padding: "15px 20px",
        }}
      >
        <div className="text-white add_book_class_header">
          Thêm Kết Quả Xổ Số
        </div>
        <div onClick={() => closeForm(false, false)}>
          <i
            className="far fa-times-circle btn_close_form"
            style={{ color: "white", fontSize: 25 }}
          />
        </div>
      </div>
      <div className="sroll_form style-15">
        <div style={{ padding: 20, display: "flex" }}>
          <div className="col-6">
            <div style={{ marginBottom: 20 }}>
              <label className="form-label">Con số trúng thưởng</label>
              <input
                onChange={(e) => handleOnChange(e)}
                className="form-control showordisable"
                name="number_trung_thuong"
                defaultValue={itemState.number_trung_thuong}
                type="text"
                placeholder="VD: 99"
              />
            </div>

            <div style={{ marginBottom: 20 }}>
              <label className="form-label">Trạng thái</label>
              <select
                onChange={(e) => handleOnChange(e)}
                className="form-select"
                name="is_tra_thuong"
                value={itemState.is_tra_thuong}
                aria-label="Default select example"
              >
                <option value={1}>Đã trả thưởng</option>
                <option value={0}>Chưa trả thưởng</option>
              </select>
            </div>
          </div>
          <div className="col-6">
            <div style={{ marginBottom: 20 }}>
              <label className="form-label">Ghi chú</label>
              <input
                onChange={(e) => handleOnChange(e)}
                className="form-control"
                name="note"
                type="text"
                placeholder="VD: Kết quả XSMB"
                defaultValue={itemState.note}
              />
            </div>
            <div style={{ marginBottom: 20 }}>
              <label className="form-label">Kết quả ngày</label>
              <input
                onChange={(e) => handleOnChange(e)}
                className="form-control"
                name="ket_qua_date"
                type="date"
                defaultValue={itemState.ket_qua_date}
                placeholder="VD: 20/10/2025"
              />
            </div>
          </div>
        </div>

        <div
          style={{
            display: "flex",
            justifyContent: "center",
            margin: 10,
          }}
        >
          <button
            onClick={() => handleEditItem()}
            className="btn btn-success btn_add_edit_customer_submit"
          >
            Xác Nhận
          </button>
        </div>
      </div>
    </div>
  );
}
