import React, { Fragment, useEffect, useState } from "react";
import "../../styles/App.css";
import axios from "axios";
import { ApiLink, notify, Title } from "../../utils/Title";
import { useNavigate } from "react-router-dom";
import UserBoardLayout from "../../components/Layouts/BasicLayout/UserBoardLayout";
import NotifyLayout from "../../components/Layouts/BasicLayout/NotifyLayout";
import MultiplexAd from "../../components/AdSenseAd/MultiplexAd";
import AdSenseAd from "../../components/AdSenseAd/AdSenseAd";
import Footer from "../../components/Layouts/BasicLayout/Footer";
export default function TaskEverydayPage() {
  const [show, setShow] = useState(-1);
  const [loading, isLoading] = useState(true);
  const [info, setInfo] = useState(null);
  let [taskEverydayList, setTaskEverydayList] = useState([]);
  let navigate = useNavigate();
  let [username, setUsername] = useState("");
  let [showNotify, setShowNotify] = useState(false);

  useEffect(() => {
    document.title = Title.task_everyday + Title.origin;
  }, []);

  const handleSetShowNotify = () => {
    setShowNotify(!showNotify);
  };

  useEffect(() => {
    async function getAllTaskEveryday() {
      const response = await axios.get(`${ApiLink.domain + "/task-everyday"}`, {
        withCredentials: true,
      });
      if (response.data.status) {
        setTaskEverydayList(response.data.data); // status, data
      } else {
        if (response.data.must === "login") {
          window.localStorage.clear();
          notify(false, response.data.message);
          return navigate("/account/login", { replace: true });
        } else if (response.data.must === "permission") {
          notify(false, response.data.message);
          return navigate("/", { replace: true });
        } else {
          return navigate("/404", { replace: true });
        }
      }
    }

    async function getUserInfo() {
      const response = await axios.get(`${ApiLink.domain + "/account/info"}`, {
        withCredentials: true,
      });
      if (response.data.status) {
        setUsername(response.data.data.username); // status, data
        setInfo(response.data.data);
      } else {
        // notify(false, response.data.message);
        if (response.data.must === "login") {
          window.localStorage.clear();
          return navigate("/account/login", { replace: true });
        }
      }
    }

    const getAll = async () => {
      Promise.all([
        Promise.resolve(getUserInfo()),
        Promise.resolve(getAllTaskEveryday()),
      ]);
      isLoading(false);
    };
    getAll();
  }, [navigate]);

  async function handleNhanThuong(task_id) {
    const response = await axios.post(
      `${ApiLink.domain + "/task-everyday/" + task_id}`,
      {},
      {
        withCredentials: true,
      }
    );
    notify(response.data.status, response.data.message);
    if (response.data.status) {
      setTaskEverydayList(response.data.data); // status, data
      setInfo(response.data.user);
    } else {
      if (response.data.must === "login") {
        window.localStorage.clear();
        return navigate("/account/login", { replace: true });
      } else if (response.data.must === "permission") {
        return navigate("/", { replace: true });
      }
    }
  }

  const checkHoanThanh = (item) => {
    const { task_code_quanty, thu_hoach_quanty, cau_ca_quanty } = item;
    if (
      task_code_quanty <= info.task_code_quanty_task &&
      thu_hoach_quanty <= info.thu_hoach_quanty_task &&
      cau_ca_quanty <= info.cau_ca_quanty_task
    ) {
      return true;
    }

    return false;
  };

  const handleViewDetail = (id) => {
    id === show ? setShow(-1) : setShow(id);
  };

  return loading ? (
    <div></div>
  ) : (
    <div className="app_container">
      <AdSenseAd />
      <UserBoardLayout
        Element={null}
        info={info}
        setInfo={setInfo}
        username={username}
        setUsername={setUsername}
        handleShowNotify={handleSetShowNotify}
        showNotify={showNotify}
      />
      {showNotify && <NotifyLayout />}
      <div className="list_app">
        <div className="list_name">Nhiệm vụ hàng ngày</div>
        {taskEverydayList.map((task, index) => {
          if (task.item.task_status === 2) {
            return <Fragment></Fragment>;
          } else {
            return (
              <Fragment key={index}>
                <div
                  className="one_app"
                  style={{
                    marginTop: index === 0 ? "0px" : "10px",
                  }}
                >
                  <div className="one_app--top">
                    <img
                      className="one_app--top_left"
                      src="/images/image/daily-task.png"
                      alt="image_app"
                    ></img>
                    <div className="one_app--top_right">
                      <div className="app_title">
                        <img
                          className="app_title_icon"
                          alt="app_title_icon"
                          src="https://kiemthecaofree.com/hot.gif"
                        ></img>
                        <span className="app_title_name">
                          {`#${index + 1} ${task.item?.task_name}`}
                        </span>
                      </div>
                      <div className="app_price">
                        <span className="one_app_child_title">
                          Tiền thưởng:
                        </span>
                        <span className="one_app_child_content">
                          {task.item?.task_prize_text}
                        </span>
                      </div>
                      <div className="app_price">
                        <span className="one_app_child_title">Yêu cầu:</span>
                        <span className="one_app_child_content">
                          {task.item?.note}
                        </span>
                      </div>

                      {task.item?.thu_hoach_quanty ? (
                        <div className="app_price">
                          <span className="one_app_child_title">
                            Số cây đã thu hoạch:
                          </span>
                          <span className="one_app_child_content">
                            {info.thu_hoach_quanty_task +
                              "/" +
                              task.item?.thu_hoach_quanty}
                          </span>
                        </div>
                      ) : (
                        ""
                      )}

                      {task.item?.cau_ca_quanty ? (
                        <div className="app_price">
                          <span className="one_app_child_title">
                            Số cá đã câu được:
                          </span>
                          <span className="one_app_child_content">
                            {info.cau_ca_quanty_task +
                              "/" +
                              task.item?.cau_ca_quanty}
                          </span>
                        </div>
                      ) : (
                        ""
                      )}

                      {task.item.task_code_quanty ? (
                        <div className="app_price">
                          <span className="one_app_child_title">
                            Số code đã nhập:
                          </span>
                          <span className="one_app_child_content">
                            {info.task_code_quanty_task +
                              "/" +
                              task.item?.task_code_quanty}
                          </span>
                        </div>
                      ) : (
                        ""
                      )}

                      <div className="app_price">
                        <span className="one_app_child_title">Trạng thái:</span>
                        <span className="one_app_child_content">
                          {task.check ? (
                            <span
                              style={{
                                color: "#28a745",
                                fontWeight: 700,
                              }}
                            >
                              Hoàn thành
                            </span>
                          ) : !task.check && !checkHoanThanh(task.item) ? (
                            <span
                              style={{
                                color: "#007bff",
                                fontWeight: 700,
                              }}
                            >
                              Đang làm
                            </span>
                          ) : (
                            <div
                              onClick={() => {
                                handleNhanThuong(task.item.task_id);
                              }}
                              className="app_get_code"
                              style={{
                                cursor: "pointer",
                                color: "#fff",
                                backgroundColor: "#007bff",
                              }}
                            >
                              Nhận thưởng
                            </div>
                          )}
                        </span>
                      </div>

                      <div
                        className="app_download_error"
                        style={{
                          backgroundColor: "#28a745",
                          color: "#fff",
                        }}
                      >
                        Sau khi hoàn thành nhiệm vụ, bạn bấm nút nhận thưởng để
                        nhận quà.
                      </div>
                    </div>
                  </div>
                  <div className="one_app--bottom">
                    <div className="one_app--bottom-top">
                      <p
                        className="one_app--bottom_step_title"
                        style={{ color: "#17a2b8" }}
                      >
                        Các bước thực hiện:
                      </p>
                      <div
                        className="one_app--bottom_step"
                        onClick={() => {
                          handleViewDetail(task.item?.task_id);
                        }}
                      >
                        <img
                          className="icon_down"
                          alt="icon_down"
                          src={
                            show === task.item?.task_id
                              ? "/images/icons/up-arrow.png"
                              : "/images/icons/down-arrow.png"
                          }
                        ></img>
                        <p className="one_app--bottom_step_title">
                          {show === task.item?.task_id
                            ? "Tắt hướng dẫn"
                            : "Xem hướng dẫn"}
                        </p>
                      </div>
                    </div>
                    <div
                      className="one_app--bottom-bottom"
                      style={{
                        display: show === task.item?.task_id ? "block" : "none",
                      }}
                    >
                      {
                        <div
                          className="content"
                          style={{
                            marginTop: "10px",
                          }}
                          dangerouslySetInnerHTML={{
                            __html: task.item?.task_description,
                          }}
                        ></div>
                      }

                      {task.item.task_history_id && (
                        <div
                          className="app_note"
                          style={{
                            display: "flex",
                            alignItems: "center",
                          }}
                        >
                          <span className="app_note--title">Lấy code:</span>
                          <div
                            className="app_get_code"
                            style={{
                              cursor: "pointer",
                            }}
                          >
                            <a
                              target="_blank"
                              rel="noreferrer"
                              href={task.item.short_url || task.item.url}
                              style={{ color: "#fff" }}
                            >
                              Lấy code
                            </a>
                          </div>
                        </div>
                      )}

                      <div className="app_note">
                        <p className="app_note--title">Lưu ý:</p>
                        <div className="app_note--content">
                          Nhiệm vụ sẽ được làm mới sau 24h mỗi ngày nên các
                          thông số tích lũy được sẽ reset về 0.
                        </div>
                      </div>

                      <div className="app_video">
                        <p className="app_video--title">Video hướng dẫn:</p>
                        <p style={{ textAlign: "center" }}>Chưa có video...</p>
                      </div>
                    </div>
                  </div>
                </div>
                {index === taskEverydayList.length - 1 ? (
                  <div className="end_list">Đã tới cuối danh sách</div>
                ) : (
                  ""
                )}
              </Fragment>
            );
          }
        })}
      </div>
      <MultiplexAd />
      <Footer margin_top={"15px"} />
    </div>
  );
}
