import React, { useEffect, useState } from "react";
import axios from "axios";
import { ApiLink, convertToTimeConLai, notify } from "../../../../utils/Title";
import { useNavigate } from "react-router-dom";

export default function FormFishStock({ closeFormAdd, loadData }) {
  let [fishStockList, setFishStockList] = useState([]);
  let [userShopList, setUserShopList] = useState([]);
  let [stockScreen, isStockScreen] = useState(true);
  let navigate = useNavigate();

  useEffect(() => {
    async function getAllFishStock() {
      const response = await axios.get(
        `${ApiLink.domain + "/user-fish-stock"}`,
        {
          withCredentials: true,
        }
      );
      if (response.data.status) {
        setFishStockList(response.data.data);
      } else {
        notify(false, response.data.message);
        if (response.data.must === "login") {
          return navigate("/account/login", { replace: true });
        } else if (response.data.must === "permission") {
          return;
        }
      }
    }

    async function getAllUserShop() {
      const response = await axios.get(`${ApiLink.domain + "/user-shop"}`, {
        withCredentials: true,
      });
      if (response.data.status) {
        setUserShopList(response.data.data);
      } else {
        notify(false, response.data.message);
        if (response.data.must === "login") {
          return navigate("/account/login", { replace: true });
        } else if (response.data.must === "permission") {
          return;
        }
      }
    }

    const getAll = async () => {
      await Promise.all([
        Promise.resolve(getAllFishStock()),
        Promise.resolve(getAllUserShop()),
      ]);
    };

    getAll();
  }, [navigate]);

  const sellFishStock = async (fish_id) => {
    const response = await axios.post(
      `${ApiLink.domain + "/user-fish-stock/" + fish_id}`,
      {},
      {
        withCredentials: true,
      }
    );
    if (response.data.status) {
      notify(true, response.data.message);
      loadData(response.data);
      closeFormAdd();
    } else {
      notify(false, response.data.message);
      if (response.data.must === "login") {
        return navigate("/account/login", { replace: true });
      } else if (response.data.must === "permission") {
        return;
      }
    }
  };

  const handleSellFishStock = async (fish_id) => {
    if (!fish_id) {
      notify(false, "Vui lòng chọn cá cần bán");
      return;
    }
    await sellFishStock(fish_id);
  };

  return (
    <div
      className="row col-9 add_edit_class"
      style={{
        display: "block",
        backgroundColor: "rgb(242, 247, 255)",
        borderRadius: "3px 3px 0px 0px",
        boxShadow:
          "rgb(98, 176, 253) 0px 4px 8px, rgba(0, 0, 0, 0.08) 0px 4px 12px",
        padding: "0px !important",
        margin: 0,
      }}
    >
      <div
        className="bg-primary row"
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          padding: "15px 20px",
          margin: 0,
        }}
      >
        <div className="text-white add_book_class_header">
          <span
            onClick={() => isStockScreen(true)}
            style={
              stockScreen
                ? {
                    backgroundColor: "#007bff",
                    padding: "5px 10px",
                    borderRadius: "5px",
                    cursor: "pointer",
                  }
                : {
                    cursor: "pointer",
                  }
            }
          >
            Kho cá
          </span>
          <span
            style={{
              padding: "0px 8px",
            }}
          >
            |
          </span>
          <span
            onClick={() => isStockScreen(false)}
            style={
              !stockScreen
                ? {
                    backgroundColor: "#007bff",
                    padding: "5px 10px",
                    borderRadius: "5px",
                    cursor: "pointer",
                  }
                : {
                    cursor: "pointer",
                  }
            }
          >
            Kho vật phẩm
          </span>
        </div>
        <div onClick={() => closeFormAdd()}>
          <i
            className="far fa-times-circle btn_close_form"
            style={{ color: "white", fontSize: 25 }}
          />
        </div>
      </div>

      <div
        className="row col-12 sroll_form style-15 mobile_min_width_100 mobile_no_wrap"
        style={{
          padding: "0px !important",
          display: "flex",
          flexDirection: "row",
          margin: 0,
        }}
      >
        <div
          className="row col-12 mobile_min_width_100"
          style={{
            paddingLeft: 20,
            paddingRight: 20,
            paddingTop: 20,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <div className="shop_list">
            {stockScreen &&
              (fishStockList.length > 0 ? (
                fishStockList.map((fish_stock, index) => {
                  return (
                    <div key={index} className="one_farm_seed">
                      <div className="one_farm_seed__top">
                        <img
                          id="raucu"
                          src={"/images" + fish_stock.Fish.avatar}
                          alt="rau_cu"
                          style={{
                            height: "12px",
                            marginRight: "6px",
                          }}
                        />
                        <div>
                          <b>{"[ " + fish_stock.Fish.fish_name + " ]"}</b>
                          <div className="one_farm_seed__price">
                            Giá bán: <b>{fish_stock.Fish.xu_sell}</b> xu / kg
                          </div>
                        </div>
                      </div>{" "}
                      <div className="one_farm_seed__mid">
                        <div>
                          Cân nặng: {"[ " + fish_stock.fish_weight + " Kg ]"}
                        </div>
                        <div>
                          Số lượng: {"[ " + fish_stock.quanty + " con ]"}
                        </div>
                        <div>
                          Thành tiền:{" "}
                          {"[ " +
                            Number(fish_stock.fish_weight) *
                              Number(fish_stock.Fish.xu_sell) +
                            " đồng ]"}
                        </div>
                      </div>
                      <div className="one_farm_seed__bottom">
                        <div
                          style={{
                            marginRight: "10px",
                          }}
                        >
                          Bán luôn
                        </div>
                        <div
                          className="one_farm_seed__buy"
                          onClick={() => {
                            handleSellFishStock(fish_stock.fish_id);
                          }}
                        >
                          Bán hết
                        </div>
                      </div>
                    </div>
                  );
                })
              ) : (
                <div
                  style={{
                    margin: "20px 0px",
                  }}
                >
                  Kho cá hiện trống
                </div>
              ))}

            {!stockScreen &&
              (userShopList.length > 0 ? (
                userShopList.map((user_shop, index) => {
                  return (
                    <div key={index} className="one_farm_seed">
                      <div className="one_farm_seed__top">
                        <img
                          id="raucu"
                          src={"/images" + user_shop.ShopItem.avatar}
                          alt="rau_cu"
                          style={{
                            height: "16px",
                            marginRight: "8px",
                          }}
                        />
                        <div>
                          <b>
                            {"[ " + user_shop.ShopItem.shop_item_name + " ]"}
                          </b>
                          <div className="one_farm_seed__price">
                            Mô tả: {user_shop.ShopItem.note}
                          </div>
                          <div className="one_farm_seed__price">
                            Thời hạn:{" "}
                            {new Date(user_shop.time_expire).getFullYear() >
                            2090
                              ? "Vĩnh viễn"
                              : convertToTimeConLai(user_shop.time_expire)}
                          </div>
                        </div>
                      </div>{" "}
                      <div className="one_farm_seed__mid">
                        <div>Số lượng: {"[ " + user_shop.quanty + " ]"}</div>
                      </div>
                    </div>
                  );
                })
              ) : (
                <div
                  style={{
                    margin: "20px 0px",
                  }}
                >
                  Kho vật phẩm hiện trống
                </div>
              ))}
          </div>
        </div>
      </div>
      <div
        style={{ display: "flex", justifyContent: "center", marginBottom: 10 }}
      ></div>
    </div>
  );
}
