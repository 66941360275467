import React, { useEffect, useState } from "react";
import axios from "axios";
import ReactPaginate from "react-paginate";
import SearchTheme from "./SearchTheme";
import { useNavigate } from "react-router-dom";
import { ApiLink, notify } from "../../../utils/Title";
import AdminLogo from "./AdminLogo";
import FormEditTaskEveryday from "./Form/FormEditTaskEveryday";
import FormAddTaskEveryday from "./Form/FormAddTaskEveryday";

export default function TaskEverydayAdmin({ username }) {
  let [itemList, setItemList] = useState([]);
  let [page, setPage] = useState(1);
  const maxShow = 10;
  let navigate = useNavigate();
  let [itemEdit, setItemEdit] = useState(false);
  let [itemAdd, setItemAdd] = useState(false);
  let [keyword, setKeyword] = useState("");

  const handleShowFormEdit = (item) => {
    setItemEdit(item);
  };

  const handleShowFormAdd = () => {
    setItemAdd(true);
  };

  const handleCloseFormEdit = async (edit_submit, result) => {
    if (result & !edit_submit) {
      await reloadData();
    }
    setItemEdit(false);
  };

  useEffect(() => {
    async function getAllTask() {
      const response = await axios.get(
        `${ApiLink.domain + "/admin/task-everyday"}`,
        {
          withCredentials: true,
        }
      );
      if (response.data.status) {
        setItemList(response.data.data); // status, data
      } else {
        notify(false, response.data.message);
        if (response.data.must === "login") {
          window.localStorage.clear();
          return navigate("/", { replace: true });
        } else if (response.data.must === "permission") {
          return navigate("/", { replace: true });
        }
      }
    }
    getAllTask();
  }, [navigate]);

  const handlePageClick = (event) => {
    if (page !== event.selected + 1) setPage(event.selected + 1);
  };

  const loadData = (data) => {
    setItemList(data);
  };

  const reloadData = async () => {
    const response = await axios.get(
      `${ApiLink.domain + "/admin/farm-animal"}`,
      {
        withCredentials: true,
      }
    );
    if (response.data.status) {
      setItemList(response.data.data);
    } else {
      notify(false, response.data.message);
      if (response.data.must === "login") {
        window.localStorage.clear();
        return navigate("/", { replace: true });
      } else if (response.data.must === "permission") {
        return navigate("/", { replace: true });
      }
    }
  };

  const handleCloseFormAdd = async (add_edit, result) => {
    if (result & !add_edit) {
      // reload_data
      await reloadData();
    }
    setItemAdd(false);
  };

  const handleSearch = (keyword) => {
    setKeyword(keyword.toLowerCase().trim());
  };

  const filterSearch = () => {
    return itemList.filter((item) => {
      return (
        item.task_name.toLowerCase().includes(keyword) ||
        item.note.toLowerCase().includes(keyword) ||
        item.task_description.toLowerCase().includes(keyword) ||
        item.task_prize_text.toLowerCase().includes(keyword) ||
        item.avatar.toLowerCase().includes(keyword) ||
        Number(item.xu) === Number(keyword) ||
        Number(item.mo_qua_quanty) === Number(keyword) ||
        Number(item.cau_ca_quanty) === Number(keyword) ||
        Number(item.task_code_quanty) === Number(keyword) ||
        Number(item.vong_quay_quanty) === Number(keyword) ||
        Number(item.thu_hoach_quanty) === Number(keyword) ||
        Number(item.vong_quay_quanty) === Number(keyword) ||
        String(item.status).toLowerCase().includes(keyword) ||
        Number(item.task_id) === Number(keyword)
      );
    });
  };

  return (
    <div id="main">
      {" "}
      <AdminLogo />
      {itemAdd || itemEdit ? (
        <div
          className="background_black"
          id="background_black"
          style={{ display: "block" }}
        >
          {itemEdit ? (
            <FormEditTaskEveryday
              itemChoose={itemEdit}
              closeForm={handleCloseFormEdit}
              loadData={loadData}
            />
          ) : (
            ""
          )}

          {itemAdd ? (
            <FormAddTaskEveryday
              closeForm={handleCloseFormAdd}
              loadData={loadData}
            />
          ) : (
            ""
          )}
        </div>
      ) : (
        ""
      )}
      <div className="page-content">
        <section
          className="row"
          style={{
            marginLeft: "-5px",
            marginRight: "-5px",
          }}
        >
          <div className="col-12 col-lg-12">
            {/* <AdminThemeTop username={username} /> */}
            <SearchTheme
              title={"Tìm kiếm nhiệm vụ"}
              handleSearch={handleSearch}
            />
            <div className="row">
              <div className="col-12">
                <div className="col-lg-12 stretch-card" style={{ padding: 0 }}>
                  <div className="card">
                    <div className="card-body">
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "space-between",
                        }}
                      >
                        <h4
                          className="card-title"
                          style={{ marginBottom: "0 !important" }}
                        >
                          Danh sách nhiệm vụ
                        </h4>

                        <div style={{ display: "flex" }}>
                          <button
                            id="info"
                            className="btn btn-success btn_add"
                            onClick={() => handleShowFormAdd()}
                          >
                            <i className="fas fa-plus" />{" "}
                            <span>Thêm nhiệm vụ</span>
                          </button>
                        </div>
                      </div>
                      <div className="table-responsive pt-3">
                        <table className="table table-bordered text-center">
                          <thead>
                            <tr>
                              <th style={{ whiteSpace: "nowrap" }}>ID</th>
                              <th style={{ whiteSpace: "nowrap" }}>
                                Tên nhiệm vụ
                              </th>

                              <th style={{ whiteSpace: "nowrap" }}>
                                Phần thưởng
                              </th>
                              <th style={{ whiteSpace: "nowrap" }}>Mô tả</th>
                              <th style={{ whiteSpace: "nowrap" }}>
                                Thưởng xu
                              </th>
                              <th style={{ whiteSpace: "nowrap" }}>
                                Thưởng vòng quay
                              </th>
                              <th style={{ whiteSpace: "nowrap" }}>
                                Thưởng mở quà
                              </th>
                              <th style={{ whiteSpace: "nowrap" }}>
                                Số cây thu hoạch
                              </th>
                              <th style={{ whiteSpace: "nowrap" }}>
                                Số cá câu được
                              </th>
                              <th style={{ whiteSpace: "nowrap" }}>
                                Số code đã nhập
                              </th>
                              <th style={{ whiteSpace: "nowrap" }}>Ghi chú</th>
                              <th style={{ whiteSpace: "nowrap" }}>Ảnh</th>
                              <th style={{ whiteSpace: "nowrap" }}>
                                Trạng thái
                              </th>
                              <th style={{ whiteSpace: "nowrap" }}>Cập nhật</th>
                            </tr>
                          </thead>
                          <tbody>
                            {filterSearch().map((item, index) => {
                              if (
                                index >= (page - 1) * maxShow &&
                                index < page * maxShow
                              ) {
                                return (
                                  <tr key={index} className="table-white">
                                    <td>{item.task_id}</td>
                                    <td style={{ whiteSpace: "nowrap" }}>
                                      {item.task_name}
                                    </td>
                                    <td style={{ whiteSpace: "nowrap" }}>
                                      {item.task_prize_text}
                                    </td>
                                    <td style={{ whiteSpace: "nowrap" }}>
                                      {item.task_description.slice(0, 40)}
                                    </td>
                                    <td>
                                      {Number(item.xu).toLocaleString("vi")}
                                    </td>
                                    <td>{Number(item.vong_quay_quanty)}</td>
                                    <td>{Number(item.mo_qua_quanty)}</td>
                                    <td>{Number(item.thu_hoach_quanty)}</td>
                                    <td>{item.cau_ca_quanty}</td>
                                    <td>{item.task_code_quanty}</td>
                                    <td style={{ whiteSpace: "nowrap" }}>
                                      {item.note}
                                    </td>
                                    <td>
                                      <img
                                        style={{
                                          width: "30px",
                                        }}
                                        src={"/images" + item.avatar}
                                        alt="item"
                                      />
                                    </td>
                                    {item.status === 1 ? (
                                      <td
                                        style={{
                                          color: "#28a745",
                                          fontWeight: 700,
                                          whiteSpace: "nowrap",
                                        }}
                                      >
                                        Đang mở
                                      </td>
                                    ) : (
                                      <td
                                        style={{
                                          color: "#dc3545",
                                          fontWeight: 700,
                                          whiteSpace: "nowrap",
                                        }}
                                      >
                                        Đã đóng
                                      </td>
                                    )}
                                    <td>
                                      <button
                                        onClick={() => handleShowFormEdit(item)}
                                        type="button"
                                        className="btn btn-warning m-1 btn_edit"
                                      >
                                        <i className="far fa-edit" />
                                      </button>
                                    </td>
                                  </tr>
                                );
                              } else return "";
                            })}
                          </tbody>
                        </table>
                      </div>
                      <div className="flex_center pagination_mg">
                        <div>Tổng số: {filterSearch().length}</div>
                        <ReactPaginate
                          breakLabel="..."
                          nextLabel="Next"
                          onPageChange={handlePageClick}
                          pageRangeDisplayed={2}
                          pageCount={
                            filterSearch().length <= maxShow
                              ? 1
                              : filterSearch().length % maxShow === 0
                              ? Math.floor(filterSearch().length / maxShow)
                              : Math.floor(filterSearch().length / maxShow) + 1
                          }
                          previousLabel="Previous"
                          renderOnZeroPageCount={null}
                          pageClassName="page-item"
                          pageLinkClassName="page-link"
                          previousClassName="page-item"
                          previousLinkClassName="page-link"
                          nextClassName="page-item"
                          nextLinkClassName="page-link"
                          breakClassName="page-item"
                          breakLinkClassName="page-link"
                          containerClassName="pagination"
                          activeClassName="active"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  );
}
