import React, { useState } from "react";
import axios from "axios";
import { ApiLink, notify } from "../../../../utils/Title";
import { useNavigate } from "react-router-dom";

export default function FormEditTaskEveryday({
  itemChoose,
  closeForm,
  loadData,
}) {
  let navigate = useNavigate();
  let [itemState, setItemState] = useState({
    task_name: itemChoose.task_name,
    xu: itemChoose.xu,
    note: itemChoose.note,
    mo_qua_quanty: itemChoose.mo_qua_quanty,
    vong_quay_quanty: itemChoose.vong_quay_quanty,
    thu_hoach_quanty: itemChoose.thu_hoach_quanty,
    cau_ca_quanty: itemChoose.cau_ca_quanty,
    task_code_quanty: itemChoose.task_code_quanty,
    task_description: itemChoose.task_description,
    task_prize_text: itemChoose.task_prize_text,
    avatar: itemChoose.avatar,
    status: itemChoose.status,
  });

  const handleOnChange = (e) => {
    let { name, value } = e.target;
    if (
      name === "mo_qua_quanty" ||
      name === "vong_quay_quanty" ||
      name === "thu_hoach_quanty" ||
      name === "cau_ca_quanty" ||
      name === "task_code_quanty" ||
      name === "xu" ||
      name === "status"
    ) {
      value = Number(value);
    }
    setItemState({ ...itemState, [name]: value });
  };

  const editItem = async (newItem) => {
    const response = await axios.put(
      `${ApiLink.domain + "/admin/task-everyday/" + itemChoose.task_id}`,
      newItem,
      {
        withCredentials: true,
      }
    );
    if (response.data.status) {
      notify(true, response.data.message);
      loadData(response.data.data);
      closeForm(true, true);
    } else {
      notify(false, response.data.message);
      if (response.data.must === "login") {
        return navigate("/", { replace: true });
      } else if (response.data.must === "permission") {
        return navigate("/", { replace: true });
      }
    }
  };

  const handleEditItem = async () => {
    if (itemState.task_name.trim().length < 2) {
      notify(false, "Tên nhiệm vụ cần >= 2 ký tự");
      return;
    }
    if (itemState.avatar.trim().length < 1) {
      notify(false, "Chưa có hình ảnh nhiệm vụ");
      return;
    }
    if (
      itemState.status !== 1 &&
      itemState.status !== 0 &&
      itemState.status !== false &&
      itemState.status !== true
    ) {
      notify(false, "Vui lòng chọn trạng thái nhiệm vụ");
      return;
    }
    if (!Number.isInteger(itemState.xu) || Number(itemState.xu) < 0) {
      notify(false, "Số xu thưởng không hợp lệ");
      return;
    } else if (
      !Number.isInteger(itemState.mo_qua_quanty) ||
      Number(itemState.mo_qua_quanty) < 0
    ) {
      notify(false, "Phần thưởng mở quà không hợp lệ");
      return;
    } else if (
      !Number.isInteger(itemState.vong_quay_quanty) ||
      Number(itemState.vong_quay_quanty) < 0
    ) {
      notify(false, "Phần thưởng vòng quay không hợp lệ");
      return;
    } else if (
      !Number.isInteger(itemState.thu_hoach_quanty) ||
      Number(itemState.thu_hoach_quanty) < 0
    ) {
      notify(false, "Số cây thu hoạch không hợp lệ");
      return;
    } else if (
      !Number.isInteger(itemState.cau_ca_quanty) ||
      Number(itemState.cau_ca_quanty) < 0
    ) {
      notify(false, "Số cá câu được không hợp lệ");
      return;
    } else if (
      !Number.isInteger(itemState.task_code_quanty) ||
      Number(itemState.task_code_quanty) < 0
    ) {
      notify(false, "Số lần nhập code không hợp lệ");
      return;
    }
    const newItem = {
      task_name: itemState.task_name.trim(),
      note: itemState.note.trim(),
      avatar: itemState.avatar.trim(),
      task_description: itemState.task_description.trim(),
      task_prize_text: itemState.task_prize_text.trim(),
      status: itemState.status ? true : false,
      xu: Number(itemState.xu),
      task_code_quanty: Number(itemState.task_code_quanty),
      mo_qua_quanty: Number(itemState.mo_qua_quanty),
      cau_ca_quanty: Number(itemState.cau_ca_quanty),
      thu_hoach_quanty: Number(itemState.thu_hoach_quanty),
      vong_quay_quanty: Number(itemState.vong_quay_quanty),
    };
    await editItem(newItem);
  };
  return (
    <div
      className="col-6 add_edit_class"
      style={{
        backgroundColor: "rgb(242, 247, 255)",
        borderRadius: "3px 3px 0px 0px",
        boxShadow:
          "rgb(98, 176, 253) 0px 4px 8px, rgba(0, 0, 0, 0.08) 0px 4px 12px",
        padding: "0px !important",
        display: "block",
      }}
    >
      <div
        className="bg-primary"
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          padding: "15px 20px",
        }}
      >
        <div className="text-white add_book_class_header">
          Cập Nhật Thông Tin Nhiệm Vụ
        </div>
        <div onClick={() => closeForm(false, false)}>
          <i
            className="far fa-times-circle btn_close_form"
            style={{ color: "white", fontSize: 25 }}
          />
        </div>
      </div>
      <div className="sroll_form style-15">
        <div style={{ padding: 20, display: "flex" }}>
          <div className="col-6">
            <div style={{ marginBottom: 20 }}>
              <label className="form-label">Tên nhiệm vụ</label>
              <input
                onChange={(e) => handleOnChange(e)}
                className="form-control showordisable"
                name="task_name"
                defaultValue={itemState.task_name}
                type="text"
                placeholder="VD: Gà"
              />
            </div>

            <div style={{ marginBottom: 20 }}>
              <label className="form-label">Phần thưởng</label>
              <input
                onChange={(e) => handleOnChange(e)}
                className="form-control"
                name="task_prize_text"
                type="text"
                placeholder="VD: 100đ và 1 lượt mở quà"
                defaultValue={itemState.task_prize_text}
              />
            </div>
            <div style={{ marginBottom: 20 }}>
              <label className="form-label">Phần thưởng xu</label>
              <input
                onChange={(e) => handleOnChange(e)}
                className="form-control"
                name="xu"
                type="number"
                defaultValue={itemState.xu}
                placeholder="VD: 10"
              />
            </div>
            <div style={{ marginBottom: 20 }}>
              <label className="form-label">Trạng thái</label>
              <select
                onChange={(e) => handleOnChange(e)}
                className="form-select"
                name="status"
                value={itemState.status}
                aria-label="Default select example"
              >
                <option value={1}>Đang mở</option>
                <option value={0}>Đã đóng</option>
              </select>
            </div>

            <div style={{ marginBottom: 20 }}>
              <label className="form-label">Hình ảnh</label>
              <input
                onChange={(e) => handleOnChange(e)}
                className="form-control"
                name="avatar"
                type="text"
                placeholder="VD: /image/daily-task.png"
                defaultValue={itemState.avatar}
              />
            </div>
          </div>
          <div className="col-6">
            <div style={{ marginBottom: 20 }}>
              <label className="form-label">Số cây thu hoạch</label>
              <input
                onChange={(e) => handleOnChange(e)}
                className="form-control"
                name="thu_hoach_quanty"
                type="number"
                defaultValue={itemState.thu_hoach_quanty}
                placeholder="VD: 1440"
              />
            </div>

            <div style={{ marginBottom: 20 }}>
              <label className="form-label">Số cá câu được</label>
              <input
                onChange={(e) => handleOnChange(e)}
                className="form-control"
                name="cau_ca_quanty"
                type="number"
                defaultValue={itemState.cau_ca_quanty}
                placeholder="VD: 1440"
              />
            </div>

            <div style={{ marginBottom: 20 }}>
              <label className="form-label">Số code đã nhập</label>
              <input
                onChange={(e) => handleOnChange(e)}
                className="form-control"
                name="task_code_quanty"
                type="number"
                defaultValue={itemState.task_code_quanty}
                placeholder="VD: 360"
              />
            </div>

            <div style={{ marginBottom: 20 }}>
              <label className="form-label">Phần thưởng mở quà</label>
              <input
                onChange={(e) => handleOnChange(e)}
                className="form-control"
                name="mo_qua_quanty"
                type="number"
                defaultValue={itemState.mo_qua_quanty}
                placeholder="VD: 1440"
              />
            </div>

            <div style={{ marginBottom: 20 }}>
              <label className="form-label">Phần thưởng vòng quay</label>
              <input
                onChange={(e) => handleOnChange(e)}
                className="form-control"
                name="vong_quay_quanty"
                type="number"
                defaultValue={itemState.vong_quay_quanty}
                placeholder="VD: 1440"
              />
            </div>
          </div>
        </div>

        <div className="col-12" style={{ padding: "0px 35px", margin: 0 }}>
          <label className="form-label">Mô tả</label>
          <textarea
            onChange={(e) => handleOnChange(e)}
            className="form-control style-15"
            name="task_description"
            type="text"
            rows="3"
            value={itemState.task_description}
            placeholder="VD: Hoàn thành nhiệm vụ"
          />
        </div>
        <div className="col-12" style={{ padding: "0px 35px", margin: "10px 0px" }}>
          <label className="form-label">Ghi chú</label>
          <textarea
            onChange={(e) => handleOnChange(e)}
            className="form-control style-15"
            name="note"
            type="text"
            rows="3"
            value={itemState.note}
            placeholder="VD: Nhiệm vụ"
          />
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            margin: 10,
          }}
        >
          <button
            onClick={() => handleEditItem()}
            className="btn btn-success btn_add_edit_customer_submit"
          >
            Xác Nhận
          </button>
        </div>
      </div>
    </div>
  );
}
