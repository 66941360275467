import React, { Fragment } from "react";
import { Link } from "react-router-dom";
import { ADMIN_CODE, CUSTOMER_CODE } from "../../../utils/Title";
import UserHeaderLeft from "./UserHeaderLeft";
export default function AccountLeft({
  active_id,
  role,
  showSidebar,
  handeleSetShowSideBar,
}) {
  console.log("ROLE", role);

  return (
    <Fragment>
      <div
        onClick={() => {
          handeleSetShowSideBar(false);
        }}
        className={showSidebar ? "background_on" : "background_off"}
      ></div>
      <div
        id="sidebar"
        className={
          showSidebar
            ? "sidebar-wrapper sidebar_user_active"
            : "sidebar-wrapper sidebar_user_none_active"
        }
      >
        <div>
          <div className="menu_side">
            <UserHeaderLeft />
            <div className="sidebar-menu">
              <ul className="menu">
                <li className="sidebar-title">Danh Mục Khách Hàng</li>
                {(role === CUSTOMER_CODE || role === ADMIN_CODE) && (
                  <Fragment>
                    <li
                      className={`sidebar-item ${
                        active_id === 1 ? "active" : ""
                      } `}
                    >
                      <Link to="/account/info" className="sidebar-link">
                        <i className="fas fa-user" />
                        <span>Thông tin tài khoản</span>
                      </Link>
                    </li>
                    <li
                      className={`sidebar-item ${
                        active_id === 2 ? "active" : ""
                      } `}
                    >
                      <Link to="/account/history" className="sidebar-link">
                        <i className="fas fa-address-book" />
                        <span>Lịch sử nhận thưởng</span>
                      </Link>
                    </li>
                    <li
                      className={`sidebar-item ${
                        active_id === 6 ? "active" : ""
                      } `}
                    >
                      <Link to="/account/invite" className="sidebar-link">
                        <i className="fas fa-user-friends" />
                        <span>Giới thiệu bạn bè</span>
                      </Link>
                    </li>
                    <li
                      className={`sidebar-item ${
                        active_id === 3 ? "active" : ""
                      } `}
                    >
                      <Link to="/account/pay-history" className="sidebar-link">
                        <i className="fas fa-bullseye" />
                        <span>Lịch sử rút tiền</span>
                      </Link>
                    </li>
                    <li
                      className={`sidebar-item ${
                        active_id === 4 ? "active" : ""
                      } `}
                    >
                      <Link to="/account/pay-info" className="sidebar-link">
                        <i className="fas fa-utensils" />
                        <span>Thông tin thanh toán</span>
                      </Link>
                    </li>
                    <li
                      className={`sidebar-item ${
                        active_id === 5 ? "active" : ""
                      } `}
                    >
                      <Link to="/account/withdraw" className="sidebar-link">
                        <i className="fas fa-bars" />
                        <span>Rút tiền</span>
                      </Link>
                    </li>
                    <li
                      className={`sidebar-item ${
                        active_id === 7 ? "active" : ""
                      } `}
                    >
                      <Link to="/account/exchange" className="sidebar-link">
                        <i className="fas fa-exchange-alt" />
                        <span>Quy đổi</span>
                      </Link>
                    </li>
                    <li
                      className={`sidebar-item ${
                        active_id === 8 ? "active" : ""
                      } `}
                    >
                      <Link
                        to="/account/ket-qua-xo-so"
                        className="sidebar-link"
                      >
                        <i className="fas fa-sort-numeric-up-alt" />
                        <span>Dự đoán KQXS</span>
                      </Link>
                    </li>
                    <li
                      className={`sidebar-item ${
                        active_id === 9 ? "active" : ""
                      } `}
                    >
                      <Link to="/account/open-gift" className="sidebar-link">
                        <i className="fas fa-gift" />
                        <span>Mở quà</span>
                      </Link>
                    </li>

                    <li
                      className={`sidebar-item ${
                        active_id === 10 ? "active" : ""
                      } `}
                    >
                      <Link to="/account/fish-history" className="sidebar-link">
                        <i className="fas fa-fish" />
                        <span>Lịch sử câu cá</span>
                      </Link>
                    </li>
                  </Fragment>
                )}
              </ul>
            </div>
            <button className="sidebar-toggler btn x">
              <i data-feather="x" />
            </button>
          </div>
        </div>
      </div>
    </Fragment>
  );
}
