import React, { useEffect } from "react";

const MultiplexAd = () => {
  useEffect(() => {
    // Tải mã quảng cáo của AdSense
    const script = document.createElement("script");
    script.async = true;
    script.src =
      "https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-4213601784097143";
    document.head.appendChild(script);

    script.onload = () => {
      (window.adsbygoogle = window.adsbygoogle || []).push({});
    };

    return () => {
      // Loại bỏ script khi component unmount
      document.head.removeChild(script);
    };
  }, []);

  return (
    <div>
      <ins
        className="adsbygoogle"
        style={{ display: "block" }}
        data-ad-client="ca-pub-4213601784097143" // Thay ca-pub-XXXXXXX bằng ID tài khoản AdSense của bạn
        data-ad-slot="4789665009" // Thay XXXXXX bằng slot ID quảng cáo của bạn
        data-ad-format="autorelaxed"
      ></ins>
    </div>
  );
};

export default MultiplexAd;
