import React, { useEffect, useState } from "react";
import axios from "axios";
import { ApiLink, notify } from "../../../utils/Title";
import { useNavigate } from "react-router-dom";
import ReactPaginate from "react-paginate";
import SearchTheme from "./SearchTheme";
import FormConfirm from "./Form/FormConfirm";
import AdminLogo from "./AdminLogo";

export default function StatusAppAdmin({ username }) {
  let navigate = useNavigate();
  let [addStatus, setAddStatus] = useState(false);
  let [editStatus, setEditStatus] = useState(false);
  let [deleteStatus, setDeleteStatus] = useState(false);
  let [statusAppAdd, setStatusAppAdd] = useState({
    status_code: "",
    status_name: "",
  });
  let [statusAppEdit, setStatusAppEdit] = useState({
    status_code: "",
    status_name: "",
  });
  let [itemEdit, setItemEdit] = useState(false);
  let [itemDelete, setItemDelete] = useState(false);
  let [statusAppList, setStatusAppList] = useState([]);
  let [page, setPage] = useState(1);
  let [keyword, setKeyword] = useState("");
  const maxShow = 10;

  const handleClickAdd = () => {
    setAddStatus(!addStatus);
  };

  const handleClickEdit = (item) => {
    console.log(editStatus);
    if (item.status_id !== itemEdit.status_id) {
      setEditStatus(true);
      setItemEdit(item);
      setStatusAppEdit({
        status_code: item.status_code,
        status_name: item.status_name,
      });
    } else if (editStatus) {
      setEditStatus(!editStatus);
      setItemEdit(false);
      setStatusAppEdit({
        status_code: "",
        status_name: "",
      });
    } else {
      setEditStatus(!editStatus);
      setItemEdit(item);
      setStatusAppEdit({
        status_code: item.status_code,
        status_name: item.status_name,
      });
    }
  };

  console.log(statusAppEdit);

  const handleEditSubmit = async () => {
    const statusApp_edit = {
      status_name: statusAppEdit.status_name.trim(),
      status_code: statusAppEdit.status_code.trim(),
    };
    const response = await axios.put(
      `${ApiLink.domain + "/admin/status-app/" + itemEdit.status_id}`,
      statusApp_edit,
      {
        withCredentials: true,
      }
    );
    if (response.data.status) {
      notify(true, response.data.message);
      setStatusAppList(response.data.data);
      setItemEdit(false);
      setStatusAppEdit("");
    } else {
      notify(false, response.data.message);
      if (response.data.must === "login") {
        window.localStorage.clear();
        return navigate("/", { replace: true });
      } else if (response.data.must === "permission") {
        return navigate("/", { replace: true });
      }
    }
  };

  const handleAddSubmit = async () => {
    const statusApp = {
      status_name: statusAppAdd.status_name.trim(),
      status_code: statusAppAdd.status_code.trim(),
    };
    const response = await axios.post(
      `${ApiLink.domain + "/admin/status-app"}`,
      statusApp,
      {
        withCredentials: true,
      }
    );
    if (response.data.status) {
      notify(true, response.data.message);
      setStatusAppList(response.data.data);
      setAddStatus(false);
      setStatusAppAdd({
        status_code: "",
        status_name: "",
      });
    } else {
      notify(false, response.data.message);
      if (response.data.must === "login") {
        window.localStorage.clear();
        return navigate("/", { replace: true });
      } else if (response.data.must === "permission") {
        return navigate("/", { replace: true });
      }
    }
  };

  const closeFormConfirm = () => {
    setDeleteStatus(false);
    setItemDelete(false);
  };

  const handleChangeNameAdd = (e) => {
    const { value, name } = e.target;
    setStatusAppAdd({ ...statusAppAdd, [name]: value });
  };

  const handleChangeNameEdit = (e) => {
    const { value, name } = e.target;
    setStatusAppEdit({ ...statusAppEdit, [name]: value });
  };

  const handleSearch = (keyword) => {
    setKeyword(keyword.toLowerCase().trim());
  };

  const filterSearch = () => {
    return statusAppList.filter((item) => {
      return (
        item.status_name.toLowerCase().includes(keyword) ||
        item.status_code.toLowerCase().includes(keyword) ||
        Number(item.status_id) === Number(keyword)
      );
    });
  };

  useEffect(() => {
    async function getAllCategory() {
      const response = await axios.get(
        `${ApiLink.domain + "/admin/status-app"}`,
        {
          withCredentials: true,
        }
      );
      if (response.data.status) {
        setStatusAppList(response.data.data); // status, data
      } else {
        notify(false, response.data.message);
        if (response.data.must === "login") {
          window.localStorage.clear();
          return navigate("/", { replace: true });
        } else if (response.data.must === "permission") {
          return navigate("/", { replace: true });
        }
      }
    }
    getAllCategory();
  }, [navigate]);

  const handleShowFormDelete = (item) => {
    setDeleteStatus(true);
    setItemDelete(item);
  };

  const loadData = async (data) => {
    setStatusAppList(data); // status, data
  };

  const handlePageClick = (event) => {
    if (page !== event.selected + 1) setPage(event.selected + 1);
  };
  return (
    <div id="main">
      {" "}
      <AdminLogo />
      {deleteStatus ? (
        <div
          className="background_black"
          id="background_black"
          style={{ display: "block" }}
        >
          <FormConfirm
            status={"admin_delete_statusApp"}
            content={
              "Bạn chắc chắn muốn xóa trạng thái " +
              itemDelete.status_name +
              " với ID = "
            }
            id_handle={itemDelete.status_id}
            closeFormConfirm={closeFormConfirm}
            loadData={loadData}
          />
        </div>
      ) : (
        ""
      )}
      <div
        className="page-content"
        style={{
          marginLeft: "-5px",
          marginRight: "-5px",
        }}
      >
        <section className="row">
          <div className="col-12 col-lg-12">
            {/* <AdminThemeTop username={username} /> */}
            <SearchTheme
              title={"Tìm kiếm trạng thái ứng dụng"}
              handleSearch={handleSearch}
            />
            <div className="row">
              <div className="col-12">
                <div className="col-lg-12 stretch-card" style={{ padding: 0 }}>
                  <div className="card">
                    <div className="card-body">
                      <div
                        className="mobile_action_top_controll"
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "space-between",
                        }}
                      >
                        <h4
                          className="card-title mobile_mg5_0"
                          style={{ marginBottom: "0 !important" }}
                        >
                          Danh sách trạng thái
                        </h4>

                        <div
                          className="mobile_action_top_controll"
                          style={{ display: "flex" }}
                        >
                          <button
                            id="info"
                            className="btn btn-success btn_add mobile_mg5_0"
                            onClick={() => handleClickAdd()}
                          >
                            <i className="fas fa-plus" />{" "}
                            <span>Thêm Trạng Thái</span>
                          </button>
                          {addStatus ? (
                            <div
                              className="form_add"
                              style={{ marginLeft: 20 }}
                            >
                              <div
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                }}
                              >
                                <input
                                  onChange={(e) => handleChangeNameAdd(e)}
                                  className="form-control"
                                  name="status_code"
                                  type="text"
                                  defaultValue={statusAppAdd.status_code}
                                  placeholder="VD: ACTIVE"
                                />
                                <input
                                  style={{ marginLeft: "5px" }}
                                  onChange={(e) => handleChangeNameAdd(e)}
                                  className="form-control"
                                  name="status_name"
                                  type="text"
                                  defaultValue={statusAppAdd.status_name}
                                  placeholder="VD: Đang diễn ra"
                                />
                                <button
                                  onClick={() => handleAddSubmit()}
                                  id="btn_add_submit"
                                  style={{ marginLeft: 5 }}
                                  type="submit"
                                  className="btn btn-success btn_add_submit"
                                >
                                  Thêm
                                </button>
                              </div>
                            </div>
                          ) : (
                            ""
                          )}
                        </div>
                      </div>
                      <div className="table-responsive pt-3">
                        <table className="table table-bordered text-center">
                          <thead>
                            <tr>
                              <th style={{ whiteSpace: "nowrap" }}>
                                ID Trạng Thái
                              </th>
                              <th style={{ whiteSpace: "nowrap" }}>
                                Mã trạng thái
                              </th>
                              <th style={{ whiteSpace: "nowrap" }}>
                                Tên trạng thái
                              </th>
                              <th style={{ whiteSpace: "nowrap" }}>Cập nhật</th>
                            </tr>
                          </thead>
                          <tbody>
                            {filterSearch().map((statusApp, index) => {
                              if (
                                index >= (page - 1) * maxShow &&
                                index < page * maxShow
                              ) {
                                return (
                                  <tr key={index} className="table-white">
                                    <td>{statusApp.status_id}</td>

                                    <td>
                                      {itemEdit &&
                                      itemEdit.status_id ===
                                        statusApp.status_id ? (
                                        <div className="flex_center">
                                          <input
                                            onChange={(e) =>
                                              handleChangeNameEdit(e)
                                            }
                                            className="form-control mobile_min_width_100px"
                                            name="status_code"
                                            type="text"
                                            defaultValue={itemEdit.status_code}
                                            placeholder="VD: ACTIVE"
                                          />
                                        </div>
                                      ) : statusApp.status_code === "ACTIVE" ? (
                                        <span
                                          style={{
                                            color: "#28a745",
                                            fontWeight: 700,
                                          }}
                                        >
                                          {statusApp.status_code}
                                        </span>
                                      ) : statusApp.status_code === "STOP" ? (
                                        <span
                                          style={{
                                            color: "#dc3545",
                                            fontWeight: 700,
                                          }}
                                        >
                                          {statusApp.status_code}
                                        </span>
                                      ) : (
                                        <span
                                          style={{
                                            color: "#dc3545",
                                            fontWeight: 700,
                                          }}
                                        >
                                          {statusApp.status_code}
                                        </span>
                                      )}
                                    </td>

                                    <td>
                                      {itemEdit &&
                                      itemEdit.status_id ===
                                        statusApp.status_id ? (
                                        <div className="flex_center">
                                          <input
                                            onChange={(e) =>
                                              handleChangeNameEdit(e)
                                            }
                                            className="form-control mobile_min_width_100px"
                                            name="status_name"
                                            type="text"
                                            defaultValue={itemEdit.status_name}
                                            placeholder="VD: Đang diễn ra"
                                          />{" "}
                                          <button
                                            onClick={() => handleEditSubmit()}
                                            className="btn btn-success btn_add"
                                            style={{ margin: "0px 10px" }}
                                          >
                                            Lưu
                                          </button>
                                        </div>
                                      ) : (
                                        <span>{statusApp.status_name}</span>
                                      )}
                                    </td>
                                    <td>
                                      <button
                                        onClick={() =>
                                          handleClickEdit(statusApp)
                                        }
                                        type="button"
                                        className="btn btn-warning m-1 btn_edit"
                                      >
                                        <i className="far fa-edit" />
                                      </button>
                                      <button
                                        onClick={() =>
                                          handleShowFormDelete(statusApp)
                                        }
                                        type="button"
                                        className="btn btn-danger btn_delete"
                                      >
                                        <i
                                          style={{ color: "white" }}
                                          className="fa fa-trash-alt"
                                        />
                                      </button>
                                    </td>
                                  </tr>
                                );
                              } else {
                                return ``;
                              }
                            })}
                          </tbody>
                        </table>
                      </div>
                      <div className="flex_center pagination_mg">
                        <div>Tổng số: {filterSearch().length}</div>
                        <ReactPaginate
                          breakLabel="..."
                          nextLabel="Next"
                          onPageChange={handlePageClick}
                          pageRangeDisplayed={2}
                          pageCount={
                            filterSearch().length <= maxShow
                              ? 1
                              : filterSearch().length % maxShow === 0
                              ? Math.floor(filterSearch().length / maxShow)
                              : Math.floor(filterSearch().length / maxShow) + 1
                          }
                          previousLabel="Previous"
                          renderOnZeroPageCount={null}
                          pageClassName="page-item"
                          pageLinkClassName="page-link"
                          previousClassName="page-item"
                          previousLinkClassName="page-link"
                          nextClassName="page-item"
                          nextLinkClassName="page-link"
                          breakClassName="page-item"
                          breakLinkClassName="page-link"
                          containerClassName="pagination"
                          activeClassName="active"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  );
}
