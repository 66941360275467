import React, { useEffect, useState } from "react";
import axios from "axios";
import { ApiLink, notify } from "../../../../utils/Title";
import { useNavigate } from "react-router-dom";

export default function FormAddPayDetail({ closeForm, loadData }) {
  let navigate = useNavigate();
  let [payMethodList, setPayMethodList] = useState([]);
  let [itemState, setItemState] = useState({
    name: "",
    xu: "",
    pay_method_id: 0,
  });

  useEffect(() => {
    async function getAllPayMethod() {
      const response = await axios.get(
        `${ApiLink.domain + "/admin/pay-method"}`,
        {
          withCredentials: true,
        }
      );
      if (response.data.status) {
        setPayMethodList(response.data.data); // status, data
      } else {
        notify(false, response.data.message);
        if (response.data.must === "login") {
          window.localStorage.clear();
          return navigate("/", { replace: true });
        } else if (response.data.must === "permission") {
          return navigate("/", { replace: true });
        }
      }
    }
    getAllPayMethod();
  }, [navigate]);

  const handleOnChange = (e) => {
    let { name, value } = e.target;
    if (name === "xu" || name === "pay_method_id") {
      value = Number(value);
    }
    setItemState({ ...itemState, [name]: value });
  };

  const editItem = async (newItem) => {
    const response = await axios.post(
      `${ApiLink.domain + "/admin/pay-detail/"}`,
      newItem,
      {
        withCredentials: true,
      }
    );
    if (response.data.status) {
      notify(true, response.data.message);
      loadData(response.data.data);
      closeForm(true, false);
    } else {
      notify(false, response.data.message);
      if (response.data.must === "login") {
        return navigate("/", { replace: true });
      } else if (response.data.must === "permission") {
        return navigate("/", { replace: true });
      }
    }
  };

  const handleEditItem = async () => {
    if (itemState.name.trim().length < 2) {
      notify(false, "Tên quà quy đổi cần >= 2 ký tự");
      return;
    }
    if (!Number.isInteger(itemState.xu) || Number(itemState.xu) < 0) {
      notify(false, "Số tiền quy đổi không hợp lệ");
      return;
    }
    const newItem = {
      name: itemState.name.trim(),
      xu: Number(itemState.xu),
      pay_method_id: Number(itemState.pay_method_id),
    };
    await editItem(newItem);
  };
  return (
    <div
      className="col-6 add_edit_class"
      style={{
        backgroundColor: "rgb(242, 247, 255)",
        borderRadius: "3px 3px 0px 0px",
        boxShadow:
          "rgb(98, 176, 253) 0px 4px 8px, rgba(0, 0, 0, 0.08) 0px 4px 12px",
        padding: "0px !important",
        display: "block",
      }}
    >
      <div
        className="bg-primary"
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          padding: "15px 20px",
        }}
      >
        <div className="text-white add_book_class_header">Thêm Quà Quy Đổi</div>
        <div onClick={() => closeForm(false, false)}>
          <i
            className="far fa-times-circle btn_close_form"
            style={{ color: "white", fontSize: 25 }}
          />
        </div>
      </div>
      <div className="sroll_form style-15">
        <div style={{ padding: 20, display: "flex" }}>
          <div className="col-6">
            <div style={{ marginBottom: 20 }}>
              <label className="form-label">Tên quà quy đổi</label>
              <input
                onChange={(e) => handleOnChange(e)}
                className="form-control showordisable"
                name="name"
                defaultValue={itemState.name}
                type="text"
                placeholder="VD: Thẻ cào Viettel 10K"
              />
            </div>
          </div>

          <div className="col-6">
            <div style={{ marginBottom: 20 }}>
              <label className="form-label">Mệnh giá</label>
              <input
                onChange={(e) => handleOnChange(e)}
                className="form-control"
                name="xu"
                type="number"
                defaultValue={itemState.xu}
                placeholder="VD: 10000"
              />
            </div>
          </div>
        </div>

        <div className="col-12" style={{ padding: "0px 35px", margin: 0 }}>
          <label className="form-label">Tên phương thức</label>
          <select
            onChange={(e) => handleOnChange(e)}
            className="form-select"
            name="pay_method_id"
            value={itemState.pay_method_id}
            aria-label="Default select example"
          >
            {payMethodList.map((item, index) => (
              <option key={index} value={item.pay_method_id}>
                {item.pay_method_name}
              </option>
            ))}
          </select>
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            margin: 10,
          }}
        >
          <button
            onClick={() => handleEditItem()}
            className="btn btn-success btn_add_edit_customer_submit"
          >
            Xác Nhận
          </button>
        </div>
      </div>
    </div>
  );
}
