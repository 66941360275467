import React, { useState } from "react";
import axios from "axios";
import { ApiLink, convertToDate, notify } from "../../../../utils/Title";
import { useNavigate } from "react-router-dom";

export default function FormEditPayHistory({
  itemChoose,
  closeForm,
  loadData,
}) {
  let navigate = useNavigate();
  let [payHistoryState, setPayHistoryState] = useState({
    pay_history_id: itemChoose.pay_history_id,
    detail: itemChoose.detail,
    note: itemChoose.note,
    pay_history_status: Number(itemChoose.pay_history_status),
    createdAt: itemChoose.createdAt,
    updatedAt: itemChoose.updatedAt,
    user_id: Number(itemChoose.user_id),
    pay_detail_id: Number(itemChoose.pay_detail_id),
    PayDetail: {
      pay_detail_id: Number(itemChoose.PayDetail.pay_detail_id),
      name: itemChoose.PayDetail.name,
      xu: Number(itemChoose.PayDetail.xu),
      pay_method_id: Number(itemChoose.PayDetail.pay_method_id),
    },
    User: {
      user_id: Number(itemChoose.User.user_id),
      username: itemChoose.User.username,
    },
  });

  const handleOnChange = (e) => {
    let { name, value } = e.target;
    if (name === "pay_history_status") {
      value = Number(value);
    }
    setPayHistoryState({ ...payHistoryState, [name]: value });
  };

  const editPayHistory = async (newPayHistory) => {
    const response = await axios.put(
      `${ApiLink.domain + "/admin/pay-history/" + itemChoose.pay_history_id}`,
      newPayHistory,
      {
        withCredentials: true,
      }
    );
    if (response.data.status) {
      notify(true, response.data.message);
      loadData(response.data.data);
      closeForm(true, true);
    } else {
      notify(false, response.data.message);
      if (response.data.must === "login") {
        return navigate("/", { replace: true });
      } else if (response.data.must === "permission") {
        return navigate("/", { replace: true });
      }
    }
  };

  const handleEditPayHistory = async () => {
    // user_id, detail, pay_detail_id, note, pay_history_status
    console.log("payHistoryState", payHistoryState);
    if (
      !Number.isInteger(Number(payHistoryState.pay_history_status)) ||
      Number(payHistoryState.pay_history_status) > 3 ||
      payHistoryState.pay_history_status < 1
    ) {
      notify(false, "Vui lòng chọn trạng thái");
      return;
    }
    const newPayHistory = {
      user_id: payHistoryState.User.user_id,
      detail: payHistoryState.detail.trim(),
      pay_detail_id: payHistoryState.pay_detail_id,
      note: payHistoryState.note.trim(),
      pay_history_status: Number(payHistoryState.pay_history_status),
    };
    await editPayHistory(newPayHistory);
  };
  return (
    <div
      className="col-6 add_edit_class"
      style={{
        backgroundColor: "rgb(242, 247, 255)",
        borderRadius: "3px 3px 0px 0px",
        boxShadow:
          "rgb(98, 176, 253) 0px 4px 8px, rgba(0, 0, 0, 0.08) 0px 4px 12px",
        padding: "0px !important",
        display: "block",
      }}
    >
      <div
        className="bg-primary"
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          padding: "15px 20px",
        }}
      >
        <div className="text-white add_book_class_header">
          Cập Nhật Thông Tin Đổi Thưởng
        </div>
        <div onClick={() => closeForm(false, false)}>
          <i
            className="far fa-times-circle btn_close_form"
            style={{ color: "white", fontSize: 25 }}
          />
        </div>
      </div>
      <div className="sroll_form style-15">
        <div style={{ padding: 20, display: "flex" }}>
          <div className="col-6">
            <div style={{ marginBottom: 20 }}>
              <label className="form-label">Username</label>
              <input
                className="form-control"
                disabled
                defaultValue={payHistoryState.User.username}
                type="text"
                placeholder="VD: Username"
              />
            </div>

            <div style={{ marginBottom: 20 }}>
              <label className="form-label">Phần Thưởng</label>
              <input
                className="form-control showordisable"
                disabled
                defaultValue={payHistoryState.PayDetail.name}
                type="text"
                placeholder="VD: Tên Phần Thưởng"
              />
            </div>

            <div style={{ marginBottom: 20 }}>
              <label className="form-label">Trạng thái</label>
              <select
                onChange={(e) => handleOnChange(e)}
                className="form-select"
                name="pay_history_status"
                value={payHistoryState.pay_history_status}
                aria-label="Default select example"
                disabled={
                  Number(payHistoryState.pay_history_status) === 1
                    ? false
                    : true
                }
              >
                <option value={1}>Chờ phê duyệt</option>
                <option value={2}>Thành công</option>
                <option value={3}>Từ chối</option>
              </select>
            </div>
          </div>
          <div className="col-6">
            <div style={{ marginBottom: 20 }}>
              <label className="form-label">Thời gian</label>
              <input
                className="form-control"
                name="createdAt"
                type="text"
                disabled
                defaultValue={convertToDate(payHistoryState.createdAt)}
                placeholder="VD: 10/10/2025"
              />
            </div>
            <div style={{ marginBottom: 20 }}>
              <label className="form-label">Mệnh giá</label>
              <input
                className="form-control"
                name="lastname"
                type="text"
                disabled
                defaultValue={
                  payHistoryState.PayDetail.xu.toLocaleString("vi") + "đ"
                }
                placeholder="VD: Thẻ cào Viettel 10K"
              />
            </div>

            <div style={{ marginBottom: 20 }}>
              <label className="form-label">Ghi chú</label>
              <input
                onChange={(e) => handleOnChange(e)}
                className="form-control"
                name="note"
                type="text"
                defaultValue={payHistoryState.note}
                placeholder="VD: Thẻ cào hoặc từ chối"
              />
            </div>
          </div>
        </div>

        <div className="col-12" style={{ padding: "0px 35px", margin: 0 }}>
          <label className="form-label">Chi tiết</label>
          <textarea
            onChange={(e) => handleOnChange(e)}
            className="form-control style-15"
            name="detail"
            type="text"
            rows="3"
            disabled
            value={payHistoryState.detail}
            placeholder="VD: Thẻ cào Viettel 10K"
          />
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            margin: 10,
          }}
        >
          <button
            onClick={() => handleEditPayHistory()}
            className="btn btn-success btn_add_edit_customer_submit"
          >
            Xác Nhận
          </button>
        </div>
      </div>
    </div>
  );
}
