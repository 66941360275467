import React from "react";
import { convertToDate } from "../../../../utils/Title";
export default function FormShowPayHistory({
  itemChoose,
  closeForm,
}) {

  return (
    <div
      className="col-6 add_edit_class"
      style={{
        backgroundColor: "rgb(242, 247, 255)",
        borderRadius: "3px 3px 0px 0px",
        boxShadow:
          "rgb(98, 176, 253) 0px 4px 8px, rgba(0, 0, 0, 0.08) 0px 4px 12px",
        padding: "0px !important",
        display: "block",
      }}
    >
      <div
        className="bg-primary"
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          padding: "15px 20px",
        }}
      >
        <div className="text-white add_book_class_header">
          Chi Tiết Thông Tin Đổi Thưởng
        </div>
        <div onClick={() => closeForm(false, false)}>
          <i
            className="far fa-times-circle btn_close_form"
            style={{ color: "white", fontSize: 25 }}
          />
        </div>
      </div>
      <div className="sroll_form style-15">
        <div style={{ padding: 20, display: "flex" }}>
          <div className="col-6">
            <div style={{ marginBottom: 20 }}>
              <label className="form-label">Username</label>
              <input
                className="form-control"
                disabled
                defaultValue={itemChoose.User.username}
                type="text"
                placeholder="VD: Username"
              />
            </div>

            <div style={{ marginBottom: 20 }}>
              <label className="form-label">Phần Thưởng</label>
              <input
                className="form-control showordisable"
                disabled
                defaultValue={itemChoose.PayDetail.name}
                type="text"
                placeholder="VD: Tên Phần Thưởng"
              />
            </div>

            <div style={{ marginBottom: 20 }}>
              <label className="form-label">Trạng thái</label>
              <select
                className="form-select"
                name="pay_history_status"
                disabled
                value={itemChoose.pay_history_status}
                aria-label="Default select example"
              >
                <option value={1}>Chờ phê duyệt</option>
                <option value={2}>Thành công</option>
                <option value={3}>Từ chối</option>
              </select>
            </div>
          </div>
          <div className="col-6">
            <div style={{ marginBottom: 20 }}>
              <label className="form-label">Thời gian</label>
              <input
                className="form-control"
                name="createdAt"
                type="text"
                disabled
                defaultValue={convertToDate(itemChoose.createdAt)}
                placeholder="VD: 10/10/2025"
              />
            </div>
            <div style={{ marginBottom: 20 }}>
              <label className="form-label">Mệnh giá</label>
              <input
                className="form-control"
                name="lastname"
                type="text"
                disabled
                defaultValue={
                  itemChoose.PayDetail.xu.toLocaleString("vi") + "đ"
                }
                placeholder="VD: Thẻ cào Viettel 10K"
              />
            </div>

            <div style={{ marginBottom: 20 }}>
              <label className="form-label">Ghi chú</label>
              <input
                className="form-control"
                name="note"
                type="text"
                disabled
                defaultValue={itemChoose.note}
                placeholder="VD: Thẻ cào hoặc từ chối"
              />
            </div>
          </div>
        </div>

        <div className="col-12" style={{ padding: "0px 35px", margin: 0 }}>
          <label className="form-label">Chi tiết</label>
          <textarea
            className="form-control style-15"
            name="detail"
            type="text"
            rows="3"
            disabled
            value={itemChoose.detail}
            placeholder="VD: Thẻ cào Viettel 10K"
          />
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            margin: 10,
          }}
        >
          <button
            onClick={() => closeForm(false, false)}
            className="btn btn-success btn_add_edit_customer_submit"
          >
            Đóng
          </button>
        </div>
      </div>
    </div>
  );
}
