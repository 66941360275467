import React, { useEffect, useState } from "react";
import axios from "axios";
import ReactPaginate from "react-paginate";
import SearchTheme from "./SearchTheme";
import { useNavigate } from "react-router-dom";
import { ApiLink, convertToDate, notify } from "../../../utils/Title";
import AdminLogo from "./AdminLogo";
import FormTaskHistoryDetail from "./Form/FormTaskHistoryDetail";

export default function TaskHistoryAdmin({ username }) {
  let [taskHistoryList, setTaskHistoryList] = useState([]);
  let [page, setPage] = useState(1);
  const maxShow = 10;
  let navigate = useNavigate();
  let [itemDetail, setItemDetail] = useState(false);
  let [keyword, setKeyword] = useState("");

  const handleShowFormDetail = (history) => {
    setItemDetail(history);
  };

  const closeFormDetail = () => {
    setItemDetail(false);
  };

  useEffect(() => {
    async function getAllTaskHistory() {
      const response = await axios.get(
        `${ApiLink.domain + "/admin/task-history"}`,
        {
          withCredentials: true,
        }
      );
      if (response.data.status) {
        setTaskHistoryList(response.data.data); // status, data
      } else {
        notify(false, response.data.message);
        if (response.data.must === "login") {
          window.localStorage.clear();
          return navigate("/", { replace: true });
        } else if (response.data.must === "permission") {
          return navigate("/", { replace: true });
        }
      }
    }
    getAllTaskHistory();
  }, [navigate]);

  const handlePageClick = (event) => {
    if (page !== event.selected + 1) setPage(event.selected + 1);
  };

  const handleSearch = (keyword) => {
    setKeyword(keyword.toLowerCase().trim());
  };

  const filterSearch = () => {
    return taskHistoryList.filter((item) => {
      return (
        item.task_history_id.toLowerCase().includes(keyword) ||
        item.device_name.toLowerCase().includes(keyword) ||
        item.device_browser.toLowerCase().includes(keyword) ||
        item.device_ip.toLowerCase().includes(keyword) ||
        item.User.username.toLowerCase().includes(keyword) ||
        item.TaskLink.task_name.toLowerCase().includes(keyword)
      );
    });
  };

  return (
    <div id="main">
      {" "}
      <AdminLogo />
      {itemDetail ? (
        <div
          className="background_black"
          id="background_black"
          style={{ display: "block" }}
        >
          {itemDetail ? (
            <FormTaskHistoryDetail
              itemChoose={itemDetail}
              closeForm={closeFormDetail}
            />
          ) : (
            ""
          )}
        </div>
      ) : (
        ""
      )}
      <div className="page-content">
        <section
          className="row"
          style={{
            marginLeft: "-5px",
            marginRight: "-5px",
          }}
        >
          <div className="col-12 col-lg-12">
            {/* <AdminThemeTop username={username} /> */}
            <SearchTheme
              title={"Tìm kiếm lịch sử task"}
              handleSearch={handleSearch}
            />
            <div className="row">
              <div className="col-12">
                <div className="col-lg-12 stretch-card" style={{ padding: 0 }}>
                  <div className="card">
                    <div className="card-body">
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "space-between",
                        }}
                      >
                        <h4
                          className="card-title"
                          style={{ marginBottom: "0 !important" }}
                        >
                          Danh sách lịch sử task
                        </h4>

                        <div style={{ display: "flex" }}>
                          <button id="info" className="btn btn-success btn_add">
                            <i className="fas fa-plus" />{" "}
                            <span>Thêm lịch sử task</span>
                          </button>
                        </div>
                      </div>
                      <div className="table-responsive pt-3">
                        <table className="table table-bordered text-center">
                          <thead>
                            <tr>
                              <th style={{ whiteSpace: "nowrap" }}>ID</th>
                              <th style={{ whiteSpace: "nowrap" }}>Username</th>
                              <th style={{ whiteSpace: "nowrap" }}>
                                Thời gian
                              </th>
                              <th style={{ whiteSpace: "nowrap" }}>
                                Trạng thái
                              </th>
                              <th style={{ whiteSpace: "nowrap" }}>Tên task</th>
                              <th style={{ whiteSpace: "nowrap" }}>Lần thử</th>
                              <th style={{ whiteSpace: "nowrap" }}>Mã code</th>
                              <th style={{ whiteSpace: "nowrap" }}>Cập nhật</th>
                            </tr>
                          </thead>
                          <tbody>
                            {filterSearch().map((history, index) => {
                              if (
                                index >= (page - 1) * maxShow &&
                                index < page * maxShow
                              ) {
                                return (
                                  <tr key={index} className="table-white">
                                    <td>{history.task_history_id}</td>
                                    <td>{history.User.username}</td>
                                    <td>{convertToDate(history.time_start)}</td>
                                    {history.status === 1 ? (
                                      <td
                                        style={{
                                          color: "#007bff",
                                          fontWeight: 700,
                                        }}
                                      >
                                        Đang làm
                                      </td>
                                    ) : history.status === 2 ? (
                                      <td
                                        style={{
                                          color: "#28a745",
                                          fontWeight: 700,
                                        }}
                                      >
                                        Hoàn thành
                                      </td>
                                    ) : (
                                      <td
                                        style={{
                                          color: "#dc3545",
                                          fontWeight: 700,
                                        }}
                                      >
                                        Đã khóa
                                      </td>
                                    )}
                                    <td>{history.TaskLink.task_name}</td>
                                    <td>{history.temp}</td>
                                    <td
                                      style={{
                                        color: "#28a745",
                                        fontWeight: 700,
                                      }}
                                    >
                                      {history?.CodeLink?.code || "-"}
                                    </td>
                                    <td>
                                      <button
                                        type="button"
                                        className="btn btn-warning m-1 btn_edit"
                                      >
                                        <i className="far fa-edit" />
                                      </button>

                                      <button
                                        className="btn btn-info btn_change_status"
                                        onClick={() =>
                                          handleShowFormDetail(history)
                                        }
                                      >
                                        Chi Tiết
                                      </button>
                                    </td>
                                  </tr>
                                );
                              } else return "";
                            })}
                          </tbody>
                        </table>
                      </div>
                      <div className="flex_center pagination_mg">
                        <div>Tổng số: {filterSearch().length}</div>
                        <ReactPaginate
                          breakLabel="..."
                          nextLabel="Next"
                          onPageChange={handlePageClick}
                          pageRangeDisplayed={2}
                          pageCount={
                            filterSearch().length <= maxShow
                              ? 1
                              : filterSearch().length % maxShow === 0
                              ? Math.floor(filterSearch().length / maxShow)
                              : Math.floor(filterSearch().length / maxShow) + 1
                          }
                          previousLabel="Previous"
                          renderOnZeroPageCount={null}
                          pageClassName="page-item"
                          pageLinkClassName="page-link"
                          previousClassName="page-item"
                          previousLinkClassName="page-link"
                          nextClassName="page-item"
                          nextLinkClassName="page-link"
                          breakClassName="page-item"
                          breakLinkClassName="page-link"
                          containerClassName="pagination"
                          activeClassName="active"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  );
}
