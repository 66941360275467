import React, { useEffect, useState } from "react";
import axios from "axios";
import { ApiLink, notify } from "../../../../utils/Title";
import { useNavigate } from "react-router-dom";

export default function FormEditShopItem({ itemChoose, closeForm, loadData }) {
  let navigate = useNavigate();
  let [shopCategoryList, setShopCategoryList] = useState([]);
  let [itemState, setItemState] = useState({
    shop_item_name: itemChoose.shop_item_name,
    xu: itemChoose.xu,
    note: itemChoose.note,
    time_expire: itemChoose.time_expire,
    avatar: itemChoose.avatar,
    status: itemChoose.status,
    shop_category_id: itemChoose.ShopCategory.shop_category_id,
  });

  useEffect(() => {
    async function getAllShopCategory() {
      const response = await axios.get(
        `${ApiLink.domain + "/admin/shop-category"}`,
        {
          withCredentials: true,
        }
      );
      if (response.data.status) {
        setShopCategoryList(response.data.data); // status, data
      } else {
        notify(false, response.data.message);
        if (response.data.must === "login") {
          window.localStorage.clear();
          return navigate("/", { replace: true });
        } else if (response.data.must === "permission") {
          return navigate("/", { replace: true });
        }
      }
    }
    getAllShopCategory();
  }, [navigate]);

  const handleOnChange = (e) => {
    let { name, value } = e.target;
    if (
      name === "time_expire" ||
      name === "xu" ||
      name === "status" ||
      name === "shop_category_id"
    ) {
      value = Number(value);
    }
    setItemState({ ...itemState, [name]: value });
  };

  const editItem = async (newItem) => {
    const response = await axios.put(
      `${ApiLink.domain + "/admin/shop-item/" + itemChoose.shop_item_id}`,
      newItem,
      {
        withCredentials: true,
      }
    );
    if (response.data.status) {
      notify(true, response.data.message);
      loadData(response.data.data);
      closeForm(true, false);
    } else {
      notify(false, response.data.message);
      if (response.data.must === "login") {
        return navigate("/", { replace: true });
      } else if (response.data.must === "permission") {
        return navigate("/", { replace: true });
      }
    }
  };

  const handleEditItem = async () => {
    if (itemState.shop_item_name.trim().length < 2) {
      notify(false, "Tên vật phẩm cần >= 2 ký tự");
      return;
    }
    if (itemState.avatar.trim().length < 1) {
      notify(false, "Chưa có hình ảnh vật phẩm");
      return;
    }
    if (
      itemState.status !== 1 &&
      itemState.status !== 0 &&
      itemState.status !== false &&
      itemState.status !== true
    ) {
      notify(false, "Vui lòng chọn trạng thái vật phẩm");
      return;
    } else if (!Number.isInteger(itemState.xu) || Number(itemState.xu) < 0) {
      notify(false, "Số xu không hợp lệ");
      return;
    } else if (
      !Number.isInteger(itemState.time_expire) ||
      Number(itemState.time_expire) < -9999
    ) {
      notify(false, "Thời gian hết hạn không hợp lệ");
      return;
    }
    const newItem = {
      shop_item_name: itemState.shop_item_name.trim(),
      note: itemState.note.trim(),
      avatar: itemState.avatar.trim(),
      status: itemState.status ? true : false,
      xu: Number(itemState.xu),
      time_expire: Number(itemState.time_expire),
      shop_category_id: Number(itemState.shop_category_id),
    };
    await editItem(newItem);
  };
  return (
    <div
      className="col-6 add_edit_class"
      style={{
        backgroundColor: "rgb(242, 247, 255)",
        borderRadius: "3px 3px 0px 0px",
        boxShadow:
          "rgb(98, 176, 253) 0px 4px 8px, rgba(0, 0, 0, 0.08) 0px 4px 12px",
        padding: "0px !important",
        display: "block",
      }}
    >
      <div
        className="bg-primary"
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          padding: "15px 20px",
        }}
      >
        <div className="text-white add_book_class_header">
          Cập Nhật Thông Tin vật phẩm
        </div>
        <div onClick={() => closeForm(false, false)}>
          <i
            className="far fa-times-circle btn_close_form"
            style={{ color: "white", fontSize: 25 }}
          />
        </div>
      </div>
      <div className="sroll_form style-15">
        <div style={{ padding: 20, display: "flex" }}>
          <div className="col-6">
            <div style={{ marginBottom: 20 }}>
              <label className="form-label">Tên vật phẩm</label>
              <input
                onChange={(e) => handleOnChange(e)}
                className="form-control showordisable"
                name="shop_item_name"
                defaultValue={itemState.shop_item_name}
                type="text"
                placeholder="VD: Cần câu gỗ"
              />
            </div>
            <div style={{ marginBottom: 20 }}>
              <label className="form-label">Ảnh shop</label>
              <input
                onChange={(e) => handleOnChange(e)}
                className="form-control"
                name="avatar"
                type="text"
                placeholder="VD: /shop/can_cau_go.png"
                defaultValue={itemState.avatar}
              />
            </div>

            <div style={{ marginBottom: 20 }}>
              <label className="form-label">Trạng thái</label>
              <select
                onChange={(e) => handleOnChange(e)}
                className="form-select"
                name="status"
                value={itemState.status}
                aria-label="Default select example"
              >
                <option value={1}>Đang bán</option>
                <option value={0}>Ngưng bán</option>
              </select>
            </div>
          </div>
          <div className="col-6">
            <div style={{ marginBottom: 20 }}>
              <label className="form-label">Time hết hạn</label>
              <input
                onChange={(e) => handleOnChange(e)}
                className="form-control"
                name="time_expire"
                type="number"
                defaultValue={itemState.time_expire}
                placeholder="VD: 1440"
              />
            </div>

            <div style={{ marginBottom: 20 }}>
              <label className="form-label">Giá mua</label>
              <input
                onChange={(e) => handleOnChange(e)}
                className="form-control"
                name="xu"
                type="number"
                defaultValue={itemState.xu}
                placeholder="VD: 10"
              />
            </div>
            <div style={{ marginBottom: 20 }}>
              <label className="form-label">Danh mục shop</label>
              <select
                onChange={(e) => handleOnChange(e)}
                className="form-select"
                name="shop_category_id"
                value={itemState.shop_category_id}
                aria-label="Default select example"
              >
                {shopCategoryList.map((item, index) => (
                  <option key={index} value={item.shop_category_id}>
                    {item.shop_category_name}
                  </option>
                ))}
              </select>
            </div>
          </div>
        </div>

        <div className="col-12" style={{ padding: "0px 35px", margin: 0 }}>
          <label className="form-label">Ghi chú</label>
          <textarea
            onChange={(e) => handleOnChange(e)}
            className="form-control style-15"
            name="note"
            type="text"
            rows="3"
            value={itemState.note}
            placeholder="VD: Cần câu cá"
          />
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            margin: 10,
          }}
        >
          <button
            onClick={() => handleEditItem()}
            className="btn btn-success btn_add_edit_customer_submit"
          >
            Xác Nhận
          </button>
        </div>
      </div>
    </div>
  );
}
