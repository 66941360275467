import React, { Fragment, useEffect, useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import ReactPaginate from "react-paginate";
import { ApiLink, convertToDate, notify, Title } from "../../../utils/Title";
import UserLogo from "./UserLogo";
import FormDoiThuong from "./Form/FormDoiThuong";
export default function PayHistory({ username }) {
  let [payHistoryList, setPayHistoryList] = useState([]);
  let [page, setPage] = useState(1);
  let [showForm, isShowForm] = useState(false);
  let [itemChoose, setItemChoose] = useState(false);
  const maxShow = 10;

  const handlePageClick = (event) => {
    if (page !== event.selected + 1) setPage(event.selected + 1);
  };

  let navigate = useNavigate();

  useEffect(() => {
    document.title = Title.user_history_pay + Title.origin;
  }, []);

  useEffect(() => {
    const getMucTieuHomNay = async () => {
      const response = await axios.get(`${ApiLink.domain + "/pay-history"}`, {
        withCredentials: true,
      });
      if (response.data.status) {
        setPayHistoryList(response.data.data);
      } else {
        notify(false, response.data.message);
        if (response.data.must === "login") {
          return navigate("/account/login", { replace: true });
        } else {
          return navigate("/", { replace: true });
        }
      }
    };

    const handleAPIAll = async () => {
      await Promise.all([Promise.resolve(getMucTieuHomNay())]);
    };
    handleAPIAll();
  }, [navigate]);

  return (
    <Fragment>
      {showForm && (
        <div
          className="background_black"
          id="background_black"
          style={{ display: "block" }}
        >
          <FormDoiThuong
            closeFormConfirm={() => isShowForm(false)}
            itemChoose={itemChoose}
          />
        </div>
      )}
      <div id="main">
        <UserLogo />
        <div className="page-content">
          <section
            className="row"
            style={{
              marginLeft: "-5px",
              marginRight: "-5px",
            }}
          >
            <div className="col-12 col-lg-12">
              <div className="row">
                <div className="col-12">
                  <div
                    className="col-lg-12 stretch-card"
                    style={{ padding: 0 }}
                  >
                    <div className="card">
                      <div className="card-body">
                        <h4
                          className="card-title"
                          style={{
                            textAlign: "center",
                          }}
                        >
                          Lịch sử rút tiền
                        </h4>
                        <div className="table-responsive pt-3">
                          <table className="table table-bordered text-center">
                            <thead>
                              <tr>
                                <th>STT</th>
                                <th style={{ whiteSpace: "nowrap" }}>
                                  Thời gian
                                </th>
                                <th style={{ whiteSpace: "nowrap" }}>
                                  Phần thưởng
                                </th>
                                <th style={{ whiteSpace: "nowrap" }}>
                                  Trạng thái
                                </th>
                                <th style={{ whiteSpace: "nowrap" }}>
                                  Chi tiết
                                </th>
                              </tr>
                            </thead>
                            <tbody>
                              {payHistoryList.map((pay_history, index) => {
                                if (
                                  index >= (page - 1) * maxShow &&
                                  index < page * maxShow
                                ) {
                                  return (
                                    <tr key={index} className="table-white">
                                      <td>{index + 1}</td>
                                      <td style={{ whiteSpace: "nowrap" }}>
                                        {convertToDate(pay_history.createdAt)}
                                      </td>
                                      <td style={{ whiteSpace: "nowrap" }}>
                                        {pay_history.PayDetail.name}
                                      </td>
                                      {Number(
                                        pay_history.pay_history_status
                                      ) === 1 ? (
                                        <td
                                          style={{
                                            color: "#007bff",
                                            fontWeight: 700,
                                          }}
                                        >
                                          Chờ duyệt
                                        </td>
                                      ) : Number(
                                          pay_history.pay_history_status
                                        ) === 2 ? (
                                        <td
                                          style={{
                                            color: "#28a745",
                                            fontWeight: 700,
                                            whiteSpace: "nowrap",
                                          }}
                                        >
                                          Thành công
                                        </td>
                                      ) : (
                                        <td
                                          style={{
                                            color: "#dc3545",
                                            fontWeight: 700,
                                            whiteSpace: "nowrap",
                                          }}
                                        >
                                          Thất bại
                                        </td>
                                      )}
                                      <td>
                                        <button
                                          className="btn btn-success btn_change_status"
                                          onClick={() => {
                                            setItemChoose(pay_history);
                                            isShowForm(true);
                                          }}
                                        >
                                          Xem
                                        </button>
                                      </td>
                                    </tr>
                                  );
                                } else return "";
                              })}
                            </tbody>
                          </table>
                        </div>
                        <div
                          className="flex_center pagination_mg"
                          style={{
                            marginBottom: 20,
                          }}
                        >
                          <div>Tổng số: {payHistoryList.length}</div>
                          <ReactPaginate
                            breakLabel="..."
                            nextLabel="Next"
                            onPageChange={handlePageClick}
                            pageRangeDisplayed={2}
                            pageCount={
                              payHistoryList.length <= maxShow
                                ? 1
                                : payHistoryList.length % maxShow === 0
                                ? Math.floor(payHistoryList.length / maxShow)
                                : Math.floor(payHistoryList.length / maxShow) +
                                  1
                            }
                            previousLabel="Previous"
                            renderOnZeroPageCount={null}
                            pageClassName="page-item"
                            pageLinkClassName="page-link"
                            previousClassName="page-item"
                            previousLinkClassName="page-link"
                            nextClassName="page-item"
                            nextLinkClassName="page-link"
                            breakClassName="page-item"
                            breakLinkClassName="page-link"
                            containerClassName="pagination"
                            activeClassName="active"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
    </Fragment>
  );
}
