import React, { Fragment } from "react";
import { Link } from "react-router-dom";

export default function OpenLayout({ handleShowNotify, showNotify }) {
  return (
    <Fragment>
      <div className="text_hello">
        <b
          style={{
            color: "rgb(40, 167, 69)",
            marginRight: "5px",
          }}
        >
          Kênh Youtube:{" "}
          <Link
            style={{ color: "#007bff", textDecoration: "none" }}
            to={"https://www.youtube.com/c/kiemthecao/videos"}
            target="_blank"
          >
            Đức Ngọc Kiếm Tiền
          </Link>
        </b>
      </div>{" "}
      <div className="text_hello">
        <b
          style={{
            color: "rgb(40, 167, 69)",
            marginRight: "5px",
          }}
        >
          Facebook:{" "}
          <Link
            style={{ color: "#007bff", textDecoration: "none" }}
            to={"https://www.facebook.com/n.ducngoc.233"}
            target="_blank"
          >
            Đức Ngọc
          </Link>
        </b>
      </div>
      <div className="text_hello">
        <b
          style={{
            color: "rgb(40, 167, 69)",
            marginRight: "5px",
          }}
        >
          Zalo:{" "}
          <span
            onClick={() => {
              alert("SĐT Zalo: 0378544081 - Đức Ngọc");
            }}
            style={{ color: "#007bff", cursor: "pointer" }}
          >
            Đức Ngọc
          </span>
        </b>
      </div>
      <div className="text_hello">
        <img src="/images/icons/hot.gif" alt="icon" />
        <b
          style={{
            color: "rgb(40, 167, 69)",
            marginRight: "5px",
            marginLeft: "5px",
          }}
        >
          Tải App nhận tiền:{" "}
          <Link style={{ color: "#007bff" }} to={"/"}>
            Tham gia
          </Link>
        </b>
      </div>
      <div className="text_hello">
        <img src="/images/icons/hot.gif" alt="icon" />
        <b
          style={{
            color: "rgb(40, 167, 69)",
            marginRight: "5px",
            marginLeft: "5px",
          }}
        >
          Nhập code kiếm tiền:{" "}
          <Link style={{ color: "#007bff" }} to={"/task-link"}>
            Tham gia
          </Link>
        </b>
      </div>
      <div className="text_hello">
        <img src="/images/icons/new.gif" alt="icon" />
        <b
          style={{
            color: "rgb(40, 167, 69)",
            marginRight: "5px",
            marginLeft: "5px",
          }}
        >
          Nông trại kiếm tiền:{" "}
          <Link style={{ color: "#007bff" }} to={"/farm"}>
            Mới ra mắt (Beta)
          </Link>
        </b>
      </div>
      <div className="text_hello">
        <img src="/images/icons/new.gif" alt="icon" />
        <b
          style={{
            color: "rgb(40, 167, 69)",
            marginRight: "5px",
            marginLeft: "5px",
          }}
        >
          Sự kiện Nông Trại / Câu Cá:{" "}
          <Link style={{ color: "#007bff" }} to={"/event"}>
            Xem ngay
          </Link>
        </b>
      </div>
      <div className="text_hello">
        <img src="/images/icons/new.gif" alt="icon" />
        <b
          style={{
            color: "rgb(40, 167, 69)",
            marginRight: "5px",
            marginLeft: "5px",
          }}
        >
          Bán Paypal - Mua thẻ cào:{" "}
          <span
            onClick={() => handleShowNotify()}
            style={{ color: "#007bff", cursor: "pointer" }}
          >
            {showNotify ? "Đóng" : "Xem ngay"}
          </span>
        </b>
      </div>
    </Fragment>
  );
}
