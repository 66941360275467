import React, { useEffect, useState } from "react";
import axios from "axios";
import ReactPaginate from "react-paginate";
import SearchTheme from "./SearchTheme";
import { useNavigate } from "react-router-dom";
import { ApiLink, convertToTimeFarm, notify } from "../../../utils/Title";
import AdminLogo from "./AdminLogo";
import FormEditAnimal from "./Form/FormEditAnimal";
import FormAddAnimal from "./Form/FormAddAnimal";

export default function FarmAnimalAdmin({ username }) {
  let [animalList, setAnimalList] = useState([]);
  let [page, setPage] = useState(1);
  const maxShow = 10;
  let navigate = useNavigate();
  let [itemEdit, setItemEdit] = useState(false);
  let [itemAdd, setItemAdd] = useState(false);
  let [keyword, setKeyword] = useState("");

  const handleShowFormEdit = (item) => {
    setItemEdit(item);
  };

  const handleShowFormAdd = () => {
    setItemAdd(true);
  };

  const handleCloseFormEdit = async (edit_submit, result) => {
    if (result & !edit_submit) {
      await reloadData();
    }
    setItemEdit(false);
  };

  useEffect(() => {
    async function getAllFarmAnimal() {
      const response = await axios.get(
        `${ApiLink.domain + "/admin/farm-animal"}`,
        {
          withCredentials: true,
        }
      );
      if (response.data.status) {
        setAnimalList(response.data.data); // status, data
      } else {
        notify(false, response.data.message);
        if (response.data.must === "login") {
          window.localStorage.clear();
          return navigate("/", { replace: true });
        } else if (response.data.must === "permission") {
          return navigate("/", { replace: true });
        }
      }
    }
    getAllFarmAnimal();
  }, [navigate]);

  const handlePageClick = (event) => {
    if (page !== event.selected + 1) setPage(event.selected + 1);
  };

  const loadData = (data) => {
    setAnimalList(data);
  };

  const reloadData = async () => {
    const response = await axios.get(
      `${ApiLink.domain + "/admin/farm-animal"}`,
      {
        withCredentials: true,
      }
    );
    if (response.data.status) {
      setAnimalList(response.data.data);
    } else {
      notify(false, response.data.message);
      if (response.data.must === "login") {
        window.localStorage.clear();
        return navigate("/", { replace: true });
      } else if (response.data.must === "permission") {
        return navigate("/", { replace: true });
      }
    }
  };

  const handleCloseFormAdd = async (add_edit, result) => {
    if (result & !add_edit) {
      // reload_data
      await reloadData();
    }
    setItemAdd(false);
  };

  const handleSearch = (keyword) => {
    setKeyword(keyword.toLowerCase().trim());
  };

  const filterSearch = () => {
    return animalList.filter((item) => {
      return (
        item.animal_name.toLowerCase().includes(keyword) ||
        item.note.toLowerCase().includes(keyword) ||
        item.image_nuoi.toLowerCase().includes(keyword) ||
        item.avatar.toLowerCase().includes(keyword) ||
        Number(item.xu) === Number(keyword) ||
        Number(item.time_song) === Number(keyword) ||
        Number(item.time_choan) === Number(keyword) ||
        Number(item.time_sinhtruong) === Number(keyword) ||
        Number(item.time_sansinh) === Number(keyword) ||
        Number(item.sell_full) === Number(keyword) ||
        Number(item.sell) === Number(keyword) ||
        Number(item.sell_sansinh) === Number(keyword) ||
        Number(item.status) === Number(keyword) ||
        Number(item.animal_id) === Number(keyword)
      );
    });
  };

  return (
    <div id="main">
      {" "}
      <AdminLogo />
      {itemAdd || itemEdit ? (
        <div
          className="background_black"
          id="background_black"
          style={{ display: "block" }}
        >
          {itemEdit ? (
            <FormEditAnimal
              itemChoose={itemEdit}
              closeForm={handleCloseFormEdit}
              loadData={loadData}
            />
          ) : (
            ""
          )}

          {itemAdd ? (
            <FormAddAnimal closeForm={handleCloseFormAdd} loadData={loadData} />
          ) : (
            ""
          )}
        </div>
      ) : (
        ""
      )}
      <div className="page-content">
        <section
          className="row"
          style={{
            marginLeft: "-5px",
            marginRight: "-5px",
          }}
        >
          <div className="col-12 col-lg-12">
            {/* <AdminThemeTop username={username} /> */}
            <SearchTheme
              title={"Tìm kiếm vật nuôi"}
              handleSearch={handleSearch}
            />
            <div className="row">
              <div className="col-12">
                <div className="col-lg-12 stretch-card" style={{ padding: 0 }}>
                  <div className="card">
                    <div className="card-body">
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "space-between",
                        }}
                      >
                        <h4
                          className="card-title"
                          style={{ marginBottom: "0 !important" }}
                        >
                          Danh sách vật nuôi
                        </h4>

                        <div style={{ display: "flex" }}>
                          <button
                            id="info"
                            className="btn btn-success btn_add"
                            onClick={() => handleShowFormAdd()}
                          >
                            <i className="fas fa-plus" />{" "}
                            <span>Thêm vật nuôi</span>
                          </button>
                        </div>
                      </div>
                      <div className="table-responsive pt-3">
                        <table className="table table-bordered text-center">
                          <thead>
                            <tr>
                              <th style={{ whiteSpace: "nowrap" }}>ID</th>
                              <th style={{ whiteSpace: "nowrap" }}>
                                Tên vật nuôi
                              </th>

                              <th style={{ whiteSpace: "nowrap" }}>Giá mua</th>
                              <th style={{ whiteSpace: "nowrap" }}>
                                Giá bán min
                              </th>
                              <th style={{ whiteSpace: "nowrap" }}>
                                Giá bán max
                              </th>
                              <th style={{ whiteSpace: "nowrap" }}>
                                Giá sản sinh
                              </th>
                              <th style={{ whiteSpace: "nowrap" }}>
                                Time sống
                              </th>
                              <th style={{ whiteSpace: "nowrap" }}>
                                Time sinh trưởng
                              </th>
                              <th style={{ whiteSpace: "nowrap" }}>
                                Time cho ăn
                              </th>
                              <th style={{ whiteSpace: "nowrap" }}>
                                Time sản sinh
                              </th>
                              <th style={{ whiteSpace: "nowrap" }}>Sản sinh</th>
                              <th style={{ whiteSpace: "nowrap" }}>
                                Tên sản sinh
                              </th>
                              <th style={{ whiteSpace: "nowrap" }}>
                                Nuôi tối đa
                              </th>
                              <th style={{ whiteSpace: "nowrap" }}>Ảnh shop</th>
                              <th style={{ whiteSpace: "nowrap" }}>
                                Ảnh sản sinh
                              </th>
                              <th style={{ whiteSpace: "nowrap" }}>Ghi chú</th>
                              <th style={{ whiteSpace: "nowrap" }}>
                                Trạng thái
                              </th>
                              <th style={{ whiteSpace: "nowrap" }}>Cập nhật</th>
                            </tr>
                          </thead>
                          <tbody>
                            {filterSearch().map((item, index) => {
                              if (
                                index >= (page - 1) * maxShow &&
                                index < page * maxShow
                              ) {
                                return (
                                  <tr key={index} className="table-white">
                                    <td>{item.animal_id}</td>
                                    <td>{item.animal_name}</td>
                                    <td>
                                      {Number(item.xu).toLocaleString("vi")}
                                    </td>
                                    <td>
                                      {Number(item.sell).toLocaleString("vi")}
                                    </td>
                                    <td>
                                      {Number(item.sell_full).toLocaleString(
                                        "vi"
                                      )}
                                    </td>
                                    <td>
                                      {Number(item.sell_sansinh).toLocaleString(
                                        "vi"
                                      )}
                                    </td>
                                    <td>{convertToTimeFarm(item.time_song)}</td>
                                    <td>
                                      {convertToTimeFarm(item.time_sinhtruong)}
                                    </td>
                                    <td>
                                      {convertToTimeFarm(item.time_choan)}
                                    </td>
                                    <td>
                                      {item.is_sell
                                        ? convertToTimeFarm(item.time_sansinh)
                                        : "-"}
                                    </td>
                                    <td style={{ whiteSpace: "nowrap" }}>
                                      {item.is_sell
                                        ? "Có sản sinh"
                                        : "Không sản sinh"}
                                    </td>
                                    <td style={{ whiteSpace: "nowrap" }}>
                                      {item.is_sell ? item.sansinh_name : "-"}
                                    </td>
                                    <td>{item.max_buy}</td>
                                    <td>
                                      <img
                                        src={"/images/" + item.avatar}
                                        alt="item"
                                      />
                                    </td>
                                    <td>
                                      {item.is_sell ? (
                                        <img
                                          src={"/images/" + item.avatar_sansinh}
                                          alt="item"
                                        />
                                      ) : (
                                        " - "
                                      )}
                                    </td>
                                    <td>{item.note}</td>
                                    {item.status === 1 ? (
                                      <td
                                        style={{
                                          color: "#28a745",
                                          fontWeight: 700,
                                          whiteSpace: "nowrap",
                                        }}
                                      >
                                        Mở bán
                                      </td>
                                    ) : (
                                      <td
                                        style={{
                                          color: "#dc3545",
                                          fontWeight: 700,
                                          whiteSpace: "nowrap",
                                        }}
                                      >
                                        Ngưng bán
                                      </td>
                                    )}
                                    <td>
                                      <button
                                        onClick={() => handleShowFormEdit(item)}
                                        type="button"
                                        className="btn btn-warning m-1 btn_edit"
                                      >
                                        <i className="far fa-edit" />
                                      </button>
                                    </td>
                                  </tr>
                                );
                              } else return "";
                            })}
                          </tbody>
                        </table>
                      </div>
                      <div className="flex_center pagination_mg">
                        <div>Tổng số: {filterSearch().length}</div>
                        <ReactPaginate
                          breakLabel="..."
                          nextLabel="Next"
                          onPageChange={handlePageClick}
                          pageRangeDisplayed={2}
                          pageCount={
                            filterSearch().length <= maxShow
                              ? 1
                              : filterSearch().length % maxShow === 0
                              ? Math.floor(filterSearch().length / maxShow)
                              : Math.floor(filterSearch().length / maxShow) + 1
                          }
                          previousLabel="Previous"
                          renderOnZeroPageCount={null}
                          pageClassName="page-item"
                          pageLinkClassName="page-link"
                          previousClassName="page-item"
                          previousLinkClassName="page-link"
                          nextClassName="page-item"
                          nextLinkClassName="page-link"
                          breakClassName="page-item"
                          breakLinkClassName="page-link"
                          containerClassName="pagination"
                          activeClassName="active"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  );
}
