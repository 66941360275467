import React, { Fragment, useContext } from "react";
import { OnlineUsersContext } from "../../Context/OnlineUsersContext";

export default function FooterOnline({ margin_top }) {
  const { usersOnline } = useContext(OnlineUsersContext);
  const renderDanhSachOnline = () => {
    let list_online = [];
    let khach_number = 0;
    usersOnline.forEach((item) => {
      if (item.username) {
        list_online.push({
          ...item,
          role: item.username === "ducngoc233" ? "ADMIN" : "USER",
        });
      } else {
        khach_number += 1;
      }
    });
    return {
      list_online,
      khach_number,
    };
  };
  const { list_online, khach_number } = renderDanhSachOnline();

  return (
    <Fragment>
      <div
        style={{
          marginTop: margin_top,
        }}
      >
        <div className="list_name">
          Hiện có {usersOnline.length} người đang online
        </div>
        <div
          style={{
            backgroundColor: "#EAF6FC",
            bordeRadius: "5px",
            border: "1px solid #bce8f1",
            color: "#31708f",
            margin: "5px 0",
            padding: "10px",
            textAlign: "center",
            fontSize: "15px",
          }}
        >
          {list_online.length > 0 ? (
            <Fragment>
              {list_online.map((item, index) => (
                <span
                  key={index}
                  style={{
                    margin:
                      index + 1 === list_online.length
                        ? "0px 0px 0px 2px0px 2px"
                        : "0px 2px",
                    color: item.role === "ADMIN" ? "red" : "#31708f",
                  }}
                >
                  {index + 1 === list_online.length
                    ? item.username
                    : item.username + ","}
                </span>
              ))}
              <span> và </span>
            </Fragment>
          ) : (
            ""
          )}{" "}
          {khach_number} khách truy cập
        </div>
      </div>

      <p
        className="text-center"
        style={{
          marginTop: 10,
        }}
      >
        © 2025{" "}
        <a href="https://kiemthecaofree.com/" target="_self" className="ml-25">
          Đức Ngọc Kiếm Tiền
        </a>
        . All rights Reserved{" "}
      </p>
    </Fragment>
  );
}
