import React, { useState } from "react";
import axios from "axios";
import { ApiLink, notify } from "../../../../utils/Title";
import { useNavigate } from "react-router-dom";

export default function FormEditFish({ itemChoose, closeForm, loadData }) {
  let navigate = useNavigate();
  let [itemState, setItemState] = useState({
    fish_name: itemChoose.fish_name,
    xu_sell: itemChoose.xu_sell,
    note: itemChoose.note,
    remain: itemChoose.remain,
    avatar: itemChoose.avatar,
    status: itemChoose.status,
  });

  const handleOnChange = (e) => {
    let { name, value } = e.target;
    if (name === "remain" || name === "xu_sell" || name === "status") {
      value = Number(value);
    }
    setItemState({ ...itemState, [name]: value });
  };

  const editItem = async (newItem) => {
    const response = await axios.put(
      `${ApiLink.domain + "/admin/fish/" + itemChoose.fish_id}`,
      newItem,
      {
        withCredentials: true,
      }
    );
    if (response.data.status) {
      notify(true, response.data.message);
      loadData(response.data.data);
      closeForm(true, true);
    } else {
      notify(false, response.data.message);
      if (response.data.must === "login") {
        return navigate("/", { replace: true });
      } else if (response.data.must === "permission") {
        return navigate("/", { replace: true });
      }
    }
  };

  const handleEditItem = async () => {
    if (itemState.fish_name.trim().length < 2) {
      notify(false, "Tên con cá cần >= 2 ký tự");
      return;
    }
    if (itemState.avatar.trim().length < 1) {
      notify(false, "Chưa có hình ảnh con cá");
      return;
    }
    if (!Number.isInteger(itemState.xu_sell) || Number(itemState.xu_sell) < 0) {
      notify(false, "Số tiền bán không hợp lệ");
      return;
    } else if (
      !Number.isInteger(itemState.remain) ||
      Number(itemState.remain) < 0
    ) {
      notify(false, "Số lượng còn lại không hợp lệ");
      return;
    }
    const newItem = {
      fish_name: itemState.fish_name.trim(),
      note: itemState.note.trim(),
      avatar: itemState.avatar.trim(),
      xu_sell: Number(itemState.xu_sell),
      remain: Number(itemState.remain),
      status: true,
    };
    await editItem(newItem);
  };
  return (
    <div
      className="col-6 add_edit_class"
      style={{
        backgroundColor: "rgb(242, 247, 255)",
        borderRadius: "3px 3px 0px 0px",
        boxShadow:
          "rgb(98, 176, 253) 0px 4px 8px, rgba(0, 0, 0, 0.08) 0px 4px 12px",
        padding: "0px !important",
        display: "block",
      }}
    >
      <div
        className="bg-primary"
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          padding: "15px 20px",
        }}
      >
        <div className="text-white add_book_class_header">
          Cập Nhật Thông Tin Con Cá
        </div>
        <div onClick={() => closeForm(false, false)}>
          <i
            className="far fa-times-circle btn_close_form"
            style={{ color: "white", fontSize: 25 }}
          />
        </div>
      </div>
      <div className="sroll_form style-15">
        <div style={{ padding: 20, display: "flex" }}>
          <div className="col-6">
            <div style={{ marginBottom: 20 }}>
              <label className="form-label">Tên con cá</label>
              <input
                onChange={(e) => handleOnChange(e)}
                className="form-control showordisable"
                name="fish_name"
                defaultValue={itemState.fish_name}
                type="text"
                placeholder="VD: Cá rô phi"
              />
            </div>
            <div style={{ marginBottom: 20 }}>
              <label className="form-label">Ảnh</label>
              <input
                onChange={(e) => handleOnChange(e)}
                className="form-control"
                name="avatar"
                type="text"
                placeholder="VD: /fish/ca_ro.png"
                defaultValue={itemState.avatar}
              />
            </div>
          </div>
          <div className="col-6">
            <div style={{ marginBottom: 20 }}>
              <label className="form-label">Còn lại</label>
              <input
                onChange={(e) => handleOnChange(e)}
                className="form-control"
                name="remain"
                type="number"
                defaultValue={itemState.remain}
                placeholder="VD: 10"
              />
            </div>

            <div style={{ marginBottom: 20 }}>
              <label className="form-label">Giá bán</label>
              <input
                onChange={(e) => handleOnChange(e)}
                className="form-control"
                name="xu_sell"
                type="number"
                defaultValue={itemState.xu_sell}
                placeholder="VD: 10"
              />
            </div>
          </div>
        </div>

        <div className="col-12" style={{ padding: "0px 35px", margin: 0 }}>
          <label className="form-label">Ghi chú</label>
          <textarea
            onChange={(e) => handleOnChange(e)}
            className="form-control style-15"
            name="note"
            type="text"
            rows="3"
            value={itemState.note}
            placeholder="VD: Cần câu cá"
          />
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            margin: 10,
          }}
        >
          <button
            onClick={() => handleEditItem()}
            className="btn btn-success btn_add_edit_customer_submit"
          >
            Xác Nhận
          </button>
        </div>
      </div>
    </div>
  );
}
