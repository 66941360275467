import React, { Fragment, useEffect, useState } from "react";
import "../../styles/Farm.css";
import "../../styles/Fish.css";
import axios from "axios";
import { ApiLink, Title } from "../../utils/Title";
import { Link, useNavigate } from "react-router-dom";
import UserBoardLayout from "../../components/Layouts/BasicLayout/UserBoardLayout";
import Footer from "../../components/Layouts/BasicLayout/Footer";
import NotifyLayout from "../../components/Layouts/BasicLayout/NotifyLayout";
import AdSenseAd from "../../components/AdSenseAd/AdSenseAd";
import MultiplexAd from "../../components/AdSenseAd/MultiplexAd";
import FormFishStore from "../../components/Layouts/UserLayout/Form/FormFishStore";
import FormFishStock from "../../components/Layouts/UserLayout/Form/FormFishStock";
import BangXepHangCauCa from "../../components/Layouts/BasicLayout/BangXepHangCauCa";
export default function FishGatePage() {
  const [loading, isLoading] = useState(true);
  const [info, setInfo] = useState(null);
  let navigate = useNavigate();
  let [username, setUsername] = useState("");
  let [showNotify, setShowNotify] = useState(false);
  const [bxhCauCa, setBXHCauCa] = useState([]);
  const [showStore, setShowStore] = useState(false);
  const [showStock, setShowStock] = useState(false);
  useEffect(() => {
    document.title = Title.mobile_app + Title.origin;
  }, []);

  const handleSetShowNotify = () => {
    setShowNotify(!showNotify);
  };

  useEffect(() => {
    async function getUserInfo() {
      const response = await axios.get(`${ApiLink.domain + "/account/info"}`, {
        withCredentials: true,
      });
      if (response.data.status) {
        setUsername(response.data.data.username); // status, data
        setInfo(response.data.data);
        const land_amount = Number(response.data.data.land_amount);
        return land_amount;
      } else {
        // notify(false, response.data.message);
        if (response.data.must === "login") {
          window.localStorage.clear();
          return navigate("/account/login", { replace: true });
        }
        return false;
      }
    }
    async function getBXHCauCa() {
      const response = await axios.get(
        `${ApiLink.domain + "/event/ket-qua/bxh-cau-ca"}`,
        {
          withCredentials: true,
        }
      );
      if (response.data.status) {
        setBXHCauCa(response.data.data);
      } else {
        // notify(false, response.data.message);
        if (response.data.must === "login") {
          window.localStorage.clear();
          return navigate("/account/login", { replace: true });
        }
        return false;
      }
    }
    const getAll = async () => {
      await Promise.all([
        Promise.resolve(getUserInfo()),
        Promise.resolve(getBXHCauCa()),
      ]);
      isLoading(false);
    };
    getAll();
  }, [navigate]);

  return loading ? (
    <Fragment></Fragment>
  ) : (
    <Fragment>
      <AdSenseAd />
      {showStore || showStock ? (
        <div
          className="background_black"
          id="background_black"
          style={{ display: "block" }}
        >
          {showStore ? (
            <FormFishStore
              closeFormAdd={() => setShowStore(false)}
              loadData={(data) => {
                setInfo({
                  ...info,
                  vnd: data.vnd,
                });
              }}
            />
          ) : (
            ""
          )}
          {showStock ? (
            <FormFishStock
              closeFormAdd={() => setShowStock(false)}
              loadData={(data) => {
                setInfo({
                  ...info,
                  vnd: data.vnd,
                });
              }}
            />
          ) : (
            ""
          )}
        </div>
      ) : (
        ""
      )}
      <div className="farm_container">
        <UserBoardLayout
          Element={null}
          info={info}
          setInfo={setInfo}
          username={username}
          setUsername={setUsername}
          handleShowNotify={handleSetShowNotify}
          showNotify={showNotify}
        />
        {showNotify && <NotifyLayout />}
        <div className="farm_content">
          <div className="farm_name">Khu Shop Câu Cá</div>
          <div className="honuoc">
            <div className="vaocau">
              <Link to="/fish">
                <img src="/images/fish/vao.gif" alt="vao" />
              </Link>
            </div>
          </div>
          <div className="da">
            <div className="shop_user">
              <img
                className="shop_user_image npcanhbakhia"
                src="/images/fish/npcanhbakhia.gif"
                alt="npcanhbakhia"
                onClick={() => {
                  setShowStock(true);
                }}
              />
              <img
                className="shop_user_image"
                src="/images/fish/shopcauca.png"
                alt="shopcauca"
                onClick={() => {
                  setShowStore(true);
                }}
              />
              <img
                className="shop_user_image bxh"
                src="/images/fish/bxh.png"
                alt="bxh"
              />
              <img
                className="shop_user_image den"
                src="/images/fish/den.png"
                alt="den"
              />
            </div>
          </div>
        </div>
        <div className="list_name_no_border">Hướng dẫn câu cá</div>
        <div className="farm_action">
          <div className="fish_notify">
            Các bạn có thể mua cần câu, mồi câu tại mục{" "}
            <b
              style={{
                color: "#4cae4c",
              }}
            >
              Shop
            </b>{" "}
            của cửa hàng. Hàng tuần có thể sẽ diễn ra Event đua Top câu cá để
            tìm ra{" "}
            <b
              style={{
                color: "rgb(220, 53, 69)",
              }}
            >
              Cao Thủ Câu Cá Xuất Sắc
            </b>{" "}
            nhất vùng. Phần thưởng là thẻ cào điện thoại, tiền mặt thông qua
            chuyển khoản Ví Momo, ZaloPay hoặc ngân hàng nhé!
          </div>
          <div
            style={{
              marginTop: 10,
            }}
            className="farm_action_btn_list"
          >
            <div className="farm_action_btn">B1: Mua cần</div>
            <div className="farm_action_btn">B2: Mua mồi</div>
            <div className="farm_action_btn">B3: Câu cá</div>
          </div>
        </div>
        <div className="bxh">
          <div
            className="list_name_no_border"
            style={{
              marginTop: 10,
              borderRadius: 5,
            }}
          >
            Bảng xếp hạng câu cá
          </div>

          <BangXepHangCauCa bxhCauCa={bxhCauCa} info={info} />

          <div
            style={{
              textAlign: "center",
              marginTop: 10,
            }}
          >
            <Link
              style={{
                color: "#fff",
              }}
              className="farm_action_btn"
              to={"/chat"}
            >
              Khu vực chém gió :D
            </Link>
          </div>
        </div>
        <Footer margin_top={"15px"} />
      </div>
      <MultiplexAd />
    </Fragment>
  );
}
